import { Form } from 'react-router-dom';
import { Group, TextInput } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';

import { useNavigate } from 'react-router-dom';

import { CancelButton, FormWrapper, PrimaryButton, Spacer, Text } from '..';
import { validateEmail, validateNumber } from '../../utils';
import { CreateTenantFormData, Tenant } from '../../types';
import { COLORS } from '../../constants';

interface TenantFormProps {
  onSubmit: (data: CreateTenantFormData) => void;
  tenant?: Tenant;
}

export const TenantForm = ({ onSubmit, tenant }: TenantFormProps) => {
  const navigate = useNavigate();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      name: tenant?.name ?? '',
      organizationNumber: tenant?.organizationNumber ?? '',
      contactFirstName: tenant?.contactFirstName ?? '',
      contactLastName: tenant?.contactLastName ?? '',
      contactEmail: tenant?.contactEmail ?? '',
      contactPhone: tenant?.contactPhone ?? '',
    },

    validate: {
      name: isNotEmpty('Ange ett namn på kunden'),
      contactFirstName: isNotEmpty('Ange en kontaktperson'),
      contactLastName: isNotEmpty('Ange en kontaktperson'),
      contactEmail: (value) =>
        validateEmail(value) ? 'Ange en e-postadress' : null,
      contactPhone: isNotEmpty('Ange ett telefonnummer'),
      organizationNumber: isNotEmpty('Ange ett organisationsnummer'),
    },
  });

  return (
    <FormWrapper scrollable>
      <Form method="post">
        <Text>Kunduppgifter</Text>

        <Spacer minSpace="1.5rem" />

        <TextInput
          label="Kundnamn"
          placeholder="Kundnamn"
          name="name"
          {...form.getInputProps('name')}
        />

        <TextInput
          label="Organisationsnummer"
          placeholder="Organisationsnummer"
          name="organizationNumber"
          {...form.getInputProps('organizationNumber')}
        />

        <TextInput
          label="Kontaktperson förnamn"
          placeholder="Kontaktperson förnamn"
          name="contactFirstName"
          {...form.getInputProps('contactFirstName')}
        />
        <TextInput
          label="Kontaktperson efternamn"
          placeholder="Kontaktperson efternamn"
          name="contactLastName"
          {...form.getInputProps('contactLastName')}
        />

        <TextInput
          label="E-postadress"
          placeholder="E-postadress"
          name="contactEmail"
          {...form.getInputProps('contactEmail')}
        />
        <TextInput
          label="Telefonnummer"
          placeholder="Telefonnummer"
          name="contactPhone"
          {...form.getInputProps('contactPhone')}
        />

        <Group position="right" spacing="xs" sx={{ marginTop: '1.5rem' }}>
          <CancelButton
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          />
          <PrimaryButton
            onClick={() => {
              const formHasErrors = form.validate().hasErrors;
              !formHasErrors && onSubmit(form.values);
            }}
            background={COLORS.hdAccentGreen}
            hover={COLORS.hdAccentGreenDark}
          >
            Spara
          </PrimaryButton>
        </Group>
      </Form>
    </FormWrapper>
  );
};
