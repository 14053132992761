import { useViewportSize } from '@mantine/hooks';
import { useLoaderData, useNavigate } from 'react-router-dom';

import {
  TopBar,
  PageWrapper,
  Title,
  Table,
  PrimaryButton,
  Text,
  Spacer,
  MobileTopBarWrapper,
  MobileBackgroundWrapper,
  MobileContentWrapper,
  SortOrder,
} from '../../components';
import { useAuth } from '../../hooks';
import { Placement, UserRole } from '../../types';
import {
  formatPlacementRowsForAdmin,
  formatPlacementRowsForConsultant,
  formatPlacementRowsForConsultantMobile,
} from './data';

export const Placements = () => {
  const auth = useAuth();
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const data = useLoaderData() as Placement[];
  const isMobile = width < 500;

  const rows =
    auth.state.user?.role === UserRole.Admin
      ? formatPlacementRowsForAdmin(data, (id: string) => navigate(id))
      : formatPlacementRowsForConsultant(data, (id: string) => navigate(id));

  const mobileRows = formatPlacementRowsForConsultantMobile(
    data,
    (id: string) => navigate(id)
  );

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper>
          <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
          <Title as="h2" type="secondary" weight="500">
            {auth.state.user?.name}
          </Title>
          <Spacer minSpace="1rem" />
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              navigate('create');
            }}
          >
            Lägg till ny placerad
          </PrimaryButton>
        </MobileTopBarWrapper>

        <Table
          rows={auth.state.user?.role === UserRole.Admin ? rows : mobileRows}
          buttonMaxWidth={'3.25rem'}
          sortingField="familyHome"
          sortingOrder={SortOrder.DESC}
        />
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <Title as="h1" type="secondary" weight="500">
            Placeringar
          </Title>
        }
        right={
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              navigate('create');
            }}
          >
            Lägg till ny placerad
          </PrimaryButton>
        }
      />

      <Table
        rows={rows}
        buttonMaxWidth={'3.25rem'}
        sortingField="familyHome"
        sortingOrder={SortOrder.DESC}
      />
    </PageWrapper>
  );
};
