import { Button } from '@mantine/core';
import { ModalTitle } from '@atlaskit/modal-dialog';
import { Form } from 'react-router-dom';

import { BaseModal } from '.';
import { CancelButton } from '../CancelButton';

interface DeleteModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onClose?: () => void;
  content: string | React.ReactNode;
  title?: string;
  continueText?: string;
  action?: string;
  redirectPath?: string;
}

export const DeleteModal = ({
  isOpen,
  onCancel,
  onClose,
  content,
  title,
  continueText,
  action,
  redirectPath,
}: DeleteModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <ModalTitle appearance="danger">
          {title ?? 'Är du säker på att du vill ta bort?'}
        </ModalTitle>
      }
      content={
        <>
          <p>{content}</p>
        </>
      }
      buttons={
        <>
          <CancelButton onClick={onCancel} />
          <Form method="post" action={action ?? 'delete'}>
            {redirectPath && (
              <input type="hidden" name="redirectPath" value={redirectPath} />
            )}
            <Button
              type="submit"
              color="red"
              sx={{ fontSize: 'initial' }}
              onClick={onClose}
            >
              {continueText ?? 'Ja, radera'}
            </Button>
          </Form>
        </>
      }
    />
  );
};
