import { Form } from 'react-router-dom';
import { Group, Select } from '@mantine/core';
import { useForm, isNotEmpty, FORM_INDEX, isEmail } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  BasicInfoFields,
  ParentFields,
  RelatedPersonWrapper,
  SiblingFields,
  SiblingType,
} from './subs';
import { CancelButton, PrimaryButton, Spacer, Text, FormWrapper } from '..';
import { COLORS } from '../../constants';
import { StyledFormGroup } from './styles';
import { initialPrimaryContact, initialRelatedPerson } from './data';
import { FamilyHome } from '../../types';
import { getRelatedPersonsByRelationTypes } from '../../utils/getRelatedPersonByRelationTypes';
import { formatPlacementsToRelatedPersons } from '../../pages/FamilyHomeDetail/data';

interface FamilyHomeFormProps {
  onSubmit: (data: any) => void;
  familyHome?: FamilyHome;
  isMobile?: boolean;
}

interface FHFormRelatedPerson {
  firstName: string;
  lastName?: string;
  birthDay: string;
  email: string;
  pet?: string;
  phone?: string;
  primaryContact: boolean;
  relation: string;
}

interface FamilyHomeFormData {
  id?: string;
  adress: string;
  zipCode: string;
  city: string;
  multiplePlacement: boolean;
  parents: FHFormRelatedPerson[];
  biologicalSiblings: FHFormRelatedPerson[];
  placedSiblings: FHFormRelatedPerson[];
  pets: FHFormRelatedPerson[];
}

export const FamilyHomeForm = ({
  onSubmit,
  familyHome,
  isMobile,
}: FamilyHomeFormProps) => {
  const navigate = useNavigate();

  const formattedPlacements = formatPlacementsToRelatedPersons(
    familyHome?.placements ?? []
  );

  const parents = getRelatedPersonsByRelationTypes(
    familyHome?.relatedPersons ?? [],
    ['FamilyHomeFather', 'FamilyHomeMother']
  );
  const biologicalSiblings = getRelatedPersonsByRelationTypes(
    familyHome?.relatedPersons ?? [],
    ['Biologicalchild']
  );
  const pets = getRelatedPersonsByRelationTypes(
    familyHome?.relatedPersons ?? [],
    ['Pet']
  );

  const form = useForm<FamilyHomeFormData>({
    validateInputOnBlur: [
      'adress',
      'zipCode',
      'city',
      `parents.${FORM_INDEX}.firstName`,
      `parents.${FORM_INDEX}.lastName`,
      `parents.${FORM_INDEX}.email`,
      `parents.${FORM_INDEX}.phone`,
      `parents.${FORM_INDEX}.relation`,
      `biologicalSiblings.${FORM_INDEX}.firstName`,
      `placedSiblings.${FORM_INDEX}.firstName`,
      `pets.${FORM_INDEX}.pet`,
    ],
    initialValues: {
      adress: familyHome?.address ?? '',
      zipCode: familyHome?.zipCode ?? '',
      city: familyHome?.city ?? '',
      multiplePlacement: familyHome?.multiplePlacement ?? false,
      parents:
        parents.length > 0
          ? parents
          : [
              {
                ...initialPrimaryContact,
              },
            ],
      biologicalSiblings: biologicalSiblings,
      placedSiblings: formattedPlacements,
      pets: pets,
    },

    validate: {
      adress: isNotEmpty('Ange en adress'),
      zipCode: isNotEmpty('Ange ett postnummer'),
      city: isNotEmpty('Ange en ort'),
      parents: {
        firstName: isNotEmpty('Ange ett förnamn'),
        lastName: isNotEmpty('Ange en efternamn'),
        email: isEmail('Ange en e-postadress'),
        phone: isNotEmpty('Ange ett telefonnummer'),
        primaryContact: (value, values) => {
          if (value) return null;
          const primaryContactExists =
            values.parents.find((parent) => parent.primaryContact === true) !=
            null;
          return primaryContactExists ? null : 'Ange en primär kontaktperson';
        },
        relation: isNotEmpty('Ange roll i hemmet'),
        birthDay: (value) =>
          dayjs(value).isValid() && dayjs(value) < dayjs()
            ? null
            : 'Ange ett giltigt födelsedatum',
      },
      biologicalSiblings: {
        firstName: isNotEmpty(
          'Ange det biologiska syskonets initialer eller benämning'
        ),
        birthDay: (value) =>
          dayjs(value).isValid() && dayjs(value) < dayjs()
            ? null
            : 'Ange ett giltigt födelsedatum',
      },
      placedSiblings: {
        firstName: isNotEmpty(
          'Ange det placerade syskonets initialier eller benämning'
        ),
        birthDay: (value) =>
          dayjs(value).isValid() && dayjs(value) < dayjs()
            ? null
            : 'Ange ett giltigt födelsedatum',
      },
      pets: {
        pet: isNotEmpty('Välj ett husdjur'),
      },
    },
  });

  return (
    <FormWrapper scrollable>
      <Form method="post" style={{ width: isMobile ? '100%' : '27rem' }}>
        <Text>Kontaktuppgifter</Text>

        <Spacer minSpace="1.5rem" />

        <BasicInfoFields form={form} />

        {/* Parent */}
        {form.values.parents.map((person, index) => (
          <ParentFields
            key={index}
            form={form}
            index={index}
            isMobile={isMobile}
          />
        ))}

        {form.values.parents.length < 2 ? (
          <PrimaryButton
            fullWidth
            mt="md"
            onClick={() =>
              form.insertListItem('parents', {
                ...initialRelatedPerson,
              })
            }
          >
            Lägg till familjehemsförälder
          </PrimaryButton>
        ) : null}

        {/* Biological siblings */}
        {form.values.biologicalSiblings.map((person, index) => (
          <SiblingFields
            key={index}
            form={form}
            index={index}
            formListName="biologicalSiblings"
            title="Eget barn"
            textLabel="Barnets initialer eller benämning"
            siblingType={SiblingType.Biological}
            isMobile={isMobile}
          />
        ))}

        <PrimaryButton
          fullWidth
          mt="md"
          onClick={() =>
            form.insertListItem('biologicalSiblings', {
              ...initialRelatedPerson,
              relation: 'Biologicalchild',
            })
          }
        >
          Lägg till familjehemmets egna barn
        </PrimaryButton>

        {/* Placed siblings */}
        {form.values.placedSiblings.map((person, index) => (
          <SiblingFields
            key={index}
            form={form}
            index={index}
            formListName="placedSiblings"
            title="Placerat barn"
            siblingType={SiblingType.Placed}
            isMobile={isMobile}
          />
        ))}

        {/* Pets */}
        {form.values.pets.map((_, index) => (
          <RelatedPersonWrapper
            key={index}
            title="Husdjur"
            index={index}
            onRemoveClick={() => form.removeListItem('pets', index)}
            isMobile={isMobile}
          >
            <StyledFormGroup mt="xs">
              <Select
                name="pet"
                {...form.getInputProps(`pets.${index}.pet`)}
                value={form.getInputProps(`pets.${index}.pet`).value}
                data={[
                  { value: 'hund', label: 'Hund' },
                  { value: 'katt', label: 'Katt' },
                  { value: 'kanin', label: 'Kanin' },
                  { value: 'häst', label: 'Häst' },
                  { value: 'marsvin', label: 'Marsvin' },
                  { value: 'papegoja', label: 'Papegoja' },
                  { value: 'undulat', label: 'Undulat' },
                  { value: 'husdjur', label: 'Annat husdjur' },
                ]}
              />
            </StyledFormGroup>
          </RelatedPersonWrapper>
        ))}

        <PrimaryButton
          fullWidth
          mt="md"
          onClick={() =>
            form.insertListItem('pets', {
              ...initialRelatedPerson,
              relation: 'Pet',
              birthDay: '2023-01-01T00:00:00',
            })
          }
        >
          Lägg till husdjur
        </PrimaryButton>

        <Group position="right" spacing="xs" sx={{ marginTop: '1.5rem' }}>
          <CancelButton
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          />
          <PrimaryButton
            onClick={() => {
              const formHasErrors = form.validate().hasErrors;
              if (formHasErrors) return;
              const familyHome: any = {
                name: `${form.values.parents[0].firstName} ${form.values.parents[0].lastName}`,
                address: form.values.adress,
                zipCode: form.values.zipCode,
                city: form.values.city,
                phoneNumber: form.values.parents[0].phone ?? '',
                multiplePlacement: form.values.multiplePlacement,
                relatedPersons: [
                  ...form.values.parents,
                  ...form.values.biologicalSiblings,
                  ...form.values.placedSiblings,
                  ...form.values.pets,
                ],
              };
              !formHasErrors && onSubmit(familyHome);
            }}
            background={COLORS.hdAccentGreen}
            hover={COLORS.hdAccentGreenDark}
          >
            Spara
          </PrimaryButton>
        </Group>
        <Spacer minSpace="1.5rem" />
      </Form>
    </FormWrapper>
  );
};
