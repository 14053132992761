import {
  Drawer,
  Overlay,
  OverlayProps,
  createPolymorphicComponent,
} from '@mantine/core';
import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const StyledOutletWrapper = styled.div<{
  state: 'idle' | 'submitting' | 'loading';
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${COLORS.hdBackgroundGreenLight};
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const StyledDrawer = styled(Drawer)`
  & .mantine-Drawer-header {
    background-color: ${COLORS.hdBackgroundGreenDark};
    margin-bottom: 0;
    padding: 0.5rem 0.5rem 0 0;
  }

  & .mantine-Drawer-body {
    height: 100%;
  }
`;

const StyledOL = styled(Overlay)`
  height: 100%;
  width: 100%;
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  z-index: 1001;
`;

export const StyledOverlay = createPolymorphicComponent<'div', OverlayProps>(
  StyledOL
);
