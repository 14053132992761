export const getGeneratedSentence = async (
  sentence: string,
  token: string | null
): Promise<Response> =>
  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/Sentences/test?sentence=${sentence}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      },
    }
  )
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while generating sentence', error);
      return error;
    });
