import { ChevronDown } from 'react-feather';
import {
  useLoaderData,
  useActionData,
  useNavigate,
  useSubmit,
} from 'react-router-dom';
import { useState, useEffect } from 'react';

import {
  TopBar,
  PageWrapper,
  Title,
  Table,
  PrimaryButton,
} from '../../components';
import { formatSentencesRows } from './data';
import { Sentence, Category } from '../../types';
import { StyledSelect } from './styles';

export const Sentences = () => {
  const navigate = useNavigate();
  const { forms } = useLoaderData() as {
    forms: Category[];
  };
  const sentences = useActionData() as Sentence[];

  const submit = useSubmit();

  const [selectedMainCategory, setSelectedMainCategory] = useState<
    string | null
  >(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(
    null
  );
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesDisabled, setSubCategoriesDisabled] = useState(true);

  const rows = sentences !== undefined ? formatSentencesRows(sentences) : [];

  const [sentenceRows, setSentenceRows] = useState(rows);

  const mainCategories = forms.map((category: Category) => {
    return { label: category.name, value: category.id };
  });

  useEffect(() => {
    if (selectedMainCategory) {
      setSelectedSubCategory(null);
      setSentenceRows([]);

      const mainCategory: any = forms.find((obj: Category) => {
        return obj.id === selectedMainCategory;
      });

      const subCategoryNames: any = [];

      mainCategory.sections.map((section: any) => {
        section.questions.forEach((question: any) =>
          subCategoryNames.push({ label: question.name, value: question.id })
        );
      });

      setSubCategories(subCategoryNames);
      setSubCategoriesDisabled(false);
    }
  }, [selectedMainCategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      submit(
        { formQuestionId: selectedSubCategory },
        {
          method: 'post',
        }
      );
    }
  }, []);

  useEffect(() => {
    if (selectedSubCategory) {
      submit(
        { formQuestionId: selectedSubCategory },
        {
          method: 'post',
        }
      );
    }
  }, [selectedSubCategory]);

  //When being redirected from delete action
  useEffect(() => {
    if (selectedSubCategory && sentences == undefined) {
      submit(
        { formQuestionId: selectedSubCategory },
        {
          method: 'post',
        }
      );
    }
  }, [sentences]);

  useEffect(() => {
    setSentenceRows(rows);
  }, [sentences]);

  return (
    <PageWrapper>
      <TopBar
        left={
          <Title as="h1" type="secondary" weight="500">
            Meningar
          </Title>
        }
        right={
          <>
            <StyledSelect
              label="Kategori"
              placeholder="Välj huvudkategori"
              value={selectedMainCategory}
              data={mainCategories}
              onChange={setSelectedMainCategory}
              rightSectionWidth={40}
            />
            <StyledSelect
              label="Underkategori"
              placeholder="Välj underkategori"
              value={selectedSubCategory}
              data={subCategories}
              onChange={setSelectedSubCategory}
              disabled={subCategoriesDisabled}
              rightSectionWidth={40}
            />
            <PrimaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate('create');
              }}
            >
              Skapa ny mening
            </PrimaryButton>
          </>
        }
      />
      <Table rows={sentenceRows} buttonMaxWidth={'3.25rem'} />
    </PageWrapper>
  );
};
