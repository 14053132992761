import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { COLORS } from '../../constants';
import styled from '@emotion/styled';

const StyledPB = styled(Button)`
  padding-left: 0;
  color: ${COLORS.hdAccentGreenLight};
  background-color: ${COLORS.hdBackgroundGreenDark};
  &:hover {
    background-color: ${COLORS.hdBackgroundGreenDark};
    color: ${COLORS.hdAccentGreenDark};
  }
`;

//This function call is needed because emotion cannot correctly extract prop types from polymorphic components.
//https://mantine.dev/styles/styled/
//an easier (and more messy) approach to styling polymorphic components
//is to use the sx-prop and add inline styles. See: https://mantine.dev/styles/sx/
export const StyledProfileButton = createPolymorphicComponent<
  'button',
  ButtonProps
>(StyledPB);
