import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import {
  TopBar,
  PageWrapper,
  Title,
  Table,
  DeleteModal,
  MobileBackgroundWrapper,
  MobileTopBarWrapper,
  Spacer,
  PrimaryButton,
  MobileContentWrapper,
} from '../../components';
import { useAuth } from '../../hooks';
import { MonthlyReport, Tenant } from '../../types';
import { formatReportRows } from './data';
import { useViewportSize } from '@mantine/hooks';

export const ReportList = () => {
  const { state } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [reportIdToDelete, setreportIdToDelete] = useState('');
  const { reports } = useLoaderData() as {
    reports: MonthlyReport[];
    tenants: Tenant[];
  };

  const { width } = useViewportSize();
  const isMobile = width < 500;

  const rows = formatReportRows(reports, state?.token, (id) => {
    setreportIdToDelete(id);
    setIsOpen(true);
  });

  const handleOnDelete = () => {
    setreportIdToDelete('');
    setIsOpen(false);
  };

  useEffect(() => {
    handleOnDelete();
  }, []);

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper>
          <Title as="h2" type="secondary" weight="500">
            Rapporter
          </Title>
        </MobileTopBarWrapper>
        <Table rows={rows} buttonMaxWidth={'3.25rem'} />
        <DeleteModal
          isOpen={isOpen}
          onCancel={handleOnDelete}
          onClose={handleOnDelete}
          action={`${reportIdToDelete}/delete`}
          title="Är du säker?"
          content="Du är på väg att ta bort den här rapporten, är du säker på att du vill fortsätta?"
          continueText="Ja, ta bort rapporten"
        />
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <Title as="h1" type="secondary" weight="500">
            Rapporter
          </Title>
        }
        right={null}
      />

      <Table rows={rows} buttonMaxWidth={'3.25rem'} />
      <DeleteModal
        isOpen={isOpen}
        onCancel={handleOnDelete}
        onClose={handleOnDelete}
        action={`${reportIdToDelete}/delete`}
        title="Är du säker?"
        content="Du är på väg att ta bort den här rapporten, är du säker på att du vill fortsätta?"
        continueText="Ja jag är säker, ta bort rapporten"
      />
    </PageWrapper>
  );
};
