import {
  createDescription,
  deleteDescription,
  updateDescription,
  getDescriptionSearch,
  getDescriptionById,
  getDescriptions,
} from '../../api/descriptions';
import { AuthToken } from '../../types';
import { redirect } from 'react-router-dom';
import { errorHandler } from '../../utils';

export const createDescriptionAction =
  ({ token }: AuthToken) =>
  async ({ request, params /*contains formQuestionId*/ }: any) => {
    const formData = await request.formData();
    const { ...data } = Object.fromEntries(formData) as any;

    const res = await createDescription(data, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    // window.location.reload(); // Last resort, if new is created and then retriggerd again immediately an you get an error screen
    return redirect('/descriptions');
  };

export const updateDescriptionAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const { ...data } = Object.fromEntries(formData) as any;

    const res = await updateDescription(params.descriptionId, data, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect('/descriptions');
  };

export const deleteDescriptionAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await deleteDescription(params.descriptionId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/descriptions`);
  };

export const getDescriptionSearchAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const res = await getDescriptionSearch(data.formQuestionId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };

export const getDescriptionByIdAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    // const formData = await request.formData();
    // const data = Object.fromEntries(formData) as any;

    const res = await getDescriptionById(params.descriptionId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };

export const getDescriptionsAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    // const formData = await request.formData();
    // const data = Object.fromEntries(formData) as any;

    const res = await getDescriptions(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
