export const resetConsultantPassword = async (
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/Consultants/${id}/resetpassword`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      },
    }
  )
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while resetting password of consultant with id: ${id}`,
        error
      );
      return error;
    });
