import { useLoaderData, useSubmit } from 'react-router-dom';

import { CreateConsultantFormData, Consultant } from '../../types';
import { PageWrapper, ConsultantForm } from '../../components';
import { createFormData } from '../../utils';

export const ConsultantEdit = () => {
  const { consultant } = useLoaderData() as { consultant: Consultant };
  const submit = useSubmit();

  const onSubmit = (data: CreateConsultantFormData) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'put',
    });
  };

  return (
    <PageWrapper>
      <ConsultantForm onSubmit={onSubmit} consultant={consultant} />
    </PageWrapper>
  );
};
