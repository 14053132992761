import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { PrivateRoutes } from '../../utils';

import { StyledErrorPage } from './styles';

export const ErrorPage = () => {
  const { state, logout } = useAuth();
  const error: any = useRouteError();
  //eslint-disable-next-line
  console.error(error);
  const lines = error?.data?.split('-- :');

  useEffect(() => {
    if (error.status === 401) {
      logout();
    }

    if (!state?.token || !state?.user?.exp) {
      logout();
    }

    if (state?.user?.exp && state?.user?.exp < new Date().valueOf() / 1000) {
      logout();
    }
  });

  return (
    <PrivateRoutes>
      <StyledErrorPage>
        <h1>Oops!</h1>
        <p>Ursäkta, men något gick fel.</p>
        <p>
          <i>
            {error.statusText} {error.status}
          </i>
        </p>
        {lines?.length > 0 ? (
          lines.map((line: string, index: number) => (
            <p key={index}>
              <i>{line}</i>
            </p>
          ))
        ) : (
          <div></div>
        )}
      </StyledErrorPage>
    </PrivateRoutes>
  );
};
