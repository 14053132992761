import { ModalTitle } from '@atlaskit/modal-dialog';
import { BaseModal } from '.';
import { CancelButton, WarningButton } from '..';
import { Box, Loader } from '@mantine/core';
import { COLORS } from '../../constants';

interface AKWarningModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
  onClose?: () => void;
  content: string | React.ReactNode;
  title?: string;
  continueText?: string;
  isLoading?: boolean;
}

export const WarningModal = ({
  isOpen,
  onCancel,
  onContinue,
  onClose,
  title,
  content,
  continueText,
  isLoading,
}: AKWarningModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          <ModalTitle appearance="warning">
            {title ?? 'Är du säker?'}
          </ModalTitle>
          <Loader
            visibility={isLoading ? 'visible' : 'hidden'}
            variant="oval"
            color={COLORS.brandPink}
            size="md"
          />
        </>
      }
      content={
        <>
          <p>{content}</p>
        </>
      }
      buttons={
        <>
          <CancelButton onClick={onCancel} disabled={isLoading} />
          <WarningButton
            color="yellow"
            onClick={onContinue}
            disabled={isLoading}
          >
            {continueText ?? 'Ja, fortsätt ändå'}
          </WarningButton>
        </>
      }
    />
  );
};
