import { StyledGeneralFormWrapper } from './styles';

interface FormWrapperProps {
  children: React.ReactNode;
  //scrollable makes content scroll when larger than its container, but also uncenters content vertically
  scrollable?: boolean;
  widthCss?: string;
  paddingCss?: string;
  justifyContent?: string;
}

export const FormWrapper = ({
  children,
  scrollable = false,
  widthCss,
  paddingCss,
  justifyContent,
}: FormWrapperProps) => (
  <StyledGeneralFormWrapper
    scrollable={scrollable}
    widthCss={widthCss}
    paddingCss={paddingCss}
    justifyContent={justifyContent}
  >
    {children}
  </StyledGeneralFormWrapper>
);
