import styled from '@emotion/styled';
import { COLORS } from '../../constants';

interface StyledTableWrapperProps {
  buttonMaxWidth?: string;
  paddingCss?: string;
}

export const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${COLORS.hdBackgroundWhite};
  padding: 2.5rem 5rem 0;
  padding: ${(props) =>
    props.paddingCss ? props.paddingCss : '2.5rem 5rem 0'};

  @media (max-width: 960px) {
    padding: ${(props) =>
      props.paddingCss ? props.paddingCss : '1.5rem 3rem;'};
  }

  thead tr th {
    border-bottom: 2px solid ${COLORS.hdAccentTableBorderGrey};
  }

  tr:hover {
    cursor: pointer;
  }

  tr:last-child td {
    border-bottom: 2px solid ${COLORS.hdAccentTableBorderGrey};
  }

  button {
    width: 100%;
    max-width: ${(props) => props.buttonMaxWidth || '5.75rem'};
  }
`;
