import styled from '@emotion/styled';

export const StyledTopBar = styled.div`
  display: flex;
  width: 100%;
  top: 0px;
  margin-bottom: 1rem;

  @media (max-width: 540px) {
    padding: 0 1rem;
  }
`;

export const StyledTopBarTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 10;

  h1 {
    padding: 0.5rem 0;
  }
`;

export const StyledTopBarButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 0.5rem;
  }
`;
