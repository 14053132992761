import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { loginAction, logoutAction } from './api';
import { ErrorPage, Root, Login, Dashboard } from './pages';
import { AuthContextProvider } from './contexts';
import {
  consultantRoutes,
  tenantRoutes,
  familyHomeRoutes,
  placementRoutes,
  sentenceRoutes,
  reportsRoutes,
  profileRoutes,
  descriptionRoutes,
} from './routes';
import { useAuth } from './hooks';
import { UserRole } from './types';

import reportWebVitals from './reportWebVitals';
import './index.css';
import { NoteProvider } from './contexts/NoteContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App = () => {
  const { state } = useAuth();

  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
      errorElement: <ErrorPage />,
      action: loginAction,
    },
    {
      path: '/logout',
      errorElement: <ErrorPage />,
      action: logoutAction({ token: state?.token }),
    },
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
          errorElement: <ErrorPage />,
        },
        ...tenantRoutes(state?.token),
        ...consultantRoutes(state?.token),
        ...familyHomeRoutes(state?.token, state?.user?.role === UserRole.Admin),
        ...placementRoutes(state?.token, state?.user?.role === UserRole.Admin),
        ...sentenceRoutes(state?.token),
        ...descriptionRoutes(state?.token),
        ...reportsRoutes(
          state?.token,
          state?.user?.role === UserRole.GlobalAdmin
        ),
        ...profileRoutes(state?.token),
      ],
    },
  ]);

  return (
    <NoteProvider token={state.token} userRole={state.user?.role}>
      <RouterProvider router={router} />
    </NoteProvider>
  );
};

root.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
