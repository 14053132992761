import styled from '@emotion/styled';
import { EvaluationBoxProps } from '.';

export const StyledEvaluationBox = styled.div<EvaluationBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1.25rem;
  width: 6.875rem;
  border: 3px solid ${(props) => props.color};
  background: ${(props) => (props.selected ? props.color : 'none')};
  text-align: center;
  cursor: pointer;
`;

export const SummaryStyledEvaluationBox = styled.div<EvaluationBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  width: 14rem;
  height: 6rem;
  border: 3px solid ${(props) => props.color};
  background: ${(props) => (props.selected ? props.color : 'none')};
  text-align: center;
  cursor: pointer;
`;
