import { NumberInput } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

interface NumberSelectProps {
  numberValue?: number;
  optionValue?: string;
  numberInputPlaceHolder?: string;
  numberLabel?: string;
  numberInputOnChange: (value: number) => void;
}

export const NumberSelect = ({
  numberValue,
  optionValue,
  numberInputPlaceHolder,
  numberLabel,
  numberInputOnChange,
}: NumberSelectProps) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;
  return (
    <>
      <NumberInput
        defaultValue={0}
        placeholder={numberInputPlaceHolder}
        label={numberLabel}
        min={0}
        max={1000}
        onChange={numberInputOnChange}
        value={numberValue}
      />
    </>
  );
};
