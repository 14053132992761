import dayjs from 'dayjs';
import { FamilyRelation, Placement, RelatedPerson } from '../../types';
import { getFamilyRelationText } from '../../utils/getFamilyRelationText';
import { BirthdayIcon } from '../../components';

export const formatFHDetailRows = (
  relatedPersons: RelatedPerson[],
  onPlacementClick?: (path: string) => void
) =>
  relatedPersons?.map((person) => ({
    id: person.id,
    onClick:
      person?.relation === 'OtherPlacedchild' && onPlacementClick
        ? () => onPlacementClick(`/placements/${person.id}`)
        : undefined,
    cells: [
      {
        name: 'role',
        label: 'Roll',
        isSortable: true,
        value: FamilyRelation[person.relation as keyof typeof FamilyRelation],
        content: getFamilyRelationText(
          FamilyRelation[person.relation as keyof typeof FamilyRelation]
        ),
      },
      {
        name: 'name',
        label: 'Namn',
        isSortable: true,
        value: person.firstName,
        content:
          person?.relation === 'Pet'
            ? person?.pet
            : `${person.firstName || ''} ${person.lastName || ''}`,
      },
      {
        name: 'birthday',
        label: 'Födelsedatum',
        isSortable: true,
        value:
          (person?.birthDay &&
            dayjs(new Date(person?.birthDay)).format('YYYY-MM-DD')) ||
          '',
        content:
          person?.relation === 'Pet'
            ? '-'
            : (person?.birthDay &&
                dayjs(new Date(person?.birthDay)).format('YYYY-MM-DD')) ||
              '',
      },
      {
        name: 'birthDaySoon',
        label: '',
        isSortable: false,
        content:
          person?.birthDayNotification && person.birthDayNotification ? (
            <BirthdayIcon />
          ) : (
            ''
          ),
      },
    ],
  }));

export const formatPlacementsToRelatedPersons = (
  placements: Placement[]
): RelatedPerson[] => {
  if (!placements) {
    return [];
  }

  return placements.map((placement): RelatedPerson => {
    return {
      id: placement.id,
      firstName: placement.name,
      lastName: '',
      birthDay: dayjs(placement.birthDay).format('YYYY-MM-DD'),
      birthDayNotification: placement.birthDayNotification,
      email: '',
      pet: '',
      phone: '',
      primaryContact: false,
      relation: 'OtherPlacedchild',
    };
  });
};

export const sanitizeRelatedPersons = (
  relatedPersons: RelatedPerson[]
): RelatedPerson[] => {
  if (!relatedPersons) {
    return [];
  }

  return relatedPersons.filter(
    (person) => person.relation !== 'OtherPlacedchild'
  );
};
