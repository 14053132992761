import { useState } from 'react';

interface UseNewPasswordHook {
  newPassword: string;
  fetchNewPassword: (
    apiFunction?: () => Promise<Response>,
    password?: string
  ) => void;
  loading: boolean;
  called: boolean;
  reset: () => void;
}

export const useNewPassword = (): UseNewPasswordHook => {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);

  const fetchNewPassword = (
    apiFunction?: () => Promise<Response>,
    password?: string
  ) => {
    setLoading(true);
    setCalled(true);

    if (password) {
      setNewPassword(password);
      setLoading(false);
    } else {
      apiFunction &&
        apiFunction().then(async (res) => {
          const data = await res.json();
          setNewPassword(data.newPassword ? data.newPassword : data.password);
          setLoading(false);
        });
    }
  };

  const reset = () => {
    setNewPassword('');
    setLoading(false);
    setCalled(false);
  };

  return { newPassword, fetchNewPassword, loading, called, reset };
};
