import { redirect } from 'react-router-dom';
import { AuthToken } from '../types';
import { errorHandler } from '../utils';

export const updateRole = async (
  userId: string,
  roleId: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Roles/user`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId: userId, roleId: roleId }),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while updating the role with id: ${userId}`,
        error
      );
      return error;
    });

export const updateRoleAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await updateRole(params.consultantId, params.roleId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/consultants/${params.consultantId}`);
  };
