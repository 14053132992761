import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks';
import { UserRole } from '../../types';

export const Dashboard = () => {
  const {
    state: { user },
  } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    switch (user?.role) {
      case UserRole.GlobalAdmin:
        navigate('/tenants');
        break;
      case UserRole.Admin:
        if (pathname !== '/placements') navigate('/placements');
        break;
      case UserRole.Consultant:
        if (pathname !== '/placements') navigate('/placements');
        break;
      default:
        break;
    }
  }, [pathname, navigate, user?.role]);

  //TODO implement dashboard/landing page here
  return <div></div>;
};
