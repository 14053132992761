import { Title, Text, Spacer } from '../../../../components';
import { COLORS } from '../../../../constants';

export const OtherInformation = ({
  multiplePlacement,
}: {
  multiplePlacement: boolean;
}) => {
  return (
    <>
      <Title as="h2" type="secondary" weight="500">
        Övriga uppgifter
      </Title>
      <Spacer minSpace="1rem" maxSpace="1rem" />
      <Text weight="600px" size="12px" color={COLORS.textDisabled}>
        {'Fler placerade från annan aktör:'}
      </Text>
      <Spacer minSpace="1rem" maxSpace="1rem" />
      <Text color={COLORS.textDisabled} size="17px">
        {multiplePlacement ? 'Ja' : 'Nej'}
      </Text>
    </>
  );
};
