export const validateNumber = (number?: string) => {
  const error = 'INVALID_NUMBER';

  if (typeof number !== 'string') return error;

  if (number.trim() === '') return error;

  if (isNaN(+number)) return error;

  //if validation result is undefined value is correct
  //https://atlassian.design/components/form/examples#validation
  return;
};

export const validateEmail = (email?: string) => {
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const result = expression.test(email ?? '');

  if (!result) return 'INVALID_EMAIL';

  //if validation result is undefined value is correct
  //https://atlassian.design/components/form/examples#validation
  return;
};

//handle validation errors from backend
export const errorHandler = async (res: Response) => {
  const error = await res.json();

  return new Response(`${res.statusText} : ${error.detail}`, {
    status: res.status,
    statusText: error.title,
  });
};
