import { RouteObject } from 'react-router-dom';
import { Sentences, SentenceCreate, ErrorPage } from '../../pages';
import {
  createSentencesAction,
  deleteSentenceAction,
  getGeneratedSentenceAction,
  getSentencesAction,
  updateSentenceAction,
} from './actions';
import { sentenceEditLoader, sentenceLoader } from './loaders';
import { AiProvider } from '../../contexts/AiContext';

const SentenceCreateWithAiProvider: React.FC<{ token: string | null }> = ({
  token,
}) => {
  return (
    <AiProvider token={token}>
      <SentenceCreate />
    </AiProvider>
  );
};

export const sentenceRoutes = (token: string | null = null): RouteObject[] => [
  {
    path: 'sentences',
    element: <Sentences />,
    errorElement: <ErrorPage />,
    loader: sentenceLoader({ token }),
    action: getSentencesAction({ token }),
  },
  {
    path: 'sentences/create',
    element: <SentenceCreateWithAiProvider token={token} />,
    errorElement: <ErrorPage />,
    loader: sentenceLoader({ token }),
    action: getGeneratedSentenceAction({ token }),
  },
  {
    path: 'sentences/create/save/:formQuestionId',
    errorElement: <ErrorPage />,
    action: createSentencesAction({ token }),
  },
  {
    path: 'sentences/:sentenceId/edit',
    element: <SentenceCreateWithAiProvider token={token} />,
    errorElement: <ErrorPage />,
    loader: sentenceEditLoader({ token }),
    action: getGeneratedSentenceAction({ token }),
  },
  {
    path: 'sentences/:sentenceId/update',
    errorElement: <ErrorPage />,
    action: updateSentenceAction({ token }),
  },
  {
    path: 'sentences/:sentenceId/delete',
    errorElement: <ErrorPage />,
    action: deleteSentenceAction({ token }),
  },
];
