import { Checkbox, Select, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { DateOfBirth } from '../../../';
import { Spacer } from '../../..';
import { RelatedPersonWrapper } from '..';
import { StyledFormGroup } from '../../styles';
import { getFamilyRelationText } from '../../../../utils';
import { FamilyRelation, RelatedPerson } from '../../../../types';

interface ParentFieldsProps {
  form: UseFormReturnType<any>;
  index: number;
  isMobile?: boolean;
}

export const ParentFields = ({ form, index, isMobile }: ParentFieldsProps) => {
  const parents = form.values.parents;
  const onlyParent = parents.length === 1;
  const otherParentIsPrimaryContact =
    parents.filter(
      (parent: RelatedPerson, parentIndex: number) =>
        parentIndex !== index && parent.primaryContact === true
    ).length > 0;

  return (
    <RelatedPersonWrapper
      title={'Familjehemsförälder'}
      index={index}
      onRemoveClick={() => form.removeListItem('parents', index)}
      primaryContact={onlyParent}
      isMobile={isMobile}
    >
      <Spacer minSpace="1rem" />

      <Checkbox
        {...form.getInputProps(`parents.${index}.primaryContact`)}
        checked={form.getInputProps(`parents.${index}.primaryContact`).value}
        label="Primär kontaktperson"
        disabled={otherParentIsPrimaryContact}
      />

      <Spacer minSpace="1rem" />

      <Select
        label="Roll"
        {...form.getInputProps(`parents.${index}.relation`)}
        placeholder="Roll"
        data={[
          {
            value: 'FamilyHomeMother',
            label: getFamilyRelationText(FamilyRelation['FamilyHomeMother']),
          },
          {
            value: 'FamilyHomeFather',
            label: getFamilyRelationText(FamilyRelation['FamilyHomeFather']),
          },
          {
            value: 'FamilyHomeThey',
            label: getFamilyRelationText(FamilyRelation['FamilyHomeThey']),
          },
        ]}
      />

      <StyledFormGroup mt="xs">
        <TextInput
          label="Förnamn"
          placeholder="Förnamn"
          name="firstName"
          {...form.getInputProps(`parents.${index}.firstName`)}
        />
        <TextInput
          label="Efternamn"
          placeholder="Efternamn"
          name="lastName"
          {...form.getInputProps(`parents.${index}.lastName`)}
        />
        <TextInput
          label="E-postadress"
          placeholder="E-postadress"
          name="email"
          {...form.getInputProps(`parents.${index}.email`)}
        />
        <TextInput
          label="Telefonnummer"
          placeholder="Telefonnummer"
          name="phone"
          {...form.getInputProps(`parents.${index}.phone`)}
        />
      </StyledFormGroup>

      <DateOfBirth
        form={form}
        index={index}
        formListName={'parents'}
        person={parents[index]}
      />
    </RelatedPersonWrapper>
  );
};
