import { FamilyRelation } from '../types';

export const getFamilyRelationText = (
  familyRelation: FamilyRelation
): string => {
  switch (familyRelation) {
    case FamilyRelation.FamilyHome:
      return 'Familjehemmet';
    case FamilyRelation.FamilyHomeMother:
      return 'Familjehemsmamma';
    case FamilyRelation.FamilyHomeFather:
      return 'Familjehemspappa';
    case FamilyRelation.FamilyHomeThey:
      return 'Familjehemshen';
    case FamilyRelation.OtherPlacedchild:
      return 'Placerat barn';
    case FamilyRelation.Biologicalchild:
      return 'Eget barn';
    case FamilyRelation.Pet:
      return 'Husdjur';
    default:
      return 'Ej valt';
  }
};
