import { Box } from '@mantine/core';
import { CancelButton, Spacer, Text, Title } from '../../..';
import { Link } from 'react-router-dom';

interface RelatedPersonWrapperProps {
  index: number;
  onRemoveClick: () => void;
  title: string;
  primaryContact?: boolean;
  children: React.ReactNode;
  showPlacedPath?: string;
  isMobile?: boolean;
}

export const RelatedPersonWrapper = ({
  index,
  title,
  onRemoveClick,
  children,
  primaryContact,
  showPlacedPath = undefined,
  isMobile,
}: RelatedPersonWrapperProps) => (
  <>
    <Spacer minSpace="2rem" />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Title
        as={isMobile ? 'h2' : 'h1'}
        weight="500"
        type="secondary"
      >{`${title} ${index + 1}`}</Title>
      {!primaryContact &&
        (showPlacedPath ? (
          <Link to={showPlacedPath}>
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <CancelButton onClick={() => {}}>Till placerad</CancelButton>
          </Link>
        ) : (
          <CancelButton onClick={onRemoveClick}>Ta bort</CancelButton>
        ))}
    </Box>
    {children}
  </>
);
