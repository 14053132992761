import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import {
  getForms,
  getPlacements,
  createNote,
  getNotesSearchByUser,
  deleteNote as deleteNoteApi,
  updateNote,
} from '../api';
import { NoteFormData, Note } from '../types/Note';
import { Category, Placement, UserRole } from '../types';
import { NoteReportRepeat } from '../pages/Root/subs/Notes/subs/Notepad';
import dayjs from 'dayjs';
import { Edit, Trash2 } from 'react-feather';
import { Text, WarningModal } from '../components';
import { CustomTooltip } from '../components/CustomTooltip';
import { COLORS } from '../constants';
import { Box, Dialog, Loader } from '@mantine/core';

type NoteContextType = {
  notes: Note[];
  addNote: (noteFormData: NoteFormData) => Promise<boolean>;
  forms: Category[];
  placements: Placement[];
  updateNoteContextData: (token: string | null) => Promise<void>;
  placementsAreLoading: boolean;
  getPlacementNoteTableRowsConsultant: (placementNotes: Note[]) => any;
  getNoteTableRowsConsultant: (notesList: Note[]) => any;
  deleteNote: (noteId: string) => Promise<boolean>;
  notepadOpen: boolean;
  setNotepadOpen: React.Dispatch<React.SetStateAction<boolean>>;
  startEditingNote: (note: Note) => void;
  selectedNote: Note | null;
  setSelectedNote: React.Dispatch<React.SetStateAction<Note | null>>;
  editNote: (noteId: string, noteFormData: NoteFormData) => Promise<boolean>;
};

const NoteContext = createContext<NoteContextType | undefined>(undefined);

type NoteProviderProps = {
  children: ReactNode;
  token: string | null; // Pass the auth token to the provider
  userRole?: UserRole;
};

export const NoteProvider = ({
  children,
  token,
  userRole,
}: NoteProviderProps) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [forms, setForms] = useState<Category[]>([]);
  const [placements, setPlacements] = useState<Placement[]>([]);
  const [placementsAreLoading, setPlacementsAreLoading] =
    useState<boolean>(false);

  const [notepadOpen, setNotepadOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<null | Note>(null);
  const [deleteNoteWarningOpen, setDeleteNoteWarningOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<null | Note>(null);

  const LOAD_NOTES_TIME_PERIOD_DAYS = 365;

  useEffect(() => {
    if (token && userRole === UserRole.Consultant) {
      updateNoteContextData(token);
    }
  }, [token]);

  // ======= Note context methods =======

  const updateNoteContextData = async (token: string | null) => {
    getForms(token).then((response) => {
      // Load Async
      if (response.ok) {
        response.json().then((data: Category[]) => {
          setForms(data);
        });
      } else {
        console.error('Failed to fetch forms');
      }
    });

    setPlacementsAreLoading(true);

    getPlacements(token).then((response) => {
      // Load Async
      if (response.ok) {
        response.json().then((data: Placement[]) => {
          setPlacements(data);
        });
      } else {
        console.error('Failed to fetch placements');
      }
      setPlacementsAreLoading(false);
    });

    loadNotes();
  };

  // ====================================

  // ======= Note table rows methods =======

  const getNoteTableRowsConsultant = (notesList: Note[]) => {
    const noteRows =
      notesList && notesList.length > 0
        ? notesList.map((note, index) => ({
            id: index.toString(),
            cells: [
              {
                name: 'date',
                label: 'Datum',
                isSortable: true,
                value: note.createdAt,
                content: (
                  <div
                    style={{
                      minWidth: '100px',
                    }}
                  >
                    {dayjs(note.createdAt).format('YYYY-MM-DD')}
                  </div>
                ),
              },
              {
                name: 'edit',
                label: 'Redigera',
                isSortable: false,
                value: dayjs(note.createdAt).format('YYYY-MM-DD'),
                content: (
                  <Edit
                    color={COLORS.hdAccentBlue}
                    style={{
                      marginRight: '0.3rem',
                      cursor: 'pointer',
                    }}
                    size={20}
                    onClick={() => startEditingNote(note)}
                  />
                ),
              },
              {
                name: 'placement',
                label: 'Placerad',
                isSortable: true,
                value: placements.find(
                  // TODO: Include placements directley in notes API call instead?
                  (placement) => placement.id === note.placementId
                )?.name,
                content: placements.find(
                  (placement) => placement.id === note.placementId
                )?.name || (
                  <Box
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: 'fit-content',
                      display: 'flex',
                    }}
                  >
                    <Loader variant="oval" color={COLORS.brandPink} size="sm" />
                  </Box>
                ),
              },
              {
                name: 'note',
                label: 'Anteckning',
                isSortable: false,
                value: note.text,
                content: (
                  <CustomTooltip
                    cssBottom="-20px"
                    cssLeft="150px"
                    maxHeight="100px"
                    hoverText={note.text}
                  >
                    <div
                      style={{
                        maxWidth: '250px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {note.text}
                    </div>
                  </CustomTooltip>
                ),
              },
              {
                name: 'noteReportRepeat',
                label: 'Skall med i rapporter?',
                isSortable: true,
                value:
                  note.noteReportRepeat === NoteReportRepeat.All
                    ? 'Alla nuvarande och framtida'
                    : note.noteReportRepeat === NoteReportRepeat.Never
                    ? 'Nej'
                    : 'Specifika rapporter',
                content:
                  note.noteReportRepeat === NoteReportRepeat.All
                    ? 'Alla nuvarande och framtida'
                    : note.noteReportRepeat === NoteReportRepeat.Never
                    ? 'Nej'
                    : 'Specifika rapporter',
              },
              {
                name: 'reportCategory',
                label: 'Kategori',
                isSortable: true,
                value: note.formStep?.name || '-',
                content: note.formStep?.name || '-',
              },
              {
                name: 'delete',
                label: 'Ta bort',
                isSortable: false,
                content: (
                  <div
                    style={{
                      minWidth: '70px',
                    }}
                  >
                    <Trash2
                      size={'20px'}
                      onClick={() => {
                        setNoteToDelete(note);
                        setDeleteNoteWarningOpen(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                ),
              },
            ],
          }))
        : [];

    return noteRows;
  };

  const getPlacementNoteTableRowsConsultant = (placementNotes: Note[]): any => {
    if (!placementNotes) return [];

    return getNoteTableRowsConsultant(placementNotes);
  };

  // =======================================

  // ===== Notes API calls =====
  const loadNotes = async () => {
    getNotesSearchByUser(token, LOAD_NOTES_TIME_PERIOD_DAYS).then(
      (response) => {
        if (response.ok) {
          response.json().then((data: Note[]) => {
            setNotes(data);
          });
        } else {
          console.error('Failed to load notes');
        }
      }
    );
  };

  const addNote = async (noteFormData: NoteFormData): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      createNote(noteFormData, token).then((response) => {
        if (response.ok) {
          loadNotes();
          showResultDialog(true, 'Anteckningen har sparats');
          resolve(true);
        } else {
          console.error('Failed to add note');
          showResultDialog(false, 'Anteckningen kunde inte sparas!');
          reject(false);
        }
      });
    });
  };

  const deleteNote = async (noteId: string): Promise<boolean> => {
    setNoteToDelete(null);
    setDeleteNoteWarningOpen(false);
    return new Promise((resolve, reject) => {
      if (!noteId) reject('No noteId provided!');

      deleteNoteApi(noteId, token).then((response) => {
        if (response.ok) {
          loadNotes();
          showResultDialog(true, 'Anteckningen har tagits bort');
          resolve(true);
        } else {
          console.error('Failed to delete note');
          showResultDialog(false, 'Anteckningen kunde inte tas bort!');
          reject(false);
        }
      });
    });
  };

  const editNote = async (
    noteId: string,
    noteFormData: NoteFormData
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      updateNote(noteId, noteFormData, token).then((response) => {
        if (response.ok) {
          loadNotes();
          showResultDialog(true, 'Anteckningen har uppdaterats');
          resolve(true);
        } else {
          console.error('Failed to add note');
          showResultDialog(false, 'Anteckningen kunde inte uppdateras!');
          reject(false);
        }
      });
    });
  };
  // ===========================

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    value: false,
    text: '',
  });
  const [showErrorMessage, setShowErrorMessage] = useState({
    value: false,
    text: '',
  });

  const showResultDialog = (successful: boolean, text: string) => {
    if (successful) {
      setShowSuccessMessage({ value: true, text: text });

      setTimeout(() => {
        setShowSuccessMessage({ value: false, text: '' });
      }, 5000);
    } else {
      setShowErrorMessage({ value: true, text: text });

      setTimeout(() => {
        setShowErrorMessage({ value: false, text: '' });
      }, 5000);
    }
  };

  const startEditingNote = (note: Note) => {
    setSelectedNote(note);
    setNotepadOpen(true);
  };

  useEffect(() => {
    if (!notepadOpen) setSelectedNote(null);
  }, [notepadOpen]);

  return (
    <NoteContext.Provider
      value={{
        notes,
        addNote,
        forms,
        placements,
        updateNoteContextData,
        placementsAreLoading,
        getPlacementNoteTableRowsConsultant,
        getNoteTableRowsConsultant,
        deleteNote,
        notepadOpen,
        setNotepadOpen,
        startEditingNote,
        selectedNote,
        setSelectedNote,
        editNote,
      }}
    >
      {children}
      <Dialog
        opened={showSuccessMessage.value}
        size="md"
        radius="sm"
        style={{ backgroundColor: COLORS.hdAccentGreen }}
        zIndex={2000}
      >
        <Text color={COLORS.textPrimary} size={'14px'}>
          {showSuccessMessage.text}
        </Text>
      </Dialog>
      <Dialog
        opened={showErrorMessage.value}
        size="md"
        radius="sm"
        style={{ backgroundColor: COLORS.hdAccentRed }}
        zIndex={2000}
      >
        <Text color={COLORS.textPrimary} size={'14px'}>
          {showErrorMessage.text}
        </Text>
      </Dialog>

      <WarningModal
        isOpen={deleteNoteWarningOpen}
        onCancel={() => {
          setDeleteNoteWarningOpen(false);
          setNoteToDelete(null);
        }}
        onContinue={() => {
          noteToDelete && deleteNote(noteToDelete.id);
        }}
        title="Tag bort anteckning"
        continueText="Ja, tag bort anteckningen"
        content={
          <>
            <div>Är du säker på att du vill tag bort denna anteckning?</div>
            <p>
              Antecknignen som kommer tas bort:
              <div>"{noteToDelete ? noteToDelete.text : ''}"</div>
            </p>
          </>
        }
      />
    </NoteContext.Provider>
  );
};

export const useNoteContext = (): NoteContextType => {
  const context = useContext(NoteContext);
  if (!context) {
    throw new Error('useNoteContext must be used within a NoteProvider');
  }
  return context;
};
