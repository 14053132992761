import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const StyledPasswordBox = styled.div`
  background-color: ${COLORS.hdBackgroundRed};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 19px;
  margin-bottom: 0.5rem;
  min-height: 82px;

  svg:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const StyledPasswordTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;
