export const getReports = async (token: string | null): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Reports`, {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
    },
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while fetching reports', error);
      return error;
    });
