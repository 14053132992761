import { RouteObject } from 'react-router-dom';

import {
  ErrorPage,
  TenantDetail,
  Tenants,
  TenantEdit,
  TenantCreate,
} from '../../pages';

import { tenantLoader, tenantDetailLoader } from './loaders';

import {
  createTenantAction,
  updateTenantAction,
  deleteTenantAction,
  lockTenantAction,
  unlockTenantAction,
  invoiceTenantAction,
} from './actions';

export const tenantRoutes = (token: string | null = null): RouteObject[] => [
  {
    path: 'tenants',
    element: <Tenants />,
    errorElement: <ErrorPage />,
    loader: tenantLoader({ token }),
  },
  {
    path: 'tenants/:tenantId',
    element: <TenantDetail />,
    errorElement: <ErrorPage />,
    loader: tenantDetailLoader({ token }),
  },
  {
    path: 'tenants/create',
    element: <TenantCreate />,
    errorElement: <ErrorPage />,
    action: createTenantAction({ token }),
  },
  {
    path: 'tenants/:tenantId/edit',
    element: <TenantEdit />,
    errorElement: <ErrorPage />,
    loader: tenantDetailLoader({ token }),
    action: updateTenantAction({ token }),
  },
  {
    path: 'tenants/:tenantId/delete',
    errorElement: <ErrorPage />,
    action: deleteTenantAction({ token }),
  },
  {
    path: 'tenants/:tenantId/lock',
    errorElement: <ErrorPage />,
    action: lockTenantAction({ token }),
  },
  {
    path: 'tenants/:tenantId/unlock',
    errorElement: <ErrorPage />,
    action: unlockTenantAction({ token }),
  },
  {
    path: 'tenants/:tenantId/invoice',
    errorElement: <ErrorPage />,
    action: invoiceTenantAction({ token }),
  },
];
