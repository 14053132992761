import { Form, useNavigate } from 'react-router-dom';
import { Text } from '../../../../../../components';
import { COLORS } from '../../../../../../constants';
import {
  QuestionType,
  ReportCategory,
  ReportQuestion,
} from '../../../../../../types';
import {
  StyledSection,
  StyledReportProgress,
  StyledSectionRowWrapper,
  ReportProgressButton,
} from './styles';

interface SectionProps {
  active: boolean;
  progressValue: number;
  title: string;
  onClick: () => void;
}

const Section = ({ active, title, progressValue, onClick }: SectionProps) => {
  const color = active ? COLORS.textPrimary : COLORS.hdAccentGreenLight;

  return (
    <StyledSection onClick={onClick}>
      <Text size="13px" color={color}>
        {title}
      </Text>
      <Text size="12px" color={color}>
        {progressValue === 100 ? '\u00A0' : `${progressValue}%`}
      </Text>
    </StyledSection>
  );
};

interface ReportProgressProps {
  onGenerateClick: () => void;
  categories: ReportCategory[];
  placementId: string;
}

const getIsQuestionAnswered = (question: ReportQuestion) => {
  if (question.type === QuestionType.DateAndGreenRed) {
    return question.dateValue !== null && question.optionValue !== 'NotSet';
  }

  if (question.type === QuestionType.DropDownAndGreenRed) {
    //If not applicable, you dont have to fill in the dropdown value
    if (question.optionValue === 'NotApplicable') return true;

    return (
      question.optionValue !== 'NotSet' &&
      question.dropDownValue !== null &&
      question.dropDownValue !== 'null'
    );
  }

  if (question.type === QuestionType.NumberAndGreenRed) {
    if (question.optionValue === 'NotApplicable') {
      return true;
    }

    return question.numberValue !== -1 && question.optionValue !== 'NotSet';
  }

  if (question.type === QuestionType.EstimateAndGreenRed) {
    //If not applicable, you dont have to fill in the estimate value
    if (question.optionValue === 'NotApplicable') return true;

    return question.optionValue !== 'NotSet' && question.estimateValue !== 0;
  }

  if (question.type === QuestionType.GreenRed) {
    return question.optionValue !== 'NotSet';
  }

  if (question.type === QuestionType.Date) {
    //Is there a response
    const len =
      question.dateValues?.length === 0 && question.estimateValue !== -1; // you've unchecked the "not relevant but not given a number"
    return !len;
  }

  if (question.type === QuestionType.EstimateChild) {
    return question.estimateValue !== 0;
  }

  if (question.type === QuestionType.Number) {
    return question.numberValue !== -1;
  }

  if (question.type === QuestionType.DropDown) {
    return question.dropDownValue !== null && question.dropDownValue !== 'null';
  }
};

export const ReportProgress = ({
  onGenerateClick,
  categories,
  placementId,
}: ReportProgressProps) => {
  const navigate = useNavigate();
  //This list is depenant on the order of categories sent from backend.
  //If the order changes these titles WILL NOT update dynamically.
  const shortHandTitles = [
    'Sammanfattning',
    'Hälsa',
    'Utbildning',
    'Känslor',
    'Relationer',
    'Självständig',
  ];

  const categoryNames = categories.map((category, index) => {
    const shortTitle = shortHandTitles[index];

    const questions = category.sections.flatMap((section) => section.questions);

    const answeredCount = questions.reduce((acc, curr) => {
      const isAnswered = getIsQuestionAnswered(curr);
      if (isAnswered) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const calculatedProgressValue =
      questions.length === 0 ? 100 : (answeredCount / questions.length) * 100;

    return {
      title: shortTitle, //category.name is too long to work on small screens. Should add as a property in backend
      id: category.id,
      active: false,
      progressValue: Math.round(calculatedProgressValue), //this is very expensive to calculate, have to loop through EVERY question
    };
  });

  const totalProgress =
    categoryNames
      .map((cat) => cat.progressValue)
      .reduce((acc, curr) => {
        return acc + curr;
      }) / categoryNames.length;

  const handleClick = (categoryName: string) => {
    const element = document.getElementById(categoryName);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledReportProgress>
      <StyledSectionRowWrapper>
        {categoryNames.map(({ title, active, progressValue, id }) => {
          return (
            <Section
              onClick={() => handleClick(id)}
              key={title}
              title={title}
              active={active}
              progressValue={progressValue}
            />
          );
        })}
      </StyledSectionRowWrapper>
      <Form
        method="post"
        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <ReportProgressButton
          mb="xs"
          onClick={() => navigate(`/placements/${placementId}`)}
        >
          Spara och fortsätt senare
        </ReportProgressButton>
        <ReportProgressButton
          disabled={totalProgress !== 100}
          onClick={() => {
            totalProgress === 100 && onGenerateClick();
          }}
        >
          Förhandsgranska rapport
        </ReportProgressButton>
      </Form>
    </StyledReportProgress>
  );
};
