import { Group, TextInput } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';

import { useNavigate, Form } from 'react-router-dom';
import { COLORS } from '../../constants';
import { CreateConsultantFormData, Consultant } from '../../types';
import { validateEmail, validateNumber } from '../../utils';

import { CancelButton, FormWrapper, PrimaryButton, Spacer, Text } from '..';

interface consultantFormProps {
  onSubmit: (data: CreateConsultantFormData) => void;
  consultant?: Consultant;
}

export const ConsultantForm = ({
  onSubmit,
  consultant,
}: consultantFormProps) => {
  const navigate = useNavigate();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      firstName: consultant?.firstName ?? '',
      lastName: consultant?.lastName ?? '',
      email: consultant?.email ?? '',
      phoneNumber: consultant?.phoneNumber ?? '',
    },

    validate: {
      firstName: isNotEmpty('Ange ett förnamn på konsulenten'),
      lastName: isNotEmpty('Ange ett efternamn på konsulenten'),
      email: (value) => (validateEmail(value) ? 'Ange en e-postaddress' : null),
      phoneNumber: isNotEmpty('Ange ett telefonnummer'),
    },
  });

  return (
    <FormWrapper scrollable>
      <Form method="post">
        <Text>Konsulentuppgifter</Text>

        <Spacer minSpace="1.5rem" />

        <TextInput
          label="Konsulentens förnamn"
          placeholder="Konsulentens förnamn"
          name="firstName"
          {...form.getInputProps('firstName')}
        />

        <TextInput
          label="Konsulentens efternamn"
          placeholder="Konsulentens efternamn"
          name="lastName"
          {...form.getInputProps('lastName')}
        />

        <TextInput
          label="E-postadress"
          placeholder="E-postadress"
          name="email"
          {...form.getInputProps('email')}
        />
        <TextInput
          label="Telefonnummer"
          placeholder="Telefonnummer"
          name="phoneNumber"
          {...form.getInputProps('phoneNumber')}
        />

        <Group position="right" spacing="xs" sx={{ marginTop: '1.5rem' }}>
          <CancelButton
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          />
          <PrimaryButton
            onClick={() => {
              const formHasErrors = form.validate().hasErrors;
              !formHasErrors && onSubmit(form.values);
            }}
            background={COLORS.hdAccentGreen}
            hover={COLORS.hdAccentGreenDark}
          >
            Spara
          </PrimaryButton>
        </Group>
      </Form>
    </FormWrapper>
  );
};
