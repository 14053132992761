import { useContext } from 'react';
import { AuthContext } from '../contexts';
import { AuthHook, AuthActionType } from '../types';

export const useAuth = (): AuthHook => {
  const context = useContext(AuthContext);

  if (!context)
    throw Error('useAuth must be used within an AuthContextProvider');

  const login = (token: string) => {
    context.dispatch({ type: AuthActionType.LOGIN, payload: token });
    localStorage.setItem('token', token);
  };

  const logout = () => {
    context.dispatch({ type: AuthActionType.LOGOUT });
    localStorage.removeItem('token');
  };

  return { ...context, login, logout, token: context.state.token };
};
