import styled from '@emotion/styled';
import {
  createPolymorphicComponent,
  Overlay,
  OverlayProps,
} from '@mantine/core';
import { COLORS } from '../../constants';

export const ReportFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${COLORS.hdBackgroundWhite};
  padding: 1rem;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
`;

export const DesktopBackground = styled.div`
  background-color: ${COLORS.hdBackgroundWhite};
  width: 100%;
  padding: 3rem 1rem;
`;

const StyledReportOL = styled(Overlay)`
  height: 100%;
  width: 100%;
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
`;

export const StyledReportOverlay = createPolymorphicComponent<
  'div',
  OverlayProps
>(StyledReportOL);

export const TitleWrapper = styled.div<{ id: string }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: flex-start;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

type SectionGridProps = {
  screenWidth: number;
  isSummarySection: boolean;
};

export const SectionGrid = styled.div<SectionGridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.screenWidth < 1000 || props.isSummarySection
      ? '1fr'
      : 'repeat(2, 1fr)'};
  gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;
`;
