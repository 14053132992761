import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const StyledDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 960px) {
    padding: 1.5rem 3rem 0;
  }
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: 0.5rem;
  padding: 1rem;
  background-color: ${COLORS.hdBackgroundWhite};
  overflow: auto;
`;

export const RightContentWrapper = styled.div`
  display: flex;
  width: 65%;
  background-color: ${COLORS.hdBackgroundWhite};
  overflow: auto;
`;
