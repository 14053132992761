import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { StyledEvaluationBox, SummaryStyledEvaluationBox } from './styles';

export interface EvaluationBoxProps {
  selected: boolean;
  color: string;
  children: EmotionJSX.Element;
  onClick: (e: any) => void;
  isSummarySection?: boolean;
  screenWidth?: number;
}

export const EvaluationBox = ({
  selected,
  color,
  children,
  onClick,
  isSummarySection = false,
  screenWidth = undefined,
}: EvaluationBoxProps) => {
  return isSummarySection && screenWidth && screenWidth >= 1000 ? (
    <SummaryStyledEvaluationBox
      selected={selected}
      color={color}
      onClick={onClick}
      isSummarySection={isSummarySection}
    >
      {children}
    </SummaryStyledEvaluationBox>
  ) : (
    <StyledEvaluationBox selected={selected} color={color} onClick={onClick}>
      {children}
    </StyledEvaluationBox>
  );
};
