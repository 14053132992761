import { json, redirect } from 'react-router-dom';
import { getNotesSearchByReport, getPlacementById } from '../../api';
import { getReportById, getReportContent, getReports } from '../../api/reports';
import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const reportsLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const placementRes = await getPlacementById(params.placementId, token);

    if (!placementRes.ok) {
      throw await errorHandler(placementRes);
    }

    let placement;

    try {
      placement = await placementRes.json();
    } catch (error) {
      throw new Error('reportsLoader, Failed parsing json 1');
    }

    const reportRes = params.reportId
      ? await getReportById(token, params.reportId)
      : null;

    if (reportRes && !reportRes.ok) {
      throw await errorHandler(reportRes);
    }

    let report;
    try {
      report = reportRes ? await reportRes.json() : null;
    } catch (error) {
      throw new Error('reportsLoader, Failed parsing json 2');
    }

    return json({ placement, report }, { status: 200 });
  };

export const signingLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const reportId = params.reportId;

    const placementRes = await getPlacementById(params.placementId, token);
    if (!placementRes.ok) {
      throw await errorHandler(placementRes);
    }

    const contentRes = await getReportContent(token, params.reportId);
    if (!contentRes.ok) {
      throw await errorHandler(contentRes);
    }

    const reportNotesRes = await getNotesSearchByReport(reportId, token);
    if (!reportNotesRes.ok) {
      throw await errorHandler(reportNotesRes);
    }

    const reportRes = await getReportById(token, params.reportId);
    if (!reportRes.ok) {
      throw await errorHandler(reportRes);
    }

    let report;
    let content;
    let placement;
    let reportNotes;

    try {
      content = await contentRes.json();
      placement = await placementRes.json();
      reportNotes = await reportNotesRes.json();
      report = await reportRes.json();
    } catch (error) {
      throw new Error('signingLoader, Failed parsing json');
    }

    return json(
      { placement, content, reportId, reportNotes, report },
      { status: 200 }
    );
  };

export const reportListLoader =
  ({
    token,
    isGlobalAdmin,
  }: {
    token: string | null;
    isGlobalAdmin: boolean;
  }) =>
  async ({ params }: any) => {
    const reportRes = await getReports(token);
    // let tenantRes;

    if (!reportRes.ok) {
      throw await errorHandler(reportRes);
    }

    //TBI in a later stage
    // if (isGlobalAdmin) {
    //   tenantRes = await getTenants(token);

    //   if (!tenantRes.ok) {
    //     throw new Response(tenantRes.statusText, {
    //       status: tenantRes.status,
    //     });
    //   }
    // }

    let reports;
    // let tenants;

    try {
      reports = await reportRes.json();
      // tenants = await tenantRes?.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return json({ reports }, { status: 200 });
  };
