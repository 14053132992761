import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const changeFamilyHomePermission = async (
  token: string | null,
  familyHomeId: string,
  consultantId: string,
  add: boolean
): Promise<Response> =>
  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/FamilyHomes/${familyHomeId}/permissions/${consultantId}`,
    {
      method: add ? 'POST' : 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while changing permission', error);
      return error;
    });

export const familyHomePermissionAction =
  ({ token }: AuthToken) =>
  async ({ params, request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const res = await changeFamilyHomePermission(
      token,
      params.familyHomeId,
      data.consultantId,
      data.addPermission === 'true' ? true : false
    );

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
