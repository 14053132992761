import { PlacementReport } from '../types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const doNothing = () => {};

export const getAltTitleOfReport = (report: PlacementReport): string => {
  const reportNameArray = report.name.split(' ');
  const alternativeTitle = reportNameArray
    .splice(reportNameArray.length - 2)
    .join(' ');

  let capitalizedAltTitle =
    alternativeTitle[0].toUpperCase() + alternativeTitle.slice(1);

  if (report.alternativeName)
    capitalizedAltTitle += ` (${report.alternativeName})`;

  return capitalizedAltTitle;
};
