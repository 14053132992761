import styled from '@emotion/styled';
import { NumberBoxProps } from '.';
import { COLORS } from '../../../../../constants';

export const StyledNumberBox = styled.div<NumberBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 3.75rem;

  border: 3px solid
    ${(props) =>
      props.disabled ? COLORS.hdBackgroundGreyDark : COLORS.evaluationBlack};
  background: ${(props) =>
    props.disabled
      ? COLORS.hdBackgroundGrey
      : props.selected
      ? COLORS.evaluationBlack
      : 'none'};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
