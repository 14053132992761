import {
  useActionData,
  useLoaderData,
  useNavigate,
  useSubmit,
} from 'react-router-dom';
import { isNotEmpty, useForm } from '@mantine/form';
import { CreateProfileFormData, CreatePasswordFormData } from '../../types';
import {
  PageWrapper,
  SecondaryButton,
  TopBar,
  Text,
  Title,
  Spacer,
  FormModal,
  ChangePasswordForm,
  ErrorModal,
} from '../../components';

import { useCallback, useEffect, useState } from 'react';

import { createFormData } from '../../utils';
import { StyledProfileInformation, StyledProfileWrapper } from './styles';

export const Profile = () => {
  const profile = useLoaderData() as CreateProfileFormData;
  const passwordRes = useActionData() as any;

  const submit = useSubmit();
  const navigate = useNavigate();

  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const openPasswordModal = useCallback(() => setPasswordModalIsOpen(true), []);

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const closeErrorModal = useCallback(() => setErrorModalIsOpen(false), []);

  const passwordForm: any = useForm({
    validateInputOnBlur: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      verifyNewPassword: '',
    },

    validate: {
      oldPassword: isNotEmpty('Ange ett lösenord'),
      newPassword: (value) =>
        value.length > 7 ? null : 'Lösenordet måste vara minst 8 tecken',
      verifyNewPassword: (value) =>
        value === passwordForm.values.newPassword
          ? null
          : 'Lösenordet matchar inte det nya lösenordet',
    },
  });

  const closePasswordModal = () => {
    passwordForm.reset();
    setPasswordModalIsOpen(false);
  };

  const changePassword = () => {
    const formHasErrors = passwordForm.validate().hasErrors;

    if (!formHasErrors) {
      const data: CreatePasswordFormData = {
        oldPassword: passwordForm.values.oldPassword,
        newPassword: passwordForm.values.newPassword,
        verifyNewPassword: passwordForm.values.verifyNewPassword,
      };

      const formData = createFormData(data);

      submit(formData, {
        method: 'post',
      });

      closePasswordModal();
    }
  };

  useEffect(() => {
    if (passwordRes?.status === 500) {
      setErrorModalIsOpen(true);
    }
  }, [passwordRes]);

  return (
    <PageWrapper>
      <TopBar
        left={<></>}
        right={
          <>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate('edit');
              }}
            >
              Ändra
            </SecondaryButton>
            <SecondaryButton
              onClick={(e) => {
                openPasswordModal();
              }}
            >
              Byt lösenord
            </SecondaryButton>
          </>
        }
      />
      <StyledProfileWrapper>
        <StyledProfileInformation>
          <Title as="h2" type="secondary" weight="600">
            Min Profil
          </Title>
          <Spacer minSpace="1.25rem" />
          <Title as="h4" type="secondary" weight="600">
            Förnamn
          </Title>
          <Text size="16px" color="#6B778C">
            {profile.firstName}
          </Text>
          <Spacer minSpace="1.25rem" />
          <Title as="h4" type="secondary" weight="600">
            Efternamn
          </Title>
          <Text size="16px" color="#6B778C">
            {profile.lastName}
          </Text>
          <Spacer minSpace="1.25rem" />
          <Title as="h4" type="secondary" weight="600">
            E-postadress
          </Title>
          <Text size="16px" color="#6B778C">
            {profile.email}
          </Text>
          <Spacer minSpace="1.25rem" />
          <Title as="h4" type="secondary" weight="600">
            Telefonnummer
          </Title>
          <Text size="16px" color="#6B778C">
            {profile.phoneNumber}
          </Text>
        </StyledProfileInformation>
      </StyledProfileWrapper>

      <FormModal
        isOpen={passwordModalIsOpen}
        onCancel={closePasswordModal}
        onContinue={changePassword}
        continueText="Spara"
        content={<ChangePasswordForm form={passwordForm} />}
      />

      <ErrorModal
        isOpen={errorModalIsOpen}
        onContinue={closeErrorModal}
        continueText="Stäng"
        title="Fel lösenord"
        content={<>Nuvarande lösenord matchar inte kontots lösenord</>}
      />
    </PageWrapper>
  );
};
