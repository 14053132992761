import styled from '@emotion/styled';

import { TextProps } from '.';
import { COLORS } from '../../constants';

export const StyledText = styled.div<TextProps>`
  font-size: ${(props) => props.size || '33px'};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '20px')};
  font-weight: ${(props) => props.weight || 500};
  color: ${(props) => props.color || COLORS.textSecondary};
`;
