import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { COLORS } from '../../constants';
import { CustomTooltip } from '../CustomTooltip';

interface TableLinkProps {
  id: string;
  hoverText: string;
  children: React.ReactNode;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.textSecondary};
`;

export const TableLink: React.FC<TableLinkProps> = ({
  id,
  hoverText,
  children,
}) => (
  <CustomTooltip hoverText={hoverText} cssBottom="0px" cssLeft="300px">
    <StyledLink to={id}>{children}</StyledLink>
  </CustomTooltip>
);
