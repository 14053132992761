import { RouteObject } from 'react-router-dom';
import {
  placementsLoader,
  placementDetailLoader,
  placementUpdateLoader,
  placementCreateLoader,
} from './loaders';
import {
  ErrorPage,
  Placements,
  PlacementDetail,
  PlacementCreate,
  PlacementUpdate,
} from '../../pages';
import {
  createPlacementAction,
  deletePlacementAction,
  MovePlacementAction,
  PlacementResponsibilityAction,
  updatePlacementAction,
} from './actions';

export const placementRoutes = (
  token: string | null = null,
  isAdmin = false
): RouteObject[] => [
  {
    path: 'placements',
    element: <Placements />,
    errorElement: <ErrorPage />,
    loader: placementsLoader({ token }),
  },
  {
    path: 'placements/:placementId',
    element: <PlacementDetail />,
    errorElement: <ErrorPage />,
    loader: placementDetailLoader({ token, isAdmin }),
  },
  {
    path: 'placements/create',
    element: <PlacementCreate />,
    errorElement: <ErrorPage />,
    loader: placementCreateLoader({ token }),
    action: createPlacementAction({ token }),
  },
  {
    path: 'placements/:placementId/edit',
    element: <PlacementUpdate />,
    errorElement: <ErrorPage />,
    loader: placementUpdateLoader({ token }),
    action: updatePlacementAction({ token }),
  },
  {
    path: 'placements/:placementId/delete',
    errorElement: <ErrorPage />,
    action: deletePlacementAction({ token }),
  },
  {
    path: 'placements/:placementId/responsibility',
    errorElement: <ErrorPage />,
    action: PlacementResponsibilityAction({ token }),
  },
  {
    path: 'placements/:placementId/move',
    errorElement: <ErrorPage />,
    action: MovePlacementAction({ token }),
  },
];
