import { Box, Button } from '@mantine/core';
import { StyledButtonGroup } from './styles';
import { Save } from 'react-feather';
import { COLORS } from '../../../../constants';

interface OverlayButtonProps {
  onClick: () => void;
  text: string;
}

export const OverlaySaveButton = ({ onClick, text }: OverlayButtonProps) => {
  return (
    <StyledButtonGroup>
      <Button
        onClick={onClick}
        sx={{
          borderRadius: '20px 3px 3px 20px',
          maxWidth: '110px',
          backgroundColor: COLORS.hdBackgroundGreenDark,
          ':hover': {
            backgroundColor: COLORS.hdBackgroundGreenDarker,
          },
        }}
      >
        <Box sx={{ marginRight: '10px' }}>
          <Save />
        </Box>
        {text}
      </Button>
    </StyledButtonGroup>
  );
};
