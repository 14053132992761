import { PasswordInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Form } from 'react-router-dom';
import { Title, Spacer, FormWrapper } from '..';

interface ChangePasswordFormProps {
  form: UseFormReturnType<any>;
}

export const ChangePasswordForm = ({ form }: ChangePasswordFormProps) => {
  return (
    <FormWrapper scrollable>
      <Form method="post">
        <Title as="h2" type="secondary" weight="600">
          Byt lösenord
        </Title>
        <Spacer minSpace="1rem" />

        <PasswordInput
          label="Ange nuvarande lösenord"
          placeholder="Ange nuvarande lösenord"
          name="oldPassword"
          {...form.getInputProps('oldPassword')}
        />

        <PasswordInput
          label="Ange nytt lösenord (minst 8 tecken)"
          placeholder="Ange nytt lösenord"
          name="newPassword"
          {...form.getInputProps('newPassword')}
        />

        <PasswordInput
          label="Bekräfta nytt lösenord (minst 8 tecken)"
          placeholder="Bekräfta nytt lösenord"
          name="verifyNewPassword"
          {...form.getInputProps('verifyNewPassword')}
        />
      </Form>
    </FormWrapper>
  );
};
