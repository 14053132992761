import styled from '@emotion/styled';

import { COLORS } from '../../constants';

export const StyledGeneralFormWrapper = styled.div<{
  scrollable: boolean;
  paddingCss?: string;
  widthCss?: string;
  justifyContent?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.hdBackgroundWhite};

  justify-content: ${(props) =>
    props.justifyContent
      ? props.justifyContent
      : props.scrollable
      ? null
      : 'center'};
  margin: ${(props) => (props.scrollable ? 'auto' : null)};

  padding: ${(props) =>
    props.paddingCss ? props.paddingCss : '2.5rem 5rem 0'};

  @media (max-width: 960px) {
    padding: ${(props) =>
      props.paddingCss ? props.paddingCss : '1.5rem 3rem 0'};
  }

  form {
    width: ${(props) => (props.widthCss ? props.widthCss : '19rem')};
  }

  h2 {
    overflow: initial;
  }
`;
