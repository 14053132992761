import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const FinishedReportWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${COLORS.hdBackgroundWhite};
  padding: 2.5rem;
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
`;
export const FinishedReportWrapperDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${COLORS.hdBackgroundWhite};
  padding: 3rem 9rem;
  width: 100%;
`;
