import { useCallback, useEffect, useState } from 'react';

import { Button, Center } from '@mantine/core';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSubmit,
} from 'react-router-dom';
import { ArrowLeft, Check } from 'react-feather';

import {
  Spacer,
  TopBar,
  PageWrapper,
  DetailWrapper,
  Title,
  Text,
  IconLink,
  Table,
  PasswordBox,
  DeleteModal,
  SecondaryButton,
} from '../../components';
import { COLORS } from '../../constants';
import { Consultant, Placement, ReportStatus } from '../../types';
import { ContactDetail, QuickInfo } from './subs';
import { useAuth, useNewPassword } from '../../hooks';
import { WarningModal } from '../../components/Modal';
import { StyledSelect } from '../Sentences/styles';
import { resetConsultantPassword } from '../../api';
import ReactRouterPrompt from 'react-router-prompt';

export const ConsultantDetail = () => {
  const { state: locationState } = useLocation();
  const params = useParams();
  const { token } = useAuth();

  const { consultant, placements, roles, userRole } = useLoaderData() as {
    consultant: Consultant;
    placements: Placement[];
    roles: any;
    userRole: any;
  };

  const roleKeyToTranslated = {
    Admin: 'Administratör',
    Consultant: 'Konsulent',
  };
  const roleMap = new Map<string, string>(
    roles.map((role: any) => {
      return [
        role.roleName as keyof typeof roleKeyToTranslated,
        roleKeyToTranslated[role.roleName as keyof typeof roleKeyToTranslated],
      ];
    })
  );

  const roleList = roles.map((role: any) => {
    return roleMap.get(role.roleName);
  });

  const { state } = useAuth();
  const isAdmin = state.user?.role === 'Admin';
  const isSelf = state.user?.email === consultant.email;

  const navigate = useNavigate();
  const submit = useSubmit();

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [selectedRoleName, setSelectedRole] = useState(userRole.roleName);
  const selectedRoleTranslated: string = roleMap.get(selectedRoleName) ?? '';
  const setSelectedRoleFromTranslated = (selectedRole: string) => {
    for (const [key, value] of roleMap.entries()) {
      if (selectedRole !== value) continue;

      setSelectedRole(key);
    }
  };

  const {
    newPassword,
    fetchNewPassword,
    loading: newPasswordLoading,
    called: newPasswordCalled,
  } = useNewPassword();

  const [passwordWarningIsOpen, setPasswordWarningIsOpen] = useState(false);
  const [deleteConsultantIsOpen, setDeleteConsultantIsOpen] = useState(false);

  const openPasswordModal = useCallback(
    () => setPasswordWarningIsOpen(true),
    []
  );
  const closePasswordModal = useCallback(
    () => setPasswordWarningIsOpen(false),
    []
  );

  const passwordModalFinish = useCallback(() => {
    navigate('/consultants');
  }, [navigate]);

  const openDeleteModal = useCallback(
    () => setDeleteConsultantIsOpen(true),
    []
  );
  const closeDeleteModal = useCallback(
    () => setDeleteConsultantIsOpen(false),
    []
  );

  useEffect(() => {
    //gets the new password property when coming from a new create consultant form
    locationState &&
      locationState.password &&
      fetchNewPassword(undefined, locationState.password);
    //eslint-disable-next-line
  }, [locationState]);

  useEffect(() => {
    // Add a fake history event so that the back button does nothing if pressed once
    if (newPasswordCalled) {
      window.history.pushState(
        'fake-route',
        document.title,
        window.location.href
      );
    }

    const eventHandler = () => {
      if (newPasswordCalled) {
        openPasswordModal();
        window.history.pushState(
          'fake-route',
          document.title,
          window.location.href
        );
      }
    };

    window.addEventListener('popstate', eventHandler);
    return () => {
      window.removeEventListener('popstate', eventHandler);
      // If we left without using the back button, aka by using a button on the page, we need to clear out that fake history event
      if (window.history.state === 'fake-route') {
        window.history.back();
      }
    };
  }, [newPasswordCalled, openPasswordModal]);

  useEffect(() => {
    setSelectedRole(selectedRoleName);

    const role = roles.find((obj: any) => {
      return obj.roleName === selectedRoleName;
    });

    const data = { userId: consultant.id, roleId: role.id };

    submit(data, {
      method: 'post',
      action: `/consultants/${consultant.id}/update-role/${role.id}`,
    });
  }, [selectedRoleName]);

  const rows = placements.map((placement: Placement) => ({
    id: placement.id,
    cells: [
      {
        name: 'placerade',
        label: 'Placerade',
        isSortable: true,
        value: placement.name,
        content: placement.name,
      },
      {
        name: 'familjehem',
        label: 'Familjehem',
        isSortable: true,
        value: `${placement?.familyHome?.name}, ${placement?.familyHome?.city}`,
        content: placement?.familyHome
          ? `${placement?.familyHome?.name}, ${placement?.familyHome?.city}`
          : '',
      },
      {
        name: 'rapport påbörjad',
        label: 'Rapportering påbörjad',
        isSortable: true,
        value: consultant.phoneNumber,
        content: (
          <Center>
            {placement.reports?.find(
              (report) =>
                report.status === ReportStatus.InProgress ||
                report.status === ReportStatus.Generated
            ) && (
              <Check
                size={24}
                color={COLORS.hdAccentBlue}
                strokeWidth={'1px'}
              />
            )}
          </Center>
        ),
      },
      {
        name: 'rapport klar',
        label: 'Rapportering klar',
        isSortable: true,
        value: '1',
        content: (
          <Center>
            {placement.reports?.find(
              (report) =>
                report.status !== ReportStatus.Signed &&
                (report.status as unknown as number) !== 4 // Deprecated ReportStatus.Invoiced = 4
            ) && (
              <Check
                size={24}
                color={COLORS.hdAccentGreenDarker}
                strokeWidth={'1px'}
              />
            )}
          </Center>
        ),
      },
    ],
  }));

  const [showReactRouterPrompt, setShowReactRouterPrompt] = useState(
    newPassword.length > 0
  );

  useEffect(() => {
    if (newPassword && newPassword.length > 0) {
      setShowReactRouterPrompt(true);
    } else {
      setShowReactRouterPrompt(false);
    }
  }, [newPassword]);

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to="/consultants"
              onClick={(e) => {
                if (newPasswordCalled) {
                  e.preventDefault();
                  openPasswordModal();
                }
              }}
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Tillbaka
              </Text>
            </IconLink>

            <Title as="h1" type="secondary" weight="500">
              {consultant.firstName} {consultant.lastName}
            </Title>
          </>
        }
        right={
          <>
            {isAdmin && !isSelf && (
              <StyledSelect
                label="Roll"
                value={selectedRoleTranslated}
                onChange={setSelectedRoleFromTranslated}
                data={roleList}
              />
            )}
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate('edit');
              }}
            >
              Ändra
            </SecondaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                setChangePasswordModal(true);
              }}
            >
              Byt lösenord
            </SecondaryButton>
            <Button
              color="red"
              onClick={openDeleteModal}
              sx={{ fontSize: 'initial' }}
            >
              Ta bort konsulent
            </Button>
          </>
        }
      />
      <DetailWrapper
        left={
          <>
            <ContactDetail
              email={consultant.email}
              phone={consultant.phoneNumber}
            />
            <Spacer minSpace="2rem" />

            {newPasswordCalled && (
              <PasswordBox
                loading={newPasswordLoading}
                password={newPassword}
              />
            )}

            <QuickInfo stats={consultant.statistics} />
          </>
        }
        right={<Table rows={rows} />}
      />
      <DeleteModal
        isOpen={deleteConsultantIsOpen}
        onCancel={closeDeleteModal}
        title={'Är du säker på att du vill ta bort konsulentkonto?'}
        content={
          <>
            <p>
              När du godkänner kommer konsulentkontot att raderas från systemet.
            </p>
            <p>
              Placerade och familjehem kopplade till konsulenten raderas inte.
            </p>
          </>
        }
        continueText={'Ja, radera konsulentkonto '}
      />
      {/* Change password warning modal */}
      <WarningModal
        isOpen={changePasswordModal}
        onCancel={() => setChangePasswordModal(false)}
        onContinue={() => {
          fetchNewPassword(() =>
            resetConsultantPassword(params.consultantId ?? '', token)
          );
          setChangePasswordModal(false);
        }}
        title="Byt lösenord"
        continueText="Ja, jag vill byta lösenord"
        content="Detta kommer att byta kundens lösenord. Är du säker på att du vill genomföra detta?"
      />
      <WarningModal
        isOpen={passwordWarningIsOpen}
        onCancel={closePasswordModal}
        onContinue={passwordModalFinish}
        title="Har du kopierat lösenordet?"
        continueText="Jag har kopierat och skickat, gå tillbaka"
        content={`Är du säker på att du kopierat lösenordet och skickat det till aktuell konsulent?
        Lösenordet kommer inte visas efter att du gått tillbaka till konsulentvyn`}
      />
      <ReactRouterPrompt when={showReactRouterPrompt}>
        {({ onConfirm }) => (
          <WarningModal
            isOpen={showReactRouterPrompt}
            onCancel={() => {
              setShowReactRouterPrompt(false);
              setTimeout(() => {
                setShowReactRouterPrompt(newPassword.length > 0);
              }, 100);
            }}
            onContinue={onConfirm}
            title="Har du kopierat lösenordet?"
            continueText="Jag har kopierat och skickat, gå vidare"
            content="Är du säker på att du kopierat lösenordet och skickat det till aktuell konsulent?
            Lösenordet kommer inte visas i fortsättningen på aktuell konsulentvy"
          />
        )}
      </ReactRouterPrompt>
    </PageWrapper>
  );
};
