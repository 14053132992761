import { Text } from '../';
import { COLORS } from '../../constants';
import { Color } from '../../types';
import { StyledInfoBox, StyledNumber } from './styles';

interface InfoBoxProps {
  infoText: string;
  value: string | number;
  textColor?: Color | string;
  backgroundColor?: Color | string;
}

export const InfoBox = ({
  infoText,
  value,
  textColor,
  backgroundColor,
}: InfoBoxProps) => {
  return (
    <StyledInfoBox backgroundColor={backgroundColor}>
      <Text
        color={textColor || COLORS.hdAccentBlueLight}
        size="23"
        weight="700"
      >
        {infoText}
      </Text>
      <StyledNumber>{value}</StyledNumber>
    </StyledInfoBox>
  );
};
