import { Button } from '@mantine/core';
import { Edit } from 'react-feather';
import { COLORS } from '../../../../../../constants';
import { StyledButtonGroup } from './styles';

interface OverlayButtonProps {
  onClick: () => void;
  overlayOpened: boolean;
  drawerWidth?: string;
}

export const OverlayNotepadButton = ({
  onClick,
  overlayOpened,
  drawerWidth,
}: OverlayButtonProps) => {
  return (
    <StyledButtonGroup overlayOpened={overlayOpened} drawerWidth={drawerWidth}>
      <Button
        onClick={onClick}
        sx={{
          borderRadius: '10px 0px 0px 0px',
          maxWidth: '62px',
          backgroundColor: COLORS.brandPink,
          ':hover': {
            backgroundColor: COLORS.brandPinkDarker,
          },
          height: '3rem',
        }}
      >
        <Edit />
      </Button>
    </StyledButtonGroup>
  );
};
