import { createContext, useReducer } from 'react';
import {
  AuthState,
  AuthAction,
  AuthActionType,
  AuthContextProps,
} from '../types';
import { decodeToken, getUserFromToken } from '../utils/authHelpers';

const AuthReducer: React.Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return {
        token: action.payload,
        user: getUserFromToken(action.payload),
      };
    case AuthActionType.LOGOUT:
      return { token: null };
    default:
      return state;
  }
};

export const AuthContext = createContext<AuthContextProps>({
  state: { token: '', user: null },
  dispatch: () => null,
});

export const AuthContextProvider = ({ children }: any) => {
  //this token check is so that you will still be redirected to login when adding your own invalid token.
  //A more secure check is to be added with the dashboard request which will return 401 if not valid.
  const tryDecode = () => decodeToken(localStorage.getItem('token') ?? '');

  const [state, dispatch] = useReducer(AuthReducer, {
    token: tryDecode() ? localStorage.getItem('token') : '',
    user: getUserFromToken(localStorage.getItem('token') ?? ''),
  });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
