export enum AuthActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface AuthState {
  token: string | null;
  user?: User | null;
}

export interface AuthAction {
  type: AuthActionType;
  payload?: any;
}

export interface AuthContextProps {
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
}

export interface AuthHook {
  login: (token: string) => void;
  logout: () => void;
  token: string | null;
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
}

export interface DecodedToken {
  email: string;
  TenantId: string;
  TenantName: string;
  role: string;
  given_name: string;
  family_name: string;
  name: string;
  nbf: number;
  exp: number;
  iat: number;
}

export enum UserRole {
  GlobalAdmin = 'GlobalAdmin',
  Admin = 'Admin',
  Consultant = 'Consultant',
}

export interface User {
  tenantId?: string;
  tenantName?: string;
  name?: string;
  email?: string;
  role?: UserRole;
  exp?: number;
}
