import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../../../../../../constants';

export const StyledNav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  padding-top: 3rem;

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

export const StyledListItem = styled.div`
  margin-bottom: 1rem;
`;

export const StyledNavLink = styled(NavLink)`
  color: ${COLORS.hdAccentGreenLight};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 20px;
  text-decoration: none;
  margin-bottom: 1.5rem;

  &:hover:not(.active) {
    color: ${COLORS.hdAccentGreenDark};
    cursor: pointer;
  }
  &[class*='active'] {
    color: ${COLORS.textPrimary};
  }
`;
