import { NumberInput } from '@mantine/core';
import { GreenRed } from '../GreenRed';
import { Spacer } from '../../Spacer';

interface NumberGreenRedProps {
  numberValue?: number;
  optionValue?: string;
  numberInputPlaceHolder?: string;
  numberLabel?: string;
  numberInputOnChange: (value: number) => void;
  greenRedOnChange: (value: string) => void;
  disabled?: boolean;
}

export const NumberGreenRed = ({
  numberValue,
  optionValue,
  numberInputPlaceHolder,
  numberLabel,
  numberInputOnChange,
  greenRedOnChange,
  disabled,
}: NumberGreenRedProps) => {
  return (
    <>
      <NumberInput
        defaultValue={0}
        placeholder={numberInputPlaceHolder}
        label={numberLabel}
        min={0}
        max={1000}
        onChange={numberInputOnChange}
        value={numberValue}
        disabled={disabled}
      />
      <Spacer minSpace="1rem" />
      <GreenRed onChange={greenRedOnChange} value={optionValue} />
    </>
  );
};
