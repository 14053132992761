import {
  saveAnswer,
  registerContact,
  generateReport,
  resetReport,
  deleteReport,
  signReport,
  saveReportContent,
  getPlacementById,
  createNewReport,
} from '../../api';
import { AuthToken, RedirectAction } from '../../types';
import { redirect } from 'react-router-dom';
import { errorHandler } from '../../utils';
import dayjs from 'dayjs';

export const createNewReportAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const { yearMonth, redirectAction } = Object.fromEntries(formData) as any;

    const placementRes = await getPlacementById(params.placementId, token);

    if (!placementRes.ok) {
      throw await errorHandler(placementRes);
    }

    let placement;

    try {
      placement = await placementRes.json();
    } catch (error) {
      throw new Error('reportsLoader, Failed parsing json 1');
    }

    const requestYearMonth = yearMonth ? yearMonth : dayjs().format('YYYYMM');

    if (
      placement.reports.find(
        (report: any) => report.yearMonth === requestYearMonth
      )
    ) {
      return redirect(`/placements/${placement.id}`);
    }

    const reportRes = await createNewReport(token, {
      placementId: placement?.id,
      yearMonth: requestYearMonth,
    });

    if (reportRes && !reportRes.ok) {
      throw await errorHandler(reportRes);
    }

    let report;

    try {
      report = await reportRes.json();
    } catch (error) {
      throw new Error('reportsLoader, Failed parsing json 3');
    }

    switch (redirectAction) {
      case RedirectAction.Report:
        return redirect(`/placements/${placement.id}/report/${report.id}/form`);
      case RedirectAction.Register:
        return redirect(
          `/placements/${placement.id}/report/${report.id}/register`
        );
      default:
        return redirect(`/placements/${placement.id}`);
    }
  };

export const registerContactAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const { reportId, ...rest } = Object.fromEntries(formData) as any;

    const res = await registerContact(token, reportId, rest);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(
      `/placements/${params.placementId}/report/${reportId}/register`
    );
  };

export const generateReportAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const { reportId } = Object.fromEntries(formData) as any;
    const res = await generateReport(token, reportId);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(
      `/placements/${params.placementId}/report/${reportId}/signing`
    );
  };

export const deleteReportAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const res = await deleteReport(token, params.reportId);
    const formData = await request.formData();
    const { redirectPath } = Object.fromEntries(formData) as any;

    if (!res.ok) {
      throw await errorHandler(res);
    }

    if (redirectPath) return redirect(redirectPath);

    return redirect(`/reports`);
  };

export const signReportAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;
    //parse nested array
    const content = JSON.parse(data.content);
    const alternativeName = data.alternativeName;

    const res = await signReport(token, params.reportId, {
      alternativeName,
      content,
    });

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/placements/${params.placementId}`);
  };

export const saveReportContentAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;
    //parse nested array

    const content = JSON.parse(data.content);
    const alternativeName = data.alternativeName;

    const res = await saveReportContent(token, params.reportId, {
      alternativeName,
      content,
    });

    if (!res.ok) {
      throw await errorHandler(res);
    }
    return res;
  };
