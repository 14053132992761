import React from 'react';
import { ModalTitle } from '@atlaskit/modal-dialog';

import { BaseModal } from '.';
import { CancelButton, PrimaryButton } from '..';

interface ModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
  onClose?: () => void;
  content: string | React.ReactNode;
  title?: string;
  continueText?: string;
}

export const Modal = ({
  isOpen,
  onCancel,
  onContinue,
  onClose,
  content,
  title,
  continueText,
}: ModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={<ModalTitle>{title ?? 'Information'}</ModalTitle>}
      content={
        <>
          <p>{content}</p>
        </>
      }
      buttons={
        <>
          <CancelButton onClick={onCancel} />
          <PrimaryButton onClick={onContinue}>
            {continueText ?? 'Ja, fortsätt ändå'}
          </PrimaryButton>
        </>
      }
    />
  );
};
