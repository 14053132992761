import { redirect } from 'react-router-dom';
import {
  changePlacementResponsibility,
  createPlacement,
  createPlacementInFamilyHome,
  deletePlacement,
  updatePlacement,
  addExistingPlacementToFamilyHome,
} from '../../api';
import { AuthToken, FamilyHome, Placement } from '../../types';
import { errorHandler } from '../../utils';

export const createPlacementAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const { familyHomeId, ...data } = Object.fromEntries(formData) as any;

    const ownReasons = JSON.parse(data.ownReasons);
    const experiencedReasons = JSON.parse(data.experiencedReasons);
    const placementData: Placement = {
      ...data,
      ownReasons,
      experiencedReasons,
    };
    const isFamilyHomeAddition =
      familyHomeId !== null &&
      familyHomeId !== undefined &&
      familyHomeId !== '' &&
      familyHomeId !== 'undefined'; //formData makes undefined value a string

    const res = isFamilyHomeAddition
      ? await createPlacementInFamilyHome(familyHomeId, placementData, token)
      : await createPlacement(placementData, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    let recievedResponse: Placement | FamilyHome; //can be both family home and placement, depending on if a familyHomeId was passed.

    try {
      recievedResponse = await res.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return isFamilyHomeAddition
      ? redirect(`/placements/${recievedResponse.id}`)
      : redirect(`/placements/${recievedResponse.id}`);
  };

export const updatePlacementAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const ownReasons = JSON.parse(data.ownReasons);
    const experiencedReasons = JSON.parse(data.experiencedReasons);
    const placementData: Placement = {
      ...data,
      ownReasons,
      experiencedReasons,
    };

    const res = await updatePlacement(params.placementId, placementData, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    let placement: Placement;

    try {
      placement = await res.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return redirect(`/placements/${placement.id}`);
  };

export const deletePlacementAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await deletePlacement(params.placementId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/placements`);
  };

export const PlacementResponsibilityAction =
  ({ token }: AuthToken) =>
  async ({ params, request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const res = await changePlacementResponsibility(
      token,
      params.placementId,
      data.consultantId,
      data.isResponsible === 'true' ? true : false
    );

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/placements/${params.placementId}`);
  };

export const MovePlacementAction =
  ({ token }: AuthToken) =>
  async ({ params, request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const res = await addExistingPlacementToFamilyHome(
      token,
      data.familyHomeId,
      {
        placementId: params.placementId,
        remove: data.remove === 'true' ? true : false,
      }
    );

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/placements/${params.placementId}`);
  };
