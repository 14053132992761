import { Button, ButtonProps } from '@mantine/core';
import { COLORS } from '../../constants';

interface CancelButtonProps extends ButtonProps {
  onClick: (e: any) => void;
}

export const CancelButton = ({
  onClick,
  children,
  ...rest
}: CancelButtonProps) => {
  return (
    <Button
      variant="white"
      color="dark"
      sx={{
        fontSize: 'initial',
        '&:hover': { backgroundColor: COLORS.hdBackgroundGrey },
      }}
      onClick={onClick}
      {...rest}
    >
      {children ?? 'Avbryt'}
    </Button>
  );
};
