import { ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { StyledCheckButton } from './styles';

export interface CheckButtonProps extends ButtonProps {
  selected?: boolean;
  value?: string;
  width?: 'full' | 'small';
  background?: 'secondary';
  textalign?: 'left';
  onClick?: (value: string, index: number) => void;
}

export const CheckButton = createPolymorphicComponent<
  'button',
  CheckButtonProps
>(StyledCheckButton);
