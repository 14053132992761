import { GreenRed } from '../GreenRed';
import { OneToFive } from '../OneToFive';

interface EstimateGreenRedProps {
  estimateValue?: number;
  optionValue?: string;
  onChangeNumber: (value: number) => void;
  onChangeGreenRed: (value: string) => void;
}

export const EstimateGreenRed = ({
  estimateValue,
  optionValue,
  onChangeNumber,
  onChangeGreenRed,
}: EstimateGreenRedProps) => {
  return (
    <>
      <OneToFive value={estimateValue} onChange={onChangeNumber} />
      <GreenRed value={optionValue} onChange={onChangeGreenRed} />
    </>
  );
};
