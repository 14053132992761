import { Checkbox, TextInput } from '@mantine/core';
import { StyledFormGroup } from '../../styles';

export const BasicInfoFields = ({ form }: any) => (
  <StyledFormGroup mt="xs">
    <TextInput
      label="Adress"
      placeholder="Adress"
      name="adress"
      {...form.getInputProps('adress')}
    />
    <TextInput
      label="Postnummer"
      placeholder="Postnummer"
      name="zipCode"
      {...form.getInputProps('zipCode')}
    />
    <TextInput
      label="Ort"
      placeholder="Ort"
      name="city"
      {...form.getInputProps('city')}
    />
    <Checkbox
      mt="sm"
      label={'Placerad från en annan aktör'}
      name="multiplePlacement"
      {...form.getInputProps('multiplePlacement')}
      checked={form.getInputProps(`multiplePlacement`).value}
      style={{ justifySelf: 'center' }}
    />
  </StyledFormGroup>
);
