import { Text } from '..';
import { COLORS } from '../../constants';
import { StyledDetailBlock } from './styles';

export interface DetailProps {
  header: string;
  content: any;
}

export const DetailBlock = ({ header, content }: DetailProps) => {
  return (
    <StyledDetailBlock>
      <Text weight="600px" size="12px" color={COLORS.textDisabled}>
        {header}
      </Text>
      <Text weight="500px" size="19px" color={COLORS.textDisabled}>
        {content}
      </Text>
    </StyledDetailBlock>
  );
};
