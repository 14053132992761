import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';

interface PrivateRoutesProps {
  children: React.ReactNode;
}

export const PrivateRoutes = ({ children }: PrivateRoutesProps) => {
  const { state, logout } = useAuth();

  useEffect(() => {
    if (!state?.token || !state?.user?.exp) {
      logout();
    }

    if (state?.user?.exp && state?.user?.exp < new Date().valueOf() / 1000) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token, state.user?.exp]);

  return state.token ? <>{children}</> : <Navigate to="/login" />;
};
