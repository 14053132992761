import styled from '@emotion/styled';

import { COLORS } from '../../constants';

interface StyledHeaderProps {
  type: 'primary' | 'faded' | 'secondary';
  weight?: string;
}

export const StyledH1 = styled.h1<StyledHeaderProps>`
  margin: 0;
  font-size: 33px;
  line-height: 35px;
  font-weight: ${(props) => props.weight || 700};
  color: ${(props) =>
    props.type === 'primary' ? COLORS.textPrimary : COLORS.textSecondary};
`;

export const StyledH2 = styled.h2<StyledHeaderProps>`
  margin: 0;
  font-size: 23px;
  line-height: 26px;
  font-weight: ${(props) => props.weight || 500};
  color: ${(props) =>
    props.type === 'primary' ? COLORS.textPrimary : COLORS.textSecondary};
`;

export const StyledH3 = styled.h3<StyledHeaderProps>`
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: ${(props) => props.weight || 500};
  color: ${(props) =>
    props.type === 'primary' ? COLORS.textPrimary : COLORS.textSecondary};
`;

export const StyledH4 = styled.h4<StyledHeaderProps>`
  margin: 0;
  font-size: 13px;
  line-height: 20px;
  font-weight: ${(props) => props.weight || 500};
  color: ${(props) =>
    props.type === 'primary'
      ? COLORS.textPrimary
      : props.type === 'faded'
      ? COLORS.textDisabled
      : COLORS.textSecondary};
`;
