import { UseFormReturnType } from '@mantine/form';
import { CheckButton, Spacer } from '../../../../components';
import {
  StyledPreviewSection,
  StyledTagsSection,
  StyledTextarea,
  StyledTransparentSection,
} from '../../styles';
import { Button, Loader, Radio, Switch } from '@mantine/core';
import { COLORS } from '../../../../constants';
import { Zap } from 'react-feather';
import { useAiContext } from '../../../../contexts/AiContext';
import { useState } from 'react';

interface SentenceSectionProps {
  tagsList: any[];
  handleTagsForm: any;
  previewSentence: string;
  form: UseFormReturnType<any>;
}

export const SentenceSection = ({
  tagsList,
  handleTagsForm,
  previewSentence,
  form,
}: SentenceSectionProps) => {
  const { generateSentenceFromTemplate, isLoading } = useAiContext();
  const [templateText, setTemplateText] = useState('');
  const [useAi, setUseAi] = useState(true);

  const handleTagsTemplate = (event: any) => {
    const tagCode = event.currentTarget.value;
    // form.setFieldValue('text', form.values.text + tagCode);
    setTemplateText(templateText + tagCode);
  };

  return (
    <>
      <StyledTransparentSection>
        Meningsinmatning
        <StyledTagsSection>
          {tagsList.map((item, i) => {
            return (
              <CheckButton
                key={i}
                value={item.code}
                background={'secondary'}
                onClick={useAi ? handleTagsTemplate : handleTagsForm}
              >
                {item.name}
              </CheckButton>
            );
          })}
        </StyledTagsSection>
        <Switch
          checked={useAi}
          label="Använd AI"
          onChange={(event) => {
            setUseAi(event.currentTarget.checked);
          }}
          style={{ marginBottom: '0.5rem' }}
        />
        {useAi && (
          <>
            <StyledTextarea
              placeholder="Skriv exempel mening"
              minRows={4}
              name="template-text"
              value={templateText}
              onChange={(e) => {
                setTemplateText(e.target.value);
              }}
            />

            <Button
              sx={{
                width: '100%',
                margin: '1rem 0',
                backgroundColor: COLORS.brandPink,
                '&:hover': {
                  backgroundColor: COLORS.brandPinkDarker,
                },
              }}
              onClick={async () => {
                const text = await generateSentenceFromTemplate(templateText);
                form.setFieldValue('text', text ?? '');
              }}
            >
              {isLoading ? (
                <Loader
                  variant="oval"
                  color={COLORS.textPrimary}
                  size="sm"
                  style={{ marginRight: '0.5rem' }}
                />
              ) : (
                <Zap style={{ marginRight: '0.5rem' }} />
              )}
              Generera mening med AI
            </Button>
          </>
        )}
        <StyledTextarea
          placeholder="Mening som ska sparas"
          minRows={4}
          name="text"
          {...form.getInputProps('text')}
        />
      </StyledTransparentSection>
      <StyledTransparentSection>
        Förhandsgranskning
        <StyledPreviewSection>
          {form.values.text.length > 0 ? previewSentence : ''}
        </StyledPreviewSection>
      </StyledTransparentSection>
    </>
  );
};
