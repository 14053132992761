import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const getFamilyHomes = async (token: string | null): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/FamilyHomes`, {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
    },
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while fetching family homes', error);
      return error;
    });

export const familyHomesLoader =
  ({ token }: AuthToken) =>
  async () => {
    const res = await getFamilyHomes(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
