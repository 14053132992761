import { useLoaderData, useSubmit } from 'react-router-dom';

import { PageWrapper, PlacementForm } from '../../components';
import { Placement } from '../../types';
import { createFormData } from '../../utils';

export const PlacementUpdate = () => {
  const data = useLoaderData() as Placement;
  const submit = useSubmit();

  const onSubmit = (data: any) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'post',
    });
  };

  return (
    <PageWrapper>
      <PlacementForm onSubmit={onSubmit} placement={data} />
    </PageWrapper>
  );
};
