import { CheckButton, Title } from '../../../../components';
import { StyledAgeSection } from '../../styles';

interface AgeSectionProps {
  age: any[];
  handleAge: any;
}

export const AgeSection = ({ age, handleAge }: AgeSectionProps) => {
  return (
    <StyledAgeSection>
      <Title as="h4" type="secondary" weight="600">
        Ålder
      </Title>

      <div style={{ marginTop: '2.25rem' }}>
        {age.map((item) => {
          return (
            <CheckButton
              key={item.name}
              selected={item.selected}
              value={item.name}
              width={'full'}
              textalign={'left'}
              onClick={handleAge}
            >
              {item.name}
            </CheckButton>
          );
        })}
      </div>
    </StyledAgeSection>
  );
};
