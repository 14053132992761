import styled from '@emotion/styled';
import { COLORS } from '../../constants';

interface TableMailLinkProps {
  email: string;
  children: React.ReactNode;
}

const StyledLink = styled.a`
  text-decoration: none;
  color: ${COLORS.textSecondary};
`;

export const TableMailLink = ({ email, children }: TableMailLinkProps) => (
  <StyledLink href={`mailto:${email}`}>
    <div>{children}</div>
  </StyledLink>
);
