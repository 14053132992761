import styled from '@emotion/styled';
import { Select } from '@mantine/core';
import { COLORS } from '../../constants';
export const StyledSelect = styled(Select)`
  margin-left: 0.5rem;
  input {
    background-color: ${COLORS.hdBackgroundGreyDark};
    color: ${COLORS.textSecondary};
    border: none;

    &:hover {
      cursor: pointer;
    }
    &::placeholder {
      color: ${COLORS.textSecondary};
    }
  }
`;
