import { json } from 'react-router-dom';
import { errorHandler } from '../../utils';
import { getFamilyHomePermissions } from './getFamilyHomePermissions';

export const getFamilyHomeById = async (
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/FamilyHomes/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
    },
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while fetching family home with id: ${id}`,
        error
      );
      return error;
    });

export const familyHomeDetailLoader =
  ({ token, isAdmin }: { token: string | null; isAdmin: boolean }) =>
  async ({ params }: any) => {
    const familyHomeRes = await getFamilyHomeById(params.familyHomeId, token);
    let consultantsRes;

    if (isAdmin) {
      consultantsRes = await getFamilyHomePermissions(
        params.familyHomeId,
        token
      );
    }

    if (!familyHomeRes.ok) {
      throw await errorHandler(familyHomeRes);
    }

    if (isAdmin && consultantsRes && !consultantsRes.ok) {
      throw await errorHandler(consultantsRes);
    }

    const familyHome = await familyHomeRes.json();
    const consultants = await consultantsRes?.json();

    return json({ familyHome, consultants }, { status: 200 });
  };
