import React from 'react';

import { BaseModal } from '.';
import { CancelButton, PrimaryButton } from '..';
import { COLORS } from '../../constants';
import { useIsMobile } from '../../hooks/useIsMobile';

interface FormModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
  onClose?: () => void;
  content: string | React.ReactNode;
  continueText?: string;
}

export const FormModal = ({
  isOpen,
  onCancel,
  onContinue,
  onClose,
  content,
  continueText,
}: FormModalProps) => {
  const isMobile = useIsMobile();
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      content={
        <div style={{ padding: isMobile ? '1rem 0rem' : '1rem 4rem 0' }}>
          {content}
        </div>
      }
      buttons={
        <div style={{ padding: '0 4rem 2rem 4rem' }}>
          <CancelButton onClick={onCancel} />
          <PrimaryButton
            onClick={onContinue}
            background={COLORS.hdAccentGreen}
            hover={COLORS.hdAccentGreenDark}
          >
            {continueText ?? 'Spara'}
          </PrimaryButton>
        </div>
      }
    />
  );
};
