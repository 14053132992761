import { width } from '@atlaskit/modal-dialog/dist/types/internal/constants';
import styled from '@emotion/styled';

export const StyledEvaluationWrapper = styled.div<{
  isDesktop?: boolean;
  isSummarySection?: boolean;
  screenWidth?: number;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${(props) => (props.isDesktop ? 'center' : 'space-around')};
  align-items: stretch;
  width: 100%;
  height: 100%;
  gap: 6px;
`;
