export const changePlacementResponsibility = async (
  token: string | null,
  placementId: string,
  consultantId: string,
  add: boolean
): Promise<Response> =>
  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/Placements/${placementId}/responsibilities/${consultantId}`,
    {
      method: add ? 'POST' : 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        'something went wrong while changing responsibility',
        error
      );
      return error;
    });
