export const statusList = [
  { label: 'Välfungerande', value: 'Green' },
  { label: 'Utvecklingsområde', value: 'Red' },
  { label: 'Inte relevant', value: 'NotApplicable' },
];

export const monthsList = [
  { name: 'Januari', selected: false },
  { name: 'Februari', selected: false },
  { name: 'Mars', selected: false },
  { name: 'April', selected: false },
  { name: 'Maj', selected: false },
  { name: 'Juni', selected: false },
  { name: 'Juli', selected: false },
  { name: 'Augusti', selected: false },
  { name: 'September', selected: false },
  { name: 'Oktober', selected: false },
  { name: 'November', selected: false },
  { name: 'December', selected: false },
];

export const ageList = [
  { name: '0 - F', minAge: 0, maxAge: 6, selected: true },
  { name: 'Lågstadie', minAge: 7, maxAge: 9, selected: true },
  { name: 'Mellanstadie', minAge: 10, maxAge: 12, selected: true },
  { name: 'Högstadie', minAge: 13, maxAge: 15, selected: true },
  { name: 'Gymnasium', minAge: 16, maxAge: 20, selected: true },
  { name: 'Vuxen', minAge: 21, maxAge: 100, selected: true },
];

export const sexList = [
  { name: 'Alla', value: 'All', selected: true },
  { name: 'Han', value: 'He', selected: false },
  { name: 'Hon', value: 'She', selected: false },
  { name: 'Hen', value: 'They', selected: false },
];

export const tagsList = [
  { name: 'Familjehemsförälder', code: '%familyhomeparent%' },
  { name: 'Husdjur', code: '%familyhomepet%' },
  { name: 'Placerad', code: '%placedchild%' },
  { name: 'Konsulent', code: '%consultantname%' },
  { name: 'Konsulent förnamn', code: '%consultantfirstname%' },
  { name: 'Konsulent efternamn', code: '%consultantlastname%' },
  { name: 'Rapport månad', code: '%reportmonth%' },
  { name: 'Antal', code: '%times%' },
  { name: 'Tillfälle', code: '%occasion%' },
  { name: 'Självskattning', code: '%estimated%' },
  { name: 'Datum', code: '%date%' },
  { name: 'Listvärde', code: '%dropdownvalue%' },
];
