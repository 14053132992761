import { Badge } from '@mantine/core';
import { TableRow, Consultant } from '../../types';

export const formatConsultantsRows = (
  data: Consultant[],
  onRowClick: (id: string) => void
): TableRow[] =>
  data.map((consultant) => ({
    id: consultant.id,
    onClick: () => onRowClick(consultant.id),
    cells: [
      {
        name: 'name',
        label: 'Namn',
        isSortable: true,
        value: consultant.firstName,
        content: `${consultant.firstName} ${consultant.lastName}`,
      },
      {
        name: 'email',
        label: 'E-postadress',
        isSortable: true,
        value: consultant.email,
        content: consultant.email,
      },
      {
        name: 'phone',
        label: 'Telefonnummer',
        isSortable: true,
        value: consultant.phoneNumber,
        content: consultant.phoneNumber,
      },
      {
        name: 'statistics',
        label: 'Antal rapporter kvar',
        isSortable: true,
        value: consultant.statistics.numberOfUnfinishedReports,
        content: (
          <Badge color="gray">
            {consultant.statistics.numberOfUnfinishedReports}
          </Badge>
        ),
      },
    ],
  }));
