import { redirect } from 'react-router-dom';
import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const deleteFamilyHome = async (
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/FamilyHomes/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
    },
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while deleting family home with id: ${id}`,
        error
      );
      return error;
    });

export const deleteFamilyHomeAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await deleteFamilyHome(params.familyHomeId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/family-homes`);
  };
