import { useCallback, useState, useMemo } from 'react';
import { Copy, Check } from 'react-feather';
import { Loader } from '@mantine/core';

import { Spacer, Text } from '../';
import { COLORS } from '../../constants';
import { StyledPasswordBox, StyledPasswordTextColumn } from './styles';

interface PasswordBoxProps {
  password?: string;
  loading: boolean;
  onCopyClick?: () => void;
}

export const PasswordBox = ({
  password,
  loading,
  onCopyClick,
}: PasswordBoxProps) => {
  const [copied, setCopied] = useState(false);
  const copyPW = useCallback(() => {
    password &&
      navigator.clipboard.writeText(password).then(
        () => {
          setCopied(true);
        },
        () => {
          setCopied(false);
        }
      );
    onCopyClick && onCopyClick();
  }, [password, onCopyClick]);

  const CopyButton = useMemo(
    () => <Copy size={20} color={COLORS.hdAccentRed} onClick={copyPW} />,
    [copyPW]
  );
  const CheckButton = useMemo(
    () => <Check size={20} color={COLORS.hdAccentRed} onClick={copyPW} />,
    [copyPW]
  );

  const CopyOrCheckButton = () => {
    return !copied ? CopyButton : CheckButton;
  };

  return (
    <StyledPasswordBox>
      <StyledPasswordTextColumn>
        <Text color={COLORS.hdAccentRed} size="12px" weight="600">
          Nytt lösenord
        </Text>
        {loading ? (
          <>
            <Spacer minSpace="0.45rem" />
            <Loader color={COLORS.hdAccentRed} variant="dots" />
          </>
        ) : (
          <Text color={COLORS.hdAccentRed} size="19px" weight="500">
            {password}
          </Text>
        )}
      </StyledPasswordTextColumn>

      <Spacer />

      {!loading && <CopyOrCheckButton />}
    </StyledPasswordBox>
  );
};
