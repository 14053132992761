import styled from '@emotion/styled';
import { Drawer } from '@mantine/core';

export const NotepadDrawer = styled(Drawer)`
  & .mantine-Drawer-header {
    margin-bottom: 0;
    background-color: #ff0062;
    padding: 0.5rem 0.5rem 0 0;
    z-index: 1000;
  }

  & .mantine-Drawer-body {
    height: 100%;
    background-color: #ff0062;
    z-index: 1000;
  }
`;
