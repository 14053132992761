import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';

interface WarningButtonProps extends ButtonProps {
  onClick: (e: any) => void;
}

const StyledWB = styled(Button)`
  background-color: ${COLORS.hdAccentOrange};
  color: ${COLORS.textSecondary};
  font-size: initial;
`;

const StyledWarningButton = createPolymorphicComponent<
  'button',
  WarningButtonProps
>(StyledWB);

export const WarningButton = ({
  onClick,
  children,
  ...props
}: WarningButtonProps) => (
  <StyledWarningButton color="yellow" onClick={onClick} {...props}>
    {children}
  </StyledWarningButton>
);
