import { useCallback, useState, Fragment } from 'react';
import { Button, Menu, Checkbox, Grid, Col } from '@mantine/core';
import { Form, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { ArrowLeft, ChevronDown } from 'react-feather';

import {
  Spacer,
  TopBar,
  PageWrapper,
  DetailWrapper,
  Title,
  Text,
  IconLink,
  Table,
  DeleteModal,
  SecondaryButton,
  DetailBlock,
  SortOrder,
  MobileBackgroundWrapper,
  MobileTopBarWrapper,
  MobileContentWrapper,
  StyledCard,
} from '../../components';
import { COLORS } from '../../constants';
import { FamilyHome, UserRole } from '../../types';
import {
  formatFHDetailRows,
  formatPlacementsToRelatedPersons,
  sanitizeRelatedPersons,
} from './data';
import { OtherInformation } from './subs';
import { useAuth } from '../../hooks';
import { createFormData } from '../../utils';
import { useViewportSize } from '@mantine/hooks';

export const FamilyHomeDetail = () => {
  const submit = useSubmit();
  const {
    state: { user },
  } = useAuth();
  const data = useLoaderData() as {
    familyHome: FamilyHome;
    consultants: any[];
  };
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const [deleteFamilyHomeIsOpen, setDeleteFamilyHomeIsOpen] = useState(false);

  const openDeleteModal = useCallback(
    () => setDeleteFamilyHomeIsOpen(true),
    []
  );
  const closeDeleteModal = useCallback(
    () => setDeleteFamilyHomeIsOpen(false),
    []
  );

  const formattedPlacements = formatPlacementsToRelatedPersons(
    data?.familyHome?.placements
  );

  const sanitizedRelatedPersons = sanitizeRelatedPersons(
    data?.familyHome?.relatedPersons
  );

  const newRelatedPersonsWithPlacements = [
    ...formattedPlacements,
    ...sanitizedRelatedPersons,
  ];

  const onPlacementClick = (path: string) => {
    navigate(path);
  };

  const rows = formatFHDetailRows(
    newRelatedPersonsWithPlacements,
    onPlacementClick
  );
  const contactPersons = data?.familyHome?.relatedPersons?.filter(
    (person) =>
      person.relation === 'FamilyHomeFather' ||
      person.relation === 'FamilyHomeMother' ||
      person.relation === 'FamilyHomeThey'
  );
  const indexOfPrimaryContact = contactPersons?.findIndex(
    (person) => person.primaryContact
  );
  //take out and put the primary contact first in the array, rendering it at the top
  contactPersons?.unshift(...contactPersons.splice(indexOfPrimaryContact, 1));

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper>
          <IconLink
            to="/family-homes"
            iconBefore={
              <ArrowLeft
                size={18}
                color={COLORS.textSecondary}
                strokeWidth={'1.5px'}
              />
            }
          >
            <Text weight="500" size="13px">
              Familjehem
            </Text>
          </IconLink>

          <Title as="h1" type="secondary" weight="500">
            {data.familyHome.name}
          </Title>

          <Text size="17px" weight="600">
            {`${data.familyHome.zipCode}, ${data.familyHome.city}`}
          </Text>

          <Spacer minSpace="1rem" maxSpace="1rem" />

          {user?.role === UserRole.Admin && (
            <Menu closeOnItemClick={false}>
              <Menu.Target>
                <Button color="gray" rightIcon={<ChevronDown />}>
                  Konsulenter kopplade till familjehemmet
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Form method="post">
                  {data.consultants.map((consultant) => (
                    <Menu.Item key={consultant.userId}>
                      <Checkbox
                        disabled={consultant.role === UserRole.Admin}
                        checked={consultant.hasPermission}
                        key={consultant.userId}
                        onChange={(e) => {
                          const formData = createFormData({
                            consultantId: consultant.userId,
                            addPermission: !consultant.hasPermission,
                          });
                          submit(formData, { method: 'post' });
                        }}
                        label={`${consultant.firstName} ${consultant.lastName}`}
                      />
                    </Menu.Item>
                  ))}
                </Form>
              </Menu.Dropdown>
            </Menu>
          )}
          <Grid>
            <Col span={5}>
              <SecondaryButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate('edit');
                }}
                sx={{ width: '100%' }}
              >
                Ändra
              </SecondaryButton>
            </Col>
            <Col span={7}>
              <Button
                color="red"
                onClick={openDeleteModal}
                sx={{ fontSize: 'initial', width: '100%' }}
              >
                Ta bort familjehem
              </Button>
            </Col>
          </Grid>
        </MobileTopBarWrapper>

        <MobileContentWrapper>
          <StyledCard>
            {contactPersons.map((person, index) => (
              <Fragment key={person.id}>
                <Title as="h2" type="secondary" weight="500">
                  {`${index === 0 ? 'Primär kontaktperson' : 'Kontaktperson'}`}
                </Title>

                <Spacer minSpace="1rem" maxSpace="1rem" />

                <Title as="h2" type="secondary" weight="500">
                  {`${person.firstName} ${person.lastName}`}
                </Title>
                <DetailBlock header={'Telefonnummer'} content={person.phone} />
                <DetailBlock header={'E-postadress'} content={person.email} />

                <Spacer minSpace="2rem" maxSpace="3rem" />
              </Fragment>
            ))}

            <OtherInformation
              multiplePlacement={data.familyHome.multiplePlacement}
            />
          </StyledCard>
        </MobileContentWrapper>

        <Table rows={rows} sortingField="role" sortingOrder={SortOrder.DESC} />

        <DeleteModal
          isOpen={deleteFamilyHomeIsOpen}
          onCancel={closeDeleteModal}
          title={'Är du säker på att du vill ta bort familjehem?'}
          content={
            <>
              <p>
                När du godkänner kommer familjehemskontot att raderas från
                systemet.
              </p>
              <p>
                Placerade och konsulenter kopplade till familjehemmet raderas
                inte.{' '}
              </p>
            </>
          }
          continueText={'Ja, radera familjehem'}
        />
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to="/family-homes"
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Familjehem
              </Text>
            </IconLink>

            <Title as="h1" type="secondary" weight="500">
              {data.familyHome.name}
            </Title>

            <Text size="17px" weight="600">
              {`${data.familyHome.zipCode}, ${data.familyHome.city}`}
            </Text>
          </>
        }
        right={
          <>
            {user?.role === UserRole.Admin && (
              <Menu closeOnItemClick={false}>
                <Menu.Target>
                  <Button color="gray" rightIcon={<ChevronDown />}>
                    Konsulenter kopplade till familjehemmet
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Form method="post">
                    {data.consultants.map((consultant) => (
                      <Menu.Item key={consultant.userId}>
                        <Checkbox
                          disabled={consultant.role === UserRole.Admin}
                          checked={consultant.hasPermission}
                          key={consultant.userId}
                          onChange={(e) => {
                            const formData = createFormData({
                              consultantId: consultant.userId,
                              addPermission: !consultant.hasPermission,
                            });
                            submit(formData, { method: 'post' });
                          }}
                          label={`${consultant.firstName} ${consultant.lastName}`}
                        />
                      </Menu.Item>
                    ))}
                  </Form>
                </Menu.Dropdown>
              </Menu>
            )}
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate('edit');
              }}
            >
              Ändra
            </SecondaryButton>
            <Button
              color="red"
              onClick={openDeleteModal}
              sx={{ fontSize: 'initial' }}
            >
              Ta bort familjehem
            </Button>
          </>
        }
      />
      <DetailWrapper
        left={
          <>
            {contactPersons.map((person, index) => (
              <Fragment key={person.id}>
                <Title as="h2" type="secondary" weight="500">
                  {`${index === 0 ? 'Primär kontaktperson' : 'Kontaktperson'}`}
                </Title>

                <Spacer minSpace="1rem" maxSpace="1rem" />

                <Title as="h2" type="secondary" weight="500">
                  {`${person.firstName} ${person.lastName}`}
                </Title>
                <DetailBlock header={'Telefonnummer'} content={person.phone} />
                <DetailBlock header={'E-postadress'} content={person.email} />

                <Spacer minSpace="2rem" maxSpace="3rem" />
              </Fragment>
            ))}

            <OtherInformation
              multiplePlacement={data.familyHome.multiplePlacement}
            />
          </>
        }
        right={
          <Table
            rows={rows}
            sortingField="role"
            sortingOrder={SortOrder.DESC}
          />
        }
      />
      <DeleteModal
        isOpen={deleteFamilyHomeIsOpen}
        onCancel={closeDeleteModal}
        title={'Är du säker på att du vill ta bort familjehem?'}
        content={
          <>
            <p>
              När du godkänner kommer familjehemskontot att raderas från
              systemet.
            </p>
            <p>
              Placerade och konsulenter kopplade till familjehemmet raderas
              inte.{' '}
            </p>
          </>
        }
        continueText={'Ja, radera familjehem'}
      />
    </PageWrapper>
  );
};
