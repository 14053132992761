import jwtDecode from 'jwt-decode';
import { BASELINKS } from '../constants';

import { DecodedToken, User, UserRole } from '../types';

export const getVisibleNavLinks = (
  userRole: UserRole | undefined
): { to: string; title: string }[] => {
  if (!userRole) return [];

  if (userRole === UserRole.GlobalAdmin)
    return [...BASELINKS.slice(0, 2), ...BASELINKS.slice(5, 7)];
  if (userRole === UserRole.Admin) return [...BASELINKS].slice(2, 6);
  if (userRole === UserRole.Consultant) return [...BASELINKS].slice(3, 6);
  return BASELINKS;
};

export const decodeToken = (token: string) => {
  if (!token) return;
  let decoded;
  try {
    decoded = jwtDecode(token) as DecodedToken;
  } catch (error) {
    decoded = undefined;
  }
  return decoded;
};

export const getUserFromToken = (token: string): User | undefined => {
  if (!token) return;

  const decoded = decodeToken(token);

  return {
    email: decoded?.email,
    tenantId: decoded?.TenantId,
    tenantName: decoded?.TenantName,
    role: decoded?.role as UserRole,
    name: decoded?.name,
    exp: decoded?.exp,
  };
};
