import { useCallback, useState, useEffect } from 'react';
import { Button } from '@mantine/core';
import Badge from '@atlaskit/badge';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSubmit,
} from 'react-router-dom';
import { ArrowLeft } from 'react-feather';

import {
  Spacer,
  TopBar,
  PageWrapper,
  DetailWrapper,
  Title,
  Text,
  IconLink,
  Table,
  PasswordBox,
  DeleteModal,
  Modal,
  SecondaryButton,
} from '../../components';
import { COLORS } from '../../constants';
import { Tenant } from '../../types';
import { ContactDetail, QuickInfo } from './subs';
import { InvoiceButton } from './styles';
import { useAuth, useNewPassword } from '../../hooks';
import { WarningModal } from '../../components';
import { resetTenantPassword } from '../../api';
import { createFormData } from '../../utils';
import ReactRouterPrompt from 'react-router-prompt';

export const TenantDetail = () => {
  const { state: locationState } = useLocation();
  const params = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const submit = useSubmit();

  const {
    newPassword,
    fetchNewPassword,
    loading: newPasswordLoading,
    called: newPasswordCalled,
  } = useNewPassword();

  const tenant = useLoaderData() as Tenant;
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [passwordWarningIsOpen, setPasswordWarningIsOpen] = useState(false);
  const [deleteTenantIsOpen, setDeleteTenantIsOpen] = useState(false);
  const [invoiceTenantIsOpen, setInvoiceTenantIsOpen] = useState(false);
  const [invoiceTenantWithWarningIsOpen, setInvoiceTenantWithWarningIsOpen] =
    useState(false);
  const [monthToInvoice, setMonthToInvoice] = useState('');

  const openPasswordModal = useCallback(
    () => setPasswordWarningIsOpen(true),
    []
  );
  const closePasswordModal = useCallback(() => {
    setPasswordWarningIsOpen(false);
  }, []);

  const passwordModalFinish = useCallback(() => {
    navigate('/tenants/');
  }, [navigate]);

  const openDeleteModal = useCallback(() => setDeleteTenantIsOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteTenantIsOpen(false), []);

  const openInvoiceModal = useCallback(() => setInvoiceTenantIsOpen(true), []);
  const closeInvoiceModal = useCallback(
    () => setInvoiceTenantIsOpen(false),
    []
  );

  useEffect(() => {
    //gets the new password property when coming from a new create tenant form
    locationState &&
      locationState.newPassword &&
      fetchNewPassword(undefined, locationState.newPassword);
    //eslint-disable-next-line
  }, [locationState]);

  useEffect(() => {
    // Add a fake history event so that the back button does nothing if pressed once
    if (newPasswordCalled) {
      window.history.pushState(
        'fake-route',
        document.title,
        window.location.href
      );
    }
    const eventHandler = () => {
      if (newPasswordCalled) {
        openPasswordModal();
        window.history.pushState(
          'fake-route',
          document.title,
          window.location.href
        );
      }
    };

    window.addEventListener('popstate', eventHandler);

    return () => {
      window.removeEventListener('popstate', eventHandler);
      // If we left without using the back button, aka by using a button on the page, we need to clear out that fake history event
      if (window.history.state === 'fake-route') {
        window.history.back();
      }
    };
  }, [newPasswordCalled, openPasswordModal]);

  const rows = tenant.statistics.monthlyStatistics.map((month) => ({
    id: month.yearMonth,
    cells: [
      {
        name: 'month',
        label: 'Månad',
        isSortable: true,
        value: month.yearMonth,
        content: month.yearMonthText,
      },
      {
        name: 'invoicedReports',
        label: 'Fakturerade Rapporter',
        isSortable: true,
        value: month.numberOfInvoicedReports,
        content: (
          <Badge appearance="added">{month.numberOfInvoicedReports}</Badge>
        ),
      },
      {
        name: 'signedReports',
        label: 'Signerade Rapporter',
        isSortable: true,
        value: month.numberOfGeneratedReports,
        content: (
          <Badge appearance="primary">{month.numberOfGeneratedReports}</Badge>
        ),
      },
      {
        name: 'progressReports',
        label: 'Påbörjade Rapporter',
        isSortable: true,
        value: month.numberOfInProgressReports,
        content: (
          <Badge appearance={'default'}>
            {month.numberOfInProgressReports}
          </Badge>
        ),
      },
      {
        name: 'invoiced',
        label: 'Fakturera',
        isSortable: false,
        content: (
          <InvoiceButton
            variant={'light'}
            color={'teal'}
            size={'xs'}
            disabled={month.numberOfGeneratedReports === 0}
            onClick={() => {
              if (month.numberOfGeneratedReports === 0) return;
              setMonthToInvoice(month.yearMonth);
              openInvoiceModal();
            }}
          >
            {month.numberOfInvoicedReports > 0 &&
            month.numberOfGeneratedReports === 0
              ? 'Fakturerad'
              : 'Fakturera'}
          </InvoiceButton>
        ),
      },
    ],
  }));

  const [showReactRouterPrompt, setShowReactRouterPrompt] = useState(
    newPassword.length > 0
  );

  useEffect(() => {
    if (newPassword && newPassword.length > 0) {
      setShowReactRouterPrompt(true);
    } else {
      setShowReactRouterPrompt(false);
    }
  }, [newPassword]);

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to="/tenants"
              onClick={(e) => {
                if (newPasswordCalled) {
                  e.preventDefault();
                  openPasswordModal();
                }
              }}
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Tillbaka
              </Text>
            </IconLink>

            <Title as="h1" type="secondary" weight="500">
              {tenant.name}
            </Title>

            <Text size="11px" weight="600">
              {tenant.organizationNumber}
            </Text>
          </>
        }
        right={
          <>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate('edit');
              }}
            >
              Ändra
            </SecondaryButton>
            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                setChangePasswordModal(true);
              }}
            >
              Byt lösenord
            </SecondaryButton>
            <Button
              color="red"
              onClick={openDeleteModal}
              sx={{ fontSize: 'initial' }}
            >
              Ta bort kund
            </Button>
          </>
        }
      />
      <DetailWrapper
        left={
          <>
            <ContactDetail
              name={`${tenant.contactFirstName} ${tenant.contactLastName}`}
              email={tenant.contactEmail}
              phone={tenant.contactPhone}
            />
            <Spacer minSpace="2rem" />

            {newPasswordCalled && (
              <PasswordBox
                loading={newPasswordLoading}
                password={newPassword}
              />
            )}

            <QuickInfo stats={tenant.statistics} />
          </>
        }
        right={<Table rows={rows} />}
      />
      {/* Delete tenant modal */}
      <DeleteModal
        isOpen={deleteTenantIsOpen}
        onCancel={closeDeleteModal}
        title={'Är du säker på att du vill ta bort denna kund?'}
        content={
          <>
            <p>När du godkänner kommer kundkontot att raderas från systemet.</p>
            <p>
              Konsulentdata, familjehemsdata och information om placerade kommer
              tillsammans med kundens kontaktuppgifter att raderas.
            </p>
            <p>Kundens genererade rapporter är sparade för senare åtkomst.</p>
          </>
        }
        continueText={'Ja, radera kundkonto '}
      />
      {/* Invoice modal */}
      <Modal
        isOpen={invoiceTenantIsOpen}
        onCancel={closeInvoiceModal}
        onContinue={() => {
          const formData = createFormData({
            tenantId: tenant.id,
            yearMonth: monthToInvoice,
          });
          submit(formData, {
            method: 'patch',
            action: `/tenants/${tenant.id}/invoice`,
          });
          setInvoiceTenantIsOpen(false);
        }}
        content={`Denna kund har signerade rapporter som går att fakturera för denna månaden. 
        Vill du fortsätta och markera dessa signerade rapporter som fakturerade?`}
        continueText={'Ja, fortsätt'}
      />
      {/* Change password warning modal */}
      <WarningModal
        isOpen={changePasswordModal}
        onCancel={() => setChangePasswordModal(false)}
        onContinue={() => {
          fetchNewPassword(() =>
            resetTenantPassword(params.tenantId ?? '', token)
          );
          fetchNewPassword();
          setChangePasswordModal(false);
        }}
        title="Byt lösenord"
        continueText="Ja, jag vill byta lösenord"
        content="Detta kommer att byta kundens lösenord. Är du säker på att du vill genomföra detta?"
      />

      {/* Password warning modal */}
      <WarningModal
        isOpen={passwordWarningIsOpen}
        onCancel={closePasswordModal}
        onContinue={passwordModalFinish}
        title="Har du kopierat lösenordet?"
        continueText="Jag har kopierat och skickat, gå tillbaka"
        content={`Är du säker på att du kopierat lösenordet och skickat det till aktuell kund? 
        Lösenordet kommer inte visas efter att du gått tillbaka till kundvyn`}
      />
      <ReactRouterPrompt when={showReactRouterPrompt}>
        {({ onConfirm }) => (
          <WarningModal
            isOpen={showReactRouterPrompt}
            onCancel={() => {
              setShowReactRouterPrompt(false);
              setTimeout(() => {
                setShowReactRouterPrompt(newPassword.length > 0);
              }, 100);
            }}
            onContinue={onConfirm}
            title="Har du kopierat lösenordet?"
            continueText="Jag har kopierat och skickat, gå vidare"
            content="Är du säker på att du kopierat lösenordet och skickat det till aktuell kund?
            Lösenordet kommer inte visas i fortsättningen på aktuell kundvy"
          />
        )}
      </ReactRouterPrompt>
    </PageWrapper>
  );
};
