import { DefaultProps, SystemProp } from '@mantine/core';
import { Spacer } from '../Spacer';
import {
  StyledTopBar,
  StyledTopBarButtonWrapper,
  StyledTopBarTitleWrapper,
} from './styles';

interface TopBarProps {
  left: React.ReactNode | JSX.Element;
  right: React.ReactNode | JSX.Element;
  id?: string;
}

export const TopBar = ({ left, right, id }: TopBarProps) => {
  return (
    <StyledTopBar id={id}>
      <StyledTopBarTitleWrapper>{left}</StyledTopBarTitleWrapper>

      <Spacer minSpace="0.5rem" />

      <StyledTopBarButtonWrapper>{right}</StyledTopBarButtonWrapper>
    </StyledTopBar>
  );
};
