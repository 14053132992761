import { TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { RelatedPersonWrapper } from '..';
import { DateOfBirth } from '../../../';
import { StyledFormGroup } from '../../styles';

export enum SiblingType {
  Biological,
  Placed,
}

interface SiblingFieldsProps {
  form: UseFormReturnType<any>;
  index: number;
  formListName: string;
  title: string;
  textLabel?: string;
  siblingType: SiblingType;
  isMobile?: boolean;
}

export const SiblingFields = ({
  form,
  index,
  formListName,
  title,
  textLabel = '',
  siblingType,
  isMobile,
}: SiblingFieldsProps) => {
  return (
    <RelatedPersonWrapper
      title={title}
      index={index}
      onRemoveClick={() =>
        siblingType !== SiblingType.Placed
          ? form.removeListItem(formListName, index)
          : {}
      }
      showPlacedPath={
        siblingType === SiblingType.Placed
          ? `/placements/${form.values.placedSiblings[index].id}`
          : undefined
      }
      isMobile={isMobile}
    >
      <StyledFormGroup mt="xs">
        <TextInput
          label={textLabel ? textLabel : 'Placerads initialer eller benämning'}
          placeholder={
            textLabel ? textLabel : 'Placerads initialer eller benämning'
          }
          name="firstName"
          {...form.getInputProps(`${formListName}.${index}.firstName`)}
          disabled={siblingType === SiblingType.Placed}
        />
      </StyledFormGroup>

      <DateOfBirth
        form={form}
        index={index}
        formListName={formListName}
        person={
          siblingType === SiblingType.Biological
            ? form.values.biologicalSiblings[index]
            : form.values.placedSiblings[index]
        }
        disabled={siblingType === SiblingType.Placed}
      />
    </RelatedPersonWrapper>
  );
};
