import { Placement } from './Placement';

export interface FamilyHome {
  id: string;
  name: string;
  address: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  multiplePlacement: boolean;
  placements: Placement[];
  relatedPersons: RelatedPerson[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface CreateFamilyHomeData {
  name: string;
  address: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  multiplePlacement: boolean;
  relatedPersons: RelatedPerson[];
}

export interface RelatedPerson {
  id: string;
  firstName: string; //initials
  lastName?: string;
  birthDay: string;
  birthDayNotification: boolean;
  email: string;
  pet?: string;
  phone?: string;
  primaryContact: boolean;
  relation: string; //Keyword/enum i.e. OtherPlacedchild
}

//enum for helping with filtering and sorting
export enum FamilyRelation {
  FamilyHome,
  FamilyHomeMother,
  FamilyHomeFather,
  FamilyHomeThey,
  OtherPlacedchild,
  Biologicalchild,
  Pet,
  NotSet,
}
