import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { generateAiSentence } from '../api/sentences/generateAiSentence';
import { Dialog } from '@mantine/core';
import { COLORS } from '../constants';
import { Text } from '../components';

interface AiContextProps {
  generateSentenceFromTemplate: (
    templateSentence: string
  ) => Promise<string | null>;
  isLoading: boolean;
}

const AiContext = createContext<AiContextProps | undefined>(undefined);

interface AiProviderProps {
  children: ReactNode;
  token: string | null;
}

export const AiProvider: React.FC<AiProviderProps> = ({ children, token }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
  }, [showErrorMessage]);

  const generateSentenceFromTemplate = async (
    templateSentence: string
  ): Promise<string | null> => {
    try {
      setIsLoading(true);
      const response = await generateAiSentence({ templateSentence }, token);
      if (response.ok) {
        const { generatedSentence } = await response.json();
        if (!generatedSentence) setShowErrorMessage(true);

        return generatedSentence;
      } else {
        setShowErrorMessage(true);
        console.error('Failed to generate AI sentence');
        return null;
      }
    } catch (error) {
      setShowErrorMessage(true);
      console.error('Error generating AI sentence:', error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AiContext.Provider value={{ generateSentenceFromTemplate, isLoading }}>
      {children}
      <Dialog
        opened={showErrorMessage}
        size="md"
        radius="sm"
        style={{ backgroundColor: COLORS.hdAccentRed }}
        zIndex={2000}
      >
        <Text color={COLORS.textPrimary} size={'14px'}>
          Något gick fel med att generera AI meningen
        </Text>
      </Dialog>
    </AiContext.Provider>
  );
};

export const useAiContext = (): AiContextProps => {
  const context = useContext(AiContext);
  if (!context) {
    throw new Error('useAiContext must be used within an AiProvider');
  }
  return context;
};
