import { DatePicker as MantineDatePicker } from '@mantine/dates';
import { Calendar } from 'react-feather';
import dayjs from 'dayjs';

import 'dayjs/locale/sv';
import { useViewportSize } from '@mantine/hooks';
dayjs.locale('sv');

interface DatePickerProps {
  value?: string | null;
  disabled?: boolean;
  onChange: (value: Date) => void;
  isAdditional?: boolean;
  defaultDate?: Date;
}

export const DatePicker = ({
  value,
  disabled,
  onChange,
  isAdditional,
  defaultDate,
}: DatePickerProps) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const handleOnChange = (val: Date) => {
    onChange && onChange(val);
  };
  return (
    <MantineDatePicker
      sx={isMobile ? { width: '100%' } : { alignSelf: 'center' }}
      locale="sv"
      value={value && !value.includes('1970-01-01') ? new Date(value) : null}
      disabled={disabled}
      onChange={handleOnChange}
      placeholder="Välj datum"
      label={isAdditional ? '' : 'Ange datum för aktiviteten'}
      inputFormat="YYYY-MM-DD"
      labelFormat="YYYY/MM"
      icon={<Calendar size={16} />}
      initialMonth={defaultDate == null ? new Date() : defaultDate}
    />
  );
};
