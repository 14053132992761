import { Select } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

interface DynamicTextSelectProps {
  value?: string;
  options?: string[];
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const DynamicTextSelect = ({
  value,
  options,
  onChange,
  disabled,
}: DynamicTextSelectProps) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;
  return (
    <>
      <Select
        value={value}
        sx={isMobile ? { width: '100%' } : { alignSelf: 'center' }}
        data={[
          ...(options?.map((text) => ({ label: text, value: text })) || []),
          { label: 'Ej Relevant', value: 'NotApplicable' },
        ]}
        placeholder={'Välj'}
        label={'Välj'}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );
};
