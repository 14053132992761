import {
  StyledDetailWrapper,
  LeftContentWrapper,
  RightContentWrapper,
} from './styles';

interface DetailWrapperProps {
  left: React.ReactNode | JSX.Element;
  right: React.ReactNode | JSX.Element;
}

export const DetailWrapper = ({ left, right }: DetailWrapperProps) => {
  return (
    <StyledDetailWrapper>
      <LeftContentWrapper>{left}</LeftContentWrapper>
      <RightContentWrapper>{right}</RightContentWrapper>
    </StyledDetailWrapper>
  );
};
