import { useLoaderData, useSubmit } from 'react-router-dom';

import { CreateTenantFormData, Tenant } from '../../types';
import { PageWrapper, TenantForm } from '../../components';
import { createFormData } from '../../utils';

export const TenantEdit = () => {
  const tenant = useLoaderData() as Tenant;
  const submit = useSubmit();

  const onSubmit = (data: CreateTenantFormData) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'put',
    });
  };

  return (
    <PageWrapper>
      <TenantForm onSubmit={onSubmit} tenant={tenant} />
    </PageWrapper>
  );
};
