import { Box } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { ArrowLeft } from 'react-feather';
import {
  Navigate,
  useLoaderData,
  useNavigate,
  useSubmit,
} from 'react-router-dom';

import {
  MobileBackgroundWrapper,
  MobileTopBarWrapper,
  Spacer,
  Text,
  PlacedButton,
  ContactForm,
  PageWrapper,
  TopBar,
  IconLink,
  Title,
  Table,
  DetailWrapper,
  MobileContentWrapper,
} from '../../components';
import { COLORS } from '../../constants';
import {
  FamilyRelation,
  Placement,
  PlacementReport,
  RelatedPerson,
  ReportStatus,
} from '../../types';
import { createFormData, getFamilyRelationText } from '../../utils';
import dayjs from 'dayjs';

export const getNameFromContact = (
  contact: string,
  contactOptions: Array<RelatedPerson> | undefined
): string | undefined => {
  const relatedPerson = contactOptions?.find(
    (option) => option.relation === contact
  );
  if (relatedPerson)
    return `${relatedPerson.firstName} ${relatedPerson.lastName}`;

  if (contact === 'Trustee') return 'God man';
  else if (contact === 'Placedchild') return 'Placerat barn';

  return getFamilyRelationText(
    FamilyRelation[contact as keyof typeof FamilyRelation]
  );
};

export const getContactOptions = (placement?: Placement) => {
  return placement?.familyHome?.relatedPersons?.filter(
    (person) =>
      person.relation === 'FamilyHomeFather' ||
      person.relation === 'FamilyHomeMother' ||
      person.relation === 'FamilyHomeThey' ||
      person.relation === 'Trustee'
  );
};

export const translateContactType = (contactType: string) => {
  switch (contactType) {
    case 'Visit':
      return 'Besök';
    case 'Phone':
      return 'Telefonsamtal';
    case 'SocialService':
      return 'Besök från socialtjänst';
    case 'Trustee':
      return 'God man';
    default:
      return contactType;
  }
};

export const Contact = () => {
  const submit = useSubmit();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const { placement, report } = useLoaderData() as {
    placement: Placement;
    report: PlacementReport;
  };
  const isMobile = width < 500;

  const onSubmit = (data: any) => {
    const formData = createFormData({
      ...data,
      reportId: report?.id,
    });
    submit(formData, {
      method: 'post',
    });
  };

  if (
    report?.status === ReportStatus.Generated ||
    report?.status === ReportStatus.Signed
  ) {
    return (
      <Navigate
        replace
        to={`/placements/${placement?.id}/report/${report?.id}/signing`}
      />
    );
  }

  const rows =
    report?.formattedConsultantTimes &&
    report?.formattedConsultantTimes.length > 0
      ? report.formattedConsultantTimes.map((consultantTime, index) => ({
          id: index.toString(),
          cells: [
            {
              name: 'date',
              label: 'Datum',
              isSortable: true,
              value: dayjs(consultantTime.date).format('YYYY-MM-DD'),
              content: dayjs(consultantTime.date).format('YYYY-MM-DD'),
            },
            {
              name: 'contact',
              label: 'Kontakt med',
              isSortable: true,
              value: getNameFromContact(
                consultantTime.contact,
                getContactOptions(placement)
              ),
              content: getNameFromContact(
                consultantTime.contact,
                getContactOptions(placement)
              ),
            },
            {
              name: 'contactType',
              label: 'Typ av kontakt',
              isSortable: true,
              value: translateContactType(consultantTime.contactType),
              content: translateContactType(consultantTime.contactType),
            },
            {
              name: 'minutes',
              label: 'Minuter',
              isSortable: true,
              value: consultantTime.minutes,
              content: consultantTime.minutes,
            },
          ],
        }))
      : [];

  if (isMobile) {
    return (
      <>
        <MobileBackgroundWrapper>
          <MobileTopBarWrapper direction="row">
            <Box sx={{ flexDirection: 'column' }}>
              <Text size="18px">{placement?.name}</Text>
              <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
              <Text size="16px" weight="400">
                {placement?.birthDay &&
                  dayjs(new Date(placement?.birthDay)).format('YYYY-MM-DD')}
              </Text>
              <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
              <Text size="16px" weight="400">
                {`${placement?.familyHome?.name ?? '-'}, ${
                  placement?.familyHome?.city ?? '-'
                }`}
              </Text>
            </Box>
            <Spacer minSpace="0.5rem" />
            <PlacedButton onClick={() => navigate('/placements')}>
              Mina placerade
            </PlacedButton>
          </MobileTopBarWrapper>
          <MobileContentWrapper>
            <ContactForm
              onSubmit={onSubmit}
              contactOptions={getContactOptions(placement)}
              yearMonth={report.yearMonth}
            />
          </MobileContentWrapper>
          <MobileContentWrapper>
            <Table paddingCss="1.5rem 3rem" rows={rows} />
          </MobileContentWrapper>
        </MobileBackgroundWrapper>
      </>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to={`/placements/${placement.id}`}
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Tillbaka
              </Text>
            </IconLink>

            <Title as="h1" type="secondary" weight="500">
              {placement.familyHome
                ? `${placement.name} - ${placement.familyHome.name}, ${placement.familyHome.city}`
                : `${placement.name}`}
            </Title>
          </>
        }
        right={null}
      />
      <DetailWrapper
        left={
          <ContactForm
            onSubmit={onSubmit}
            contactOptions={getContactOptions(placement)}
            yearMonth={report.yearMonth}
          />
        }
        right={
          <div
            style={{
              flexDirection: 'column',
              width: '100%',
              height: 'fit-content',
            }}
          >
            <div style={{ padding: '3.5rem 1rem 1rem 1rem' }}>
              <Text size="23px" lineHeight="23px">
                Tidigare registreringar vid denna rapport
              </Text>
            </div>
            <Table paddingCss="1rem 2rem" rows={rows} />
          </div>
        }
      />
    </PageWrapper>
  );
};
