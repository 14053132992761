import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const StyledMobileBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${COLORS.hdBackgroundGreenLight};
`;

export const MobileBackgroundWrapper = ({ children }: any) => (
  <StyledMobileBackground>{children}</StyledMobileBackground>
);
