import { useLoaderData, useNavigate } from 'react-router-dom';

import {
  TopBar,
  PageWrapper,
  Title,
  Table,
  PrimaryButton,
} from '../../components';
import { formatConsultantsRows } from './data';
import { Consultant } from '../../types';

export const Consultants = () => {
  const navigate = useNavigate();
  const data = useLoaderData() as Consultant[];

  const rows = formatConsultantsRows(data, (id: string) => navigate(id));

  return (
    <PageWrapper>
      <TopBar
        left={
          <Title as="h1" type="secondary" weight="500">
            Konsulenter
          </Title>
        }
        right={
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              navigate('create');
            }}
          >
            Lägg till ny konsulent
          </PrimaryButton>
        }
      />

      <Table rows={rows} buttonMaxWidth={'3.25rem'} />
    </PageWrapper>
  );
};
