import { Radio } from '@mantine/core';
import { CheckButton, Spacer, Title } from '../../../../components';
import { COLORS } from '../../../../constants';
import { StyledSection } from '../../styles';

interface PeriodSectionProps {
  period: string;
  months: any[];
  setPeriod: any;
  handleMonths: any;
}

export const PeriodSection = ({
  period,
  months,
  setPeriod,
  handleMonths,
}: PeriodSectionProps) => {
  return (
    <StyledSection>
      <Title as="h4" type="faded" weight="600">
        Tidsperiod
      </Title>
      <Radio.Group value={period} onChange={setPeriod} name="period">
        <Radio
          value="all"
          label="Alla månader"
          size="xs"
          color={COLORS.hdAccentBlue}
        />
        <Radio
          value="specific"
          label="Specifika månader"
          size="xs"
          color={COLORS.hdAccentBlue}
        />
      </Radio.Group>
      {period === 'specific' && (
        <>
          <Spacer minSpace="1rem" />
          <Title as="h4" type="faded" weight="600">
            Välj månader
          </Title>
          <>
            {months.map((item) => {
              return (
                <CheckButton
                  key={item.name}
                  selected={item.selected}
                  value={item.name}
                  onClick={handleMonths}
                >
                  {item.name}
                </CheckButton>
              );
            })}
          </>
        </>
      )}
    </StyledSection>
  );
};
