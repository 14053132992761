import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Menu, Progress, Select } from '@mantine/core';
import {
  Form,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSubmit,
} from 'react-router-dom';
import { ArrowLeft, Download, Trash2 } from 'react-feather';

import {
  Spacer,
  TopBar,
  PageWrapper,
  DetailWrapper,
  Title,
  Text,
  IconLink,
  Table,
  DeleteModal,
  SecondaryButton,
  DetailBlock,
  MobileBackgroundWrapper,
  MobileTopBarWrapper,
  PrimaryButton,
  BirthdayIcon,
  PlacedButton,
  PlacementCard,
} from '../../components';
import { COLORS } from '../../constants';
import {
  FamilyHome,
  Placement,
  MonthlyReport,
  UserRole,
  ReportStatus,
  FormattedConsultantTime,
  PlacementReport,
  Note,
} from '../../types';
import { getPDFReport } from '../../api';
import { useAuth } from '../../hooks';
import { createFormData, placementReasonsTranslation } from '../../utils';
import { useViewportSize } from '@mantine/hooks';
import {
  CardContent,
  PlacementNameWrapper,
  ProgressWrapper,
  StyledCard,
} from '../../components/PlacementCard/styles';
import { MobileDetailsWrapper } from './styles';
import { DetailBirthdayCard } from './subs';
import dayjs from 'dayjs';
import {
  getContactOptions,
  getNameFromContact,
  translateContactType,
} from '../Contact';
import { formatReportRows } from '../ReportList/data';
import { useNoteContext } from '../../contexts/NoteContext';
import { NoteReportRepeat } from '../Root/subs/Notes/subs/Notepad';
import { getPlacementNoteTableRowsAdmin } from '../../utils/noteHelpers';

export const reportIsEditable = (report: PlacementReport) =>
  report?.status === ReportStatus.New ||
  report?.status === ReportStatus.InProgress ||
  report?.status === ReportStatus.Generated;

export const reportIsGenerated = (report: PlacementReport) =>
  reportIsEditable(report) && report?.status === ReportStatus.Generated;

export const reportIsSigned = (report: PlacementReport) =>
  report?.status === ReportStatus.Signed &&
  report?.yearMonth === dayjs(new Date()).format('YYYYMM');

enum TableTab {
  Reports = 'Reports',
  ConsultantTimes = 'ConsultantTimes',
  Notes = 'Notes',
}

export const PlacementDetail = () => {
  const submit = useSubmit();
  const { state } = useAuth();
  const navigate = useNavigate();
  const { placementId } = useParams();
  const { width } = useViewportSize();
  const isMobile = width < 500;
  const isConsultant = state.user?.role === UserRole.Consultant;
  const noteContext = useNoteContext();

  const [showTableTab, setShowTableTab] = useState(TableTab.Reports);

  const {
    placement,
    reports,
    consultants,
    familyHomes,
    formattedConsultantTimes,
    placementNotes,
  } = useLoaderData() as {
    placement: Placement;
    reports: MonthlyReport[];
    consultants: any[];
    familyHomes: FamilyHome[];
    formattedConsultantTimes?: FormattedConsultantTime[];
    placementNotes: Note[];
  };

  const responsibleConsultant = consultants?.find(
    (consultant) => consultant.isResponsible === true
  );

  const birthdaySoon = placement.familyHome?.relatedPersons.find(
    (person) => person.birthDayNotification
  );

  const [deletePlacementIsOpen, setDeletePlacementIsOpen] = useState(false);
  const openDeleteModal = useCallback(() => setDeletePlacementIsOpen(true), []);
  const closeDeleteModal = useCallback(
    () => setDeletePlacementIsOpen(false),
    []
  );

  const [isOpen, setIsOpen] = useState(false);
  const [reportIdToDelete, setreportIdToDelete] = useState('');

  const handleOnDelete = () => {
    setreportIdToDelete('');
    setIsOpen(false);
  };

  useEffect(() => {
    handleOnDelete();
  }, []);

  const reportRows =
    reports && reports.length > 0
      ? formatReportRows(reports, state?.token, (id) => {
          setreportIdToDelete(id);
          setIsOpen(true);
        })
      : [];

  let noteRows;
  if (state.user?.role === UserRole.Admin) {
    noteRows = getPlacementNoteTableRowsAdmin(placementNotes);
  } else if (state.user?.role === UserRole.Consultant) {
    noteRows = noteContext.getPlacementNoteTableRowsConsultant(placementNotes);
  }

  const formattedConsultantTimesRows =
    formattedConsultantTimes && formattedConsultantTimes.length > 0
      ? formattedConsultantTimes.map((consultantTime, index) => ({
          id: index.toString(),
          cells: [
            {
              name: 'date',
              label: 'Datum',
              isSortable: true,
              value: dayjs(consultantTime.date).format('YYYY-MM-DD'),
              content: dayjs(consultantTime.date).format('YYYY-MM-DD'),
            },
            {
              name: 'contact',
              label: 'Kontakt med',
              isSortable: true,
              value: getNameFromContact(
                consultantTime.contact,
                getContactOptions(placement)
              ),
              content: getNameFromContact(
                consultantTime.contact,
                getContactOptions(placement)
              ),
            },
            {
              name: 'contactType',
              label: 'Typ av kontakt',
              isSortable: true,
              value: translateContactType(consultantTime.contactType),
              content: translateContactType(consultantTime.contactType),
            },
            {
              name: 'minutes',
              label: 'Minuter',
              isSortable: true,
              value: consultantTime.minutes,
              content: consultantTime.minutes,
            },
          ],
        }))
      : [];

  const familyHomeOptions = placement?.familyHome?.id
    ? [
        {
          value: 'Inget',
          label: 'Inget',
        },
        ...familyHomes.map((familyHome) => ({
          value: familyHome.id,
          label: `${familyHome.city}, ${familyHome.name}`,
        })),
      ]
    : [
        ...familyHomes.map((familyHome) => ({
          value: familyHome.id,
          label: `${familyHome.city}, ${familyHome.name}`,
        })),
      ];

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper direction="row">
          <Box sx={{ flexDirection: 'column' }}>
            <Text size="18px">{placement?.name}</Text>
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            <Text size="16px" weight="400">
              {placement?.birthDay
                ? dayjs(new Date(placement?.birthDay)).format('YYYY-MM-DD')
                : ''}
            </Text>
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            <Text size="16px" weight="400">
              {`${placement?.familyHome?.name ?? ''}, ${
                placement?.familyHome?.city ?? ''
              }`}
            </Text>
          </Box>
          <Spacer minSpace="0.5rem" />
          <PlacedButton onClick={() => navigate('/placements')}>
            Mina placerade
          </PlacedButton>
        </MobileTopBarWrapper>
        <MobileDetailsWrapper>
          <CardContent>
            <PlacementNameWrapper>
              <Text weight="600" size="16px">
                {placement?.name}
              </Text>
              <Text weight="400" size="16px">
                {placement?.familyHome?.name}
              </Text>
            </PlacementNameWrapper>
            <Spacer minSpace="0.5rem" />
            {placement.birthDayNotification && <BirthdayIcon />}
          </CardContent>

          <PlacementCard placement={placement} reports={reports} />

          {placement.familyHome?.relatedPersons.find(
            (person) => person.birthDayNotification
          ) && <DetailBirthdayCard placement={placement} />}

          <StyledCard>
            <>
              <Text size="16px">Primär kontaktperson, familjehem</Text>
              <Text size="16px" weight="400">
                {`${placement?.familyHome?.name ?? '-'}`}
              </Text>

              <DetailBlock
                header={'Telefonnummer'}
                content={placement?.familyHome?.phoneNumber ?? '-'}
              />

              <Spacer maxSpace="0.5rem" />
              <Text size="16px">{`Placeringsorsak`}</Text>

              {[...placement.ownReasons, ...placement.experiencedReasons].map(
                (reason, index) => (
                  <DetailBlock
                    key={`${reason}-${placement.id}`}
                    header={`Orsak ${index + 1}`}
                    content={
                      placementReasonsTranslation[
                        reason as keyof typeof placementReasonsTranslation
                      ]
                    }
                  />
                )
              )}
            </>
          </StyledCard>

          <Spacer minSpace="1rem" maxSpace="1rem" />

          <StyledCard>
            <div
              style={{
                flexDirection: 'column',
                width: '100%',
                height: 'fit-content',
              }}
            >
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text size="23px" lineHeight="23px">
                    {(() => {
                      switch (showTableTab) {
                        case TableTab.ConsultantTimes:
                          return 'Tidigare registreringar';
                        case TableTab.Notes:
                          return 'Anteckningar';

                        default:
                          return 'Rapporter';
                      }
                    })()}
                  </Text>

                  <Spacer minSpace="1rem" maxSpace="1rem" />

                  <Button.Group orientation="vertical">
                    <Button
                      variant={
                        showTableTab === TableTab.Reports ? 'filled' : 'default'
                      }
                      onClick={() => setShowTableTab(TableTab.Reports)}
                    >
                      Rapporter
                    </Button>
                    <Button
                      variant={
                        showTableTab === TableTab.ConsultantTimes
                          ? 'filled'
                          : 'default'
                      }
                      onClick={() => setShowTableTab(TableTab.ConsultantTimes)}
                    >
                      Tidigare registreringar
                    </Button>
                    <Button
                      variant={
                        showTableTab === TableTab.Notes ? 'filled' : 'default'
                      }
                      onClick={() => setShowTableTab(TableTab.Notes)}
                    >
                      Anteckningar
                    </Button>
                  </Button.Group>
                </div>
              </div>

              <Spacer minSpace="1rem" maxSpace="1rem" />

              <Table
                paddingCss="0"
                rows={
                  showTableTab === TableTab.ConsultantTimes
                    ? formattedConsultantTimesRows
                    : showTableTab === TableTab.Notes
                    ? noteRows
                    : reportRows
                }
              />
            </div>
          </StyledCard>

          <Spacer maxSpace="3rem" minSpace="3rem" />
        </MobileDetailsWrapper>
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to="/placements"
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Placeringar
              </Text>
            </IconLink>

            <Text size="16px" weight="400">
              {placement?.birthDay &&
                dayjs(new Date(placement?.birthDay)).format('YYYY-MM-DD')}
            </Text>
            <Title as="h1" type="secondary" weight="500">
              {placement.familyHome
                ? `${placement.name} - ${placement.familyHome.name}, ${placement.familyHome.city}`
                : `${placement.name}`}
            </Title>
          </>
        }
        right={
          <>
            {state.user?.role === UserRole.Admin && (
              <Form method="post" action="/move">
                <Select
                  sx={{ label: { wordBreak: 'initial' } }}
                  label="Familjehem"
                  data={familyHomeOptions}
                  placeholder={'Ange familjehem'}
                  onChange={(value: string) => {
                    const data =
                      value === 'Inget'
                        ? {
                            familyHomeId: placement.familyHome?.id,
                            remove: true,
                          }
                        : {
                            familyHomeId: value,
                          };

                    const formData = createFormData(data);
                    submit(formData, {
                      method: 'post',
                      action: `/placements/${placementId}/move`,
                    });
                  }}
                  value={placement.familyHome?.id}
                />
              </Form>
            )}

            {state.user?.role === UserRole.Admin && (
              <Menu closeOnItemClick={false}>
                <Menu.Target>
                  {/* In this specific use case this select is only a button, hence no data */}
                  <Select
                    ml="xs"
                    sx={{
                      label: { wordBreak: 'initial' },
                      'input::placeholder': {
                        color: COLORS.textSecondary,
                      },
                    }}
                    label="Ansvarig konsulent"
                    placeholder={
                      responsibleConsultant?.firstName &&
                      responsibleConsultant?.lastName
                        ? `${responsibleConsultant?.firstName} ${responsibleConsultant?.lastName}`
                        : 'Välj ansvarig konsulent'
                    }
                    data={[]}
                  />
                </Menu.Target>

                <Menu.Dropdown>
                  {consultants.map((consultant: any) => (
                    <Menu.Item key={consultant.userId}>
                      <Checkbox
                        checked={consultant.isResponsible}
                        key={consultant.userId}
                        onChange={(e) => {
                          const formData = createFormData({
                            consultantId: consultant.userId,
                            isResponsible: !consultant.isResponsible,
                          });
                          submit(formData, {
                            method: 'post',
                            action: `/placements/${placementId}/responsibility`,
                          });
                        }}
                        label={`${consultant.firstName} ${consultant.lastName}`}
                      />
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            )}

            <SecondaryButton
              onClick={(e) => {
                e.preventDefault();
                navigate('edit');
              }}
            >
              Ändra
            </SecondaryButton>
            <Button
              color="red"
              onClick={openDeleteModal}
              sx={{ fontSize: 'initial' }}
            >
              Ta bort placerad
            </Button>
          </>
        }
      />
      <DetailWrapper
        left={
          <>
            {isConsultant && (
              <PlacementCard placement={placement} reports={reports} />
            )}

            {isConsultant && birthdaySoon && (
              <DetailBirthdayCard placement={placement} />
            )}

            <StyledCard>
              <Title as="h2" type="secondary" weight="500">
                {`Primär kontaktperson, familjehem`}
              </Title>

              <Spacer minSpace="0.5rem" maxSpace="0.5rem" />

              <Text size="16px" weight="500">
                {`${placement?.familyHome?.name ?? '-'}`}
              </Text>
              <DetailBlock
                header={'Telefonnummer'}
                content={placement?.familyHome?.phoneNumber ?? '-'}
              />

              <Spacer minSpace="2rem" maxSpace="3rem" />

              <Title as="h2" type="secondary" weight="500">
                {`Placeringsorsak`}
              </Title>

              {[...placement.ownReasons, ...placement.experiencedReasons].map(
                (reason) => (
                  <DetailBlock
                    key={`${reason}-${placement.id}`}
                    header={''}
                    content={
                      placementReasonsTranslation[
                        reason as keyof typeof placementReasonsTranslation
                      ]
                    }
                  />
                )
              )}
            </StyledCard>

            <Spacer minSpace="6rem" />
          </>
        }
        right={
          <>
            <div
              style={{
                flexDirection: 'column',
                width: '100%',
                height: 'fit-content',
              }}
            >
              <div style={{ padding: '2rem 2rem 0 2rem' }}>
                <TopBar
                  left={
                    // <></>
                    <Text size="23px" lineHeight="23px">
                      {(() => {
                        switch (showTableTab) {
                          case TableTab.ConsultantTimes:
                            return 'Tidigare registreringar';
                          case TableTab.Notes:
                            return 'Anteckningar';
                          default:
                            return 'Rapporter';
                        }
                      })()}
                    </Text>
                  }
                  right={
                    <Button.Group>
                      <Button
                        variant={
                          showTableTab === TableTab.Reports
                            ? 'filled'
                            : 'default'
                        }
                        onClick={() => setShowTableTab(TableTab.Reports)}
                      >
                        Rapporter
                      </Button>
                      <Button
                        variant={
                          showTableTab === TableTab.ConsultantTimes
                            ? 'filled'
                            : 'default'
                        }
                        onClick={() =>
                          setShowTableTab(TableTab.ConsultantTimes)
                        }
                      >
                        Tidigare registreringar
                      </Button>
                      <Button
                        variant={
                          showTableTab === TableTab.Notes ? 'filled' : 'default'
                        }
                        onClick={() => setShowTableTab(TableTab.Notes)}
                      >
                        Anteckningar
                      </Button>
                    </Button.Group>
                  }
                />
              </div>
              <Table
                paddingCss="1rem 2rem"
                rows={
                  showTableTab === TableTab.ConsultantTimes
                    ? formattedConsultantTimesRows
                    : showTableTab === TableTab.Notes
                    ? noteRows
                    : reportRows
                }
              />
            </div>
          </>
        }
      />
      <DeleteModal
        isOpen={deletePlacementIsOpen}
        onCancel={closeDeleteModal}
        title={'Är du säker på att du vill ta bort placerad?'}
        content={
          <>
            När du godkänner kommer placerades konto att raderas från systemet.
            Familjehem och konsulenter kopplade till den placerade kommer inte
            raderas.
          </>
        }
        continueText={'Ja, radera placerads konto'}
      />
      <DeleteModal
        isOpen={isOpen}
        onCancel={handleOnDelete}
        onClose={handleOnDelete}
        action={`/reports/${reportIdToDelete}/delete`}
        redirectPath={`/placements/${placement.id}`}
        title="Är du säker?"
        content="Du är på väg att ta bort den här rapporten, är du säker på att du vill fortsätta?"
        continueText="Ja jag är säker, ta bort rapporten"
      />
    </PageWrapper>
  );
};
