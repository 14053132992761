import React from 'react';
import { RouteObject, useLoaderData } from 'react-router-dom';
import { Contact, ErrorPage, Report, ReportList, Signing } from '../../pages';
import {
  createNewReportAction,
  deleteReportAction,
  generateReportAction,
  registerContactAction,
  saveReportContentAction,
  signReportAction,
} from './actions';
import { reportListLoader, reportsLoader, signingLoader } from './loaders';
import { AutosaveProvider } from '../../contexts/AutosaveContext';
import { Placement, MonthlyReport } from '../../types';

interface ReportLoaderData {
  placement: Placement;
  report: MonthlyReport;
}

const ReportWithAutosaveProvider: React.FC<{ token: string | null }> = ({
  token,
}) => {
  const { placement, report } = useLoaderData() as ReportLoaderData;

  return (
    <AutosaveProvider token={token} report={report}>
      <Report placement={placement} />
    </AutosaveProvider>
  );
};

export const reportsRoutes = (
  token: string | null = null,
  isGlobalAdmin = false
): RouteObject[] => [
  {
    path: 'placements/:placementId/report/:reportId/form',
    element: <ReportWithAutosaveProvider token={token} />,
    errorElement: <ErrorPage />,
    loader: reportsLoader({ token }),
  },
  {
    path: 'placements/:placementId/report/create', // requires yearMonth and redirectAction in body
    errorElement: <ErrorPage />,
    action: createNewReportAction({ token }),
  },
  {
    path: 'placements/:placementId/report/:reportId/register',
    element: <Contact />,
    errorElement: <ErrorPage />,
    loader: reportsLoader({ token }),
    action: registerContactAction({ token }),
  },
  {
    path: 'placements/:placementId/generate', //reportId is sent as formData instead
    errorElement: <ErrorPage />,
    action: generateReportAction({ token }),
  },
  {
    path: 'placements/:placementId/report/:reportId/signing',
    element: <Signing />,
    errorElement: <ErrorPage />,
    loader: signingLoader({ token }),
    action: saveReportContentAction({ token }),
  },
  {
    path: 'placements/:placementId/report/:reportId/sign',
    element: <div>report sign</div>,
    errorElement: <ErrorPage />,
    action: signReportAction({ token }),
  },
  {
    path: 'reports',
    element: <ReportList />,
    errorElement: <ErrorPage />,
    loader: reportListLoader({ token, isGlobalAdmin }),
  },
  {
    path: 'reports/:reportId/delete',
    errorElement: <ErrorPage />,
    action: deleteReportAction({ token }),
  },
];
