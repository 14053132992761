import { NoteProvider, useNoteContext } from '../../../../contexts/NoteContext';
import { NotepadDrawer } from './styles';
import { Notepad } from './subs/Notepad';
import { useState } from 'react';
import { OverlayNotepadButton } from './subs/OverlayNotepadButton';
import { Title } from '../../../../components';
import { COLORS } from '../../../../constants';

interface NotesProps {
  token: string | null;
  isMobile: boolean;
}

export const Notes = ({ token, isMobile }: NotesProps) => {
  const NOTEPAD_DRAWER_WIDTH_DESKTOP = '60rem';
  const NOTEPAD_DRAWER_WIDTH_MOBILE = '20rem';

  const { notepadOpen, setNotepadOpen } = useNoteContext();

  const closeNotepadDrawer = () => setNotepadOpen(false);

  return (
    <>
      <NotepadDrawer
        position="right"
        size={
          isMobile ? NOTEPAD_DRAWER_WIDTH_MOBILE : NOTEPAD_DRAWER_WIDTH_DESKTOP
        }
        onClose={closeNotepadDrawer}
        opened={notepadOpen}
        withCloseButton={false}
      >
        <Notepad token={token} isMobile={isMobile} />
      </NotepadDrawer>

      <OverlayNotepadButton
        onClick={() => {
          setNotepadOpen((notepadOpen) => !notepadOpen);
        }}
        overlayOpened={notepadOpen}
        drawerWidth={
          isMobile ? NOTEPAD_DRAWER_WIDTH_MOBILE : NOTEPAD_DRAWER_WIDTH_DESKTOP
        }
      />
    </>
  );
};
