import { useForm, isNotEmpty } from '@mantine/form';
import {
  useLoaderData,
  useActionData,
  useSubmit,
  useNavigate,
  Form,
} from 'react-router-dom';
import { useState, useEffect } from 'react';

import {
  PageWrapper,
  Title,
  PrimaryButton,
  CancelButton,
  Spacer,
  Text,
} from '../../components';
import {
  Category,
  CreateSentenceFormData,
  Sentence,
  PlacementExperiencedReasons,
  PlacementOwnReasons,
} from '../../types';
import {
  CreateSentenceWrapper,
  StyledSectionWrapper,
  StyledTransparentSection,
  StyledButtonWrapper,
} from './styles';
import { COLORS } from '../../constants';
import { statusList, monthsList, ageList, sexList, tagsList } from './data';
import { createFormData } from '../../utils';
import {
  CategorySection,
  PeriodSection,
  AgeSection,
  ReasonsSection,
  SentenceSection,
  SexSection,
} from './subs';
import { Dialog } from '@mantine/core';
import { placementReasonsTranslation } from '../../utils';

export const SentenceCreate = () => {
  const { forms, sentence } = useLoaderData() as {
    forms: Category[];
    sentence: Sentence;
  };

  const previewSentence = useActionData() as string;

  const submit = useSubmit();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      text: sentence ? sentence.text : '',
      mainCategory: sentence ? sentence.formHierarchy?.formStepId : '',
      subCategory: sentence ? sentence.formHierarchy?.formQuestionId : '',
      status: sentence ? sentence.questionResponse : '',
    },
    validate: {
      text: isNotEmpty('Meningen får inte vara tom'),
      mainCategory: isNotEmpty('Välj kategori'),
      subCategory: isNotEmpty('Välj underkategori'),
      status: isNotEmpty('Välj status'),
    },
  });

  useEffect(() => {
    if (sentence) {
      const updatedMonths = monthsList.slice(
        sentence.fromMonth - 1,
        sentence.toMonth
      );

      monthsList.map((month) => {
        if (updatedMonths.filter((obj) => obj.name === month.name).length > 0) {
          month.selected = true;
        } else {
          month.selected = false;
        }
      });

      const ageMinIndex = ageList.findIndex(
        (obj) => obj.minAge === sentence.minAge
      );
      const ageMaxIndex = ageList.findIndex(
        (obj) => obj.maxAge === sentence.maxAge
      );
      const updatedAge = ageList.slice(ageMinIndex, ageMaxIndex + 1);

      ageList.map((age) => {
        if (updatedAge.filter((obj) => obj.name === age.name).length > 0) {
          age.selected = true;
        } else {
          age.selected = false;
        }
      });

      const sexIndex = sexList.findIndex((obj) => obj.value === sentence.sex);

      sexList.map((sex) => {
        sex.selected = false;
      });

      sexList[sexIndex].selected = true;
    }
  }, []);

  useEffect(() => {
    if (form.values.mainCategory.length > 0) {
      form.setFieldValue(
        'subCategory',
        firstRun && sentence != undefined
          ? sentence.formHierarchy?.formQuestionId
          : ''
      );
      setFirstRun(false);

      const mainCategory: any = forms.find((obj: Category) => {
        return obj.id === form.getInputProps('mainCategory').value;
      });

      const subCategoryNames: any = [];

      mainCategory.sections.map((section: any) => {
        section.questions.forEach((question: any) =>
          subCategoryNames.push({ label: question.name, value: question.id })
        );
      });

      setSubCategories(subCategoryNames);
    }
  }, [form.values.mainCategory]);

  useEffect(() => {
    if (form.values.text.length > 0) {
      const timer = setTimeout(() => {
        submit(
          { sentence: form.values.text },
          {
            method: 'post',
          }
        );
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [form.values.text]);

  const ownReasonValues = Object.values(PlacementOwnReasons);

  const ownReasonsList = ownReasonValues.map((reason) => ({
    name: placementReasonsTranslation[reason],
    value: reason,
    selected: sentence
      ? sentence?.ownReasons.includes(reason)
        ? true
        : false
      : true,
  }));

  const experiencedReasonsValues = Object.values(PlacementExperiencedReasons);

  const experiencedReasonsList = experiencedReasonsValues.map((reason) => ({
    name: placementReasonsTranslation[reason],
    value: reason,
    selected: sentence
      ? sentence?.experiencedReasons.includes(reason)
        ? true
        : false
      : true,
  }));

  const mainCategories = forms.map((category: Category) => {
    return { label: category.name, value: category.id };
  });

  const [firstRun, setFirstRun] = useState(true);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [period, setPeriod] = useState(
    !sentence || (sentence?.fromMonth === 1 && sentence?.toMonth === 12)
      ? 'all'
      : 'specific'
  );
  const [subCategories, setSubCategories] = useState([]);
  const [months, setMonths] = useState(monthsList);
  const [age, setAge] = useState(ageList);
  const [sex, setSex] = useState(sexList);
  const [ownReasons, setOwnReasons] = useState(ownReasonsList);
  const [experiencedReasons, setExperiencedReasons] = useState(
    experiencedReasonsList
  );

  const handleMonths = (event: any) => {
    const newList = months.slice();

    const monthName = event.currentTarget.value;

    const index = newList.findIndex((obj) => obj.name === monthName);
    const updatedObject = Object.assign({}, newList[index]);
    updatedObject.selected = !updatedObject.selected;
    newList[index] = updatedObject;

    if (newList.filter((obj) => obj.selected === true).length > 1) {
      const indexFirst = newList.findIndex((obj) => obj.selected === true);
      let indexLast = 0;

      newList.map((month, index) => {
        if (month.selected === true) {
          indexLast = index;
        }
      });

      if (index > indexFirst && newList[index].selected === false) {
        indexLast = index;
      }

      const updatedMonths = newList.slice(indexFirst, indexLast + 1);

      newList.map((month) => {
        if (updatedMonths.filter((obj) => obj.name === month.name).length > 0) {
          month.selected = true;
        } else {
          month.selected = false;
        }
      });
    }

    setMonths(newList);
  };

  const handleAge = (event: any) => {
    const newList = age.slice();

    const ageName = event.currentTarget.value;

    const index = newList.findIndex((obj) => obj.name === ageName);
    const updatedObject = Object.assign({}, newList[index]);
    updatedObject.selected = !updatedObject.selected;
    newList[index] = updatedObject;

    if (newList.filter((obj) => obj.selected === true).length > 1) {
      const indexFirst = newList.findIndex((obj) => obj.selected === true);
      let indexLast = 0;

      newList.map((age, index) => {
        if (age.selected === true) {
          indexLast = index;
        }
      });

      if (index > indexFirst && newList[index].selected === false) {
        indexLast = index;
      }

      const updatedAge = newList.slice(indexFirst, indexLast + 1);

      newList.map((age) => {
        if (updatedAge.filter((obj) => obj.name === age.name).length > 0) {
          age.selected = true;
        } else {
          age.selected = false;
        }
      });
    }

    setAge(newList);
  };

  const handleOwnReasons = (event: any) => {
    const ownReasonName = event.currentTarget.value;

    const index = ownReasons.findIndex((obj) => obj.value === ownReasonName);
    const updatedObject = Object.assign({}, ownReasons[index]);
    updatedObject.selected = !updatedObject.selected;

    const newList = ownReasons.slice();
    newList[index] = updatedObject;

    setOwnReasons(newList);
  };

  const handleExperiencedReasons = (event: any) => {
    const experiencedReasonName = event.currentTarget.value;

    const index = experiencedReasons.findIndex(
      (obj) => obj.value === experiencedReasonName
    );
    const updatedObject = Object.assign({}, experiencedReasons[index]);
    updatedObject.selected = !updatedObject.selected;

    const newList = experiencedReasons.slice();
    newList[index] = updatedObject;

    setExperiencedReasons(newList);
  };

  const handleSex = (event: any) => {
    const sexName = event.currentTarget.value;

    const index = sex.findIndex((obj) => obj.name === sexName);
    const updatedObject = Object.assign({}, sex[index]);
    updatedObject.selected = true;

    const newList = sex.slice();
    newList.map((sex) => {
      sex.selected = false;
    });

    newList[index] = updatedObject;

    setSex(newList);
  };

  const handleTags = (event: any) => {
    const tagCode = event.currentTarget.value;
    form.setFieldValue('text', form.values.text + tagCode);
  };

  const saveSentence = () => {
    const formHasErrors = form.validate().hasErrors;

    if (!formHasErrors) {
      let fromMonthIndex = 0;
      let toMonthIndex = 0;

      if (period === 'all') {
        fromMonthIndex = 1;
        toMonthIndex = 12;
      } else {
        fromMonthIndex = months.findIndex((obj) => obj.selected === true) + 1;

        months.map((month, index) => {
          if (month.selected === true) {
            toMonthIndex = index + 1;
          }
        });
      }

      let minAge = 0;
      let maxAge = 0;

      const selectedAges = age.filter((obj) => obj.selected === true);

      selectedAges.map((age, index) => {
        if (index === 0) {
          minAge = age.minAge;
        }
        minAge = age.minAge < minAge ? age.minAge : minAge;
        maxAge = age.maxAge > maxAge ? age.maxAge : maxAge;
      });

      const selectedOwnReasons = ownReasons
        .filter((obj) => obj.selected === true)
        .map((reason) => {
          return reason.value;
        });

      const selectedExperiencedReasons = experiencedReasons
        .filter((obj) => obj.selected === true)
        .map((reason) => {
          return reason.value;
        });

      const selectedSex = sex.filter((obj) => obj.selected === true);

      const data: CreateSentenceFormData = {
        questionResponse: form.getInputProps('status').value,
        minAge: minAge,
        maxAge: maxAge,
        ownReasons: selectedOwnReasons,
        experiencedReasons: selectedExperiencedReasons,
        sex: selectedSex[0]?.value,
        fromMonth: fromMonthIndex,
        toMonth: toMonthIndex,
        text: form.values.text,
      };

      const formData = createFormData(data);

      const formQuestionId = form.values.subCategory;

      if (sentence) {
        submit(formData, {
          method: 'post',
          action: `/sentences/${sentence.id}/update`,
        });
      } else {
        submit(formData, {
          method: 'post',
          action: `/sentences/create/save/${formQuestionId}`,
        });

        form.setFieldValue('text', '');

        setShowSaveMessage(true);

        setTimeout(() => {
          setShowSaveMessage(false);
        }, 5000);
      }
    }
  };

  return (
    <PageWrapper>
      <CreateSentenceWrapper>
        <Form onSubmit={form.onSubmit(saveSentence)}>
          <Title as="h1" type="secondary" weight="500">
            <div style={{ marginLeft: '.25rem' }}>
              {sentence ? 'Ändra mening' : 'Skapa ny mening'}
            </div>
          </Title>

          <Spacer minSpace="0.75rem" />

          <CategorySection
            mainCategories={mainCategories}
            subCategories={subCategories}
            statusList={statusList}
            form={form}
          />

          <PeriodSection
            period={period}
            months={months}
            setPeriod={setPeriod}
            handleMonths={handleMonths}
          />

          <StyledSectionWrapper>
            <AgeSection age={age} handleAge={handleAge} />
            <ReasonsSection
              ownReasons={ownReasons}
              handleOwnReasons={handleOwnReasons}
              experiencedReasons={experiencedReasons}
              handleExperiencedReasons={handleExperiencedReasons}
            />
          </StyledSectionWrapper>

          <SexSection sex={sex} handleSex={handleSex} />

          <SentenceSection
            tagsList={tagsList}
            handleTagsForm={handleTags}
            previewSentence={previewSentence}
            form={form}
          />
          <StyledTransparentSection>
            <StyledButtonWrapper>
              <Dialog
                opened={showSaveMessage}
                size="sm"
                radius="sm"
                style={{ backgroundColor: COLORS.hdAccentGreen }}
              >
                <Text color={COLORS.textPrimary} size={'14px'}>
                  Meningen har lagts till
                </Text>
              </Dialog>
              <CancelButton
                variant="subtle"
                color="dark"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                Avbryt
              </CancelButton>
              <PrimaryButton
                type="submit"
                background={COLORS.hdAccentGreen}
                hover={COLORS.hdAccentGreenDark}
              >
                {sentence ? 'Spara' : 'Lägg till'}
              </PrimaryButton>
            </StyledButtonWrapper>
          </StyledTransparentSection>
        </Form>
      </CreateSentenceWrapper>
    </PageWrapper>
  );
};
