import { Button } from '@mantine/core';
import { StyledButtonGroup } from './styles';
import { BookOpen } from 'react-feather';
import { COLORS } from '../../../../constants';

interface OverlayButtonProps {
  onClick: () => void;
  overlayOpened: boolean;
}

export interface StyledButtonGroupProps {
  overlayOpened: boolean;
}

export const OverlayButton = ({
  onClick,
  overlayOpened,
}: OverlayButtonProps) => {
  return (
    <StyledButtonGroup overlayOpened={overlayOpened}>
      <Button
        onClick={onClick}
        sx={{
          borderRadius: '20px 3px 3px 20px',
          maxWidth: '62px',
          backgroundColor: COLORS.hdBackgroundGreenDark,
          ':hover': {
            backgroundColor: COLORS.hdBackgroundGreenDarker,
          },
        }}
      >
        <BookOpen />
      </Button>
    </StyledButtonGroup>
  );
};
