import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
export const StyledInvoiceButton = styled(Button)`
  transform: 'none !important';
  padding: 0;
  & span {
    font-size: 0.875rem;
  }
`;

export const InvoiceButton = createPolymorphicComponent<'button', ButtonProps>(
  StyledInvoiceButton
);
