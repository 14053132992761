import { redirect } from 'react-router-dom';
import { AuthToken, FamilyHome } from '../../types';
import { errorHandler } from '../../utils';

export const updateFamilyHome = async (
  data: any,
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/FamilyHomes/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while updating family home with id: ${id}`,
        error
      );
      return error;
    });

export const updateFamilyHomeAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;
    const parsedRps = JSON.parse(data.relatedPersons);

    const res = await updateFamilyHome(
      {
        ...data,
        relatedPersons: parsedRps,
        multiplePlacement: data.multiplePlacement === 'true' ? true : false,
      },
      params.familyHomeId,
      token
    );

    if (!res.ok) {
      throw await errorHandler(res);
    }

    let home: FamilyHome;

    try {
      home = await res.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return redirect(`/family-homes/${home.id}`);
  };
