import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const StyledProfileInformation = styled.div`
  text-align: left;
`;

export const StyledProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
  background-color: ${COLORS.hdBackgroundWhite};
  padding: 5rem;

  h4 {
    color: #6b778c;
    margin-bottom: 0.25rem;
  }
`;
