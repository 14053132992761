import styled from '@emotion/styled';
import { Info, Lock } from 'react-feather';
import { COLORS } from '../../constants';
import { CustomTooltip } from '../CustomTooltip';
import { useViewportSize } from '@mantine/hooks';
import { useState } from 'react';

interface QuestionInfoIconProps {
  hoverText?: string;
  children?: React.ReactNode;
}

const QuestionInfoIconWrapper = styled.div<QuestionInfoIconProps>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100%;

  .lock-icon-container {
    position: relative;
    flex-shrink: 0;

    .lock-icon-wrapper {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-80%, -30%);
      display: none;
    }

    .lock-icon-wrapper.clicked {
      display: block;

      rect {
        fill: ${COLORS.evaluationBlack};
      }
    }
  }

  .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &:hover svg {
      circle {
        fill: ${COLORS.hdAccentBlue};
        stroke: ${COLORS.hdAccentBlue};
      }

      line {
        stroke: white;
      }
    }

    &.clicked svg {
      circle {
        fill: ${COLORS.hdAccentBlue};
        stroke: ${COLORS.hdAccentBlue};
        opacity: 0.5;
      }

      line {
        stroke: white;
      }
    }
  }
`;

export const QuestionInfoIcon = ({
  hoverText,
  children,
}: QuestionInfoIconProps) => {
  const { width } = useViewportSize();

  const widthBreakpoint = 1200;

  const [clicked, setClicked] = useState(false);

  const handleTouch = (e: React.TouchEvent<SVGAElement>) => {
    e.preventDefault();
    setClicked(true);
  };

  const handleFocus = () => {
    setClicked(true);
  };

  const handleBlur = () => {
    setClicked(false);
  };

  return (
    <QuestionInfoIconWrapper>
      <div
        className={(hoverText ? 'info-icon ' : '') + (clicked ? 'clicked' : '')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        tabIndex={0}
      >
        {hoverText && (
          <CustomTooltip
            hoverText={hoverText}
            cssLeft={width < widthBreakpoint ? '600%' : '880%'}
            width={width < widthBreakpoint ? '350px' : '500px'}
            maxHeight={width < widthBreakpoint ? '250px' : '300px'}
            cssBottom="110%"
            display="flex"
            clicked={clicked}
          >
            <Info
              color={COLORS.hdAccentBlue}
              height={'28px'}
              width={'28px'}
              onTouchStart={handleTouch}
            ></Info>
          </CustomTooltip>
        )}
        <div className="lock-icon-container">
          <div className={'lock-icon-wrapper ' + (clicked ? 'clicked' : '')}>
            <Lock
              height={'20px'}
              width={'20px'}
              color={COLORS.evaluationBlack}
            />
          </div>
        </div>
      </div>
      {children && <div className="title-children">{children}</div>}
    </QuestionInfoIconWrapper>
  );
};
