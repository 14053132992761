import { RouteObject } from 'react-router-dom';
import { Profile, ErrorPage, ProfileEdit } from '../../pages';
import { updateAccountAction, updatePasswordAction } from './actions';
import { profileLoader } from './loaders';

export const profileRoutes = (token: string | null = null): RouteObject[] => [
  {
    path: 'profile',
    element: <Profile />,
    errorElement: <ErrorPage />,
    loader: profileLoader({ token }),
    action: updatePasswordAction({ token }),
  },
  {
    path: 'profile/edit',
    element: <ProfileEdit />,
    errorElement: <ErrorPage />,
    loader: profileLoader({ token }),
    action: updatePasswordAction({ token }),
  },
  {
    path: 'profile/update-account',
    errorElement: <ErrorPage />,
    action: updateAccountAction({ token }),
  },
];
