import styled from '@emotion/styled';
import { Group } from '@mantine/core';
import { COLORS } from '../../../../constants';
import { StyledButtonGroupProps } from '.';

export const StyledButtonGroup = styled(Group)<StyledButtonGroupProps>`
  background: ${COLORS.hdBackgroundGreenDark};
  position: fixed;
  right: 0;
  bottom: ${(props) => (props.overlayOpened ? '21rem' : '5.5rem')};
  z-index: 100;
  border-radius: 20px;
  transition: bottom 0.3s ease;
`;
