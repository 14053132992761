import { Button } from '@mantine/core';
import styled from '@emotion/styled';
import { CheckButtonProps } from '.';

export const StyledCheckButton = styled(Button)<CheckButtonProps>`
  background-color: ${(props) =>
    props.selected && props.background === 'secondary'
      ? '#5887d7'
      : props.selected
      ? '#9ba3b1'
      : props.background === 'secondary'
      ? '#bfd1ef'
      : '#fff'};

  width: ${(props) =>
    props.width === 'full'
      ? '100%'
      : props.width === 'small'
      ? '84px'
      : 'auto'};

  border: 1px solid
    ${(props) =>
      !props.selected && props.background !== 'secondary'
        ? '#42526e'
        : 'transparent'};

  color: ${(props) => (props.selected ? '#fff' : '#42526e')};

  margin: 0.25rem 0.5rem 0.25rem 0;
  height: 32px;
  border-radius: 3px;
  padding: 6px 12px;
  font-size: 14px;

  & > div {
    justify-content: ${(props) =>
      props.textalign === 'left' ? 'left' : 'center'};
  }

  &:hover {
    background-color: ${(props) =>
      props.selected && props.background === 'secondary'
        ? '#5887d7'
        : props.selected
        ? '#9ba3b1'
        : props.background === 'secondary'
        ? '#bfd1ef'
        : '#fff'};
  }
`;
