import styled from '@emotion/styled';
import { COLORS } from '../../../../constants';

export const QuestionWrapper = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  row-gap: 0.5rem;
  align-items: flex-start;
  padding: 16px 10px 20px;
  width: 100%;
  gap: 12px;
  background: ${COLORS.hdBackgroundWhite};
  box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  margin: 1fr 0;
`;
