import { getAccount } from '../../api';
import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const profileLoader =
  ({ token }: AuthToken) =>
  async () => {
    const res = await getAccount(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
