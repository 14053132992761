import styled from '@emotion/styled';
import { COLORS } from '../../../../constants';

export const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 15rem;
  min-width: 10rem;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: ${COLORS.hdBackgroundGreenDark};

  @media (max-width: 820px) {
    width: 100%;
  }
`;
