import styled from '@emotion/styled';
import { Select } from '@mantine/core';
import { COLORS } from '../../constants';
import { Textarea } from '@mantine/core';

export const StyledSelect = styled(Select)`
  margin-left: 0.5rem;
  width: 500px;
  input {
    background-color: ${COLORS.hdBackgroundGreyDark};
    color: ${COLORS.textSecondary};
    border: none;

    &:hover {
      cursor: pointer;
    }
    &::placeholder {
      color: ${COLORS.textSecondary};
    }
  }
`;

export const CreateDescriptionWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 830px;
`;

export const StyledSection = styled.div`
  background-color: ${COLORS.hdBackgroundGreyLight};
  margin: 0.75rem 0.25rem;
  padding: 1rem;
  flex: 1 1 100%;
  border-radius: 3px;
`;

export const StyledTransparentSection = styled.div`
  margin: 1.5rem 0.25rem 0.75rem 0.25rem;
  flex: 1 1 100%;
  border-radius: 3px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const StyledTextarea = styled(Textarea)`
  textarea {
    background-color: #fafbfc;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 1rem 0.75rem;
    border: 2px solid #dfe1e6;
    border-radius: 3px;
  }
`;
