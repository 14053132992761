import { useEffect } from 'react';
import { useActionData, useNavigate, useSubmit } from 'react-router-dom';

import { PageWrapper, ConsultantForm } from '../../components';
import { Consultant, CreateConsultantFormData } from '../../types';
import { createFormData } from '../../utils';

export const ConsultantCreate = () => {
  const data = useActionData() as Consultant;
  const navigate = useNavigate();
  const submit = useSubmit();

  useEffect(() => {
    data &&
      data.id &&
      data.password &&
      navigate(`/consultants/${data?.id}`, { state: data });
  }, [data, navigate]);

  const onSubmit = (data: CreateConsultantFormData) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'post',
    });
  };

  return (
    <PageWrapper>
      <ConsultantForm onSubmit={onSubmit} />
    </PageWrapper>
  );
};
