import styled from '@emotion/styled';
import { COLORS } from '../../../../../../constants';

export const StyledBrandBanner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledLogoutWrapper = styled.div`
  align-self: center;
  margin-bottom: 5rem;
`;

export const LoggedInAsText = styled.div`
  color: ${COLORS.hdAccentGreenLight};
  font-size: 12px;
`;
