import styled from '@emotion/styled';
import { COLORS } from '../../constants';

export const StyledInfoBox = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : COLORS.hdBackgroundGrey};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 16px;
  margin-bottom: 0.5rem;
`;

export const StyledNumber = styled.div`
  width: 55px;
  height: 20px;
  color: ${COLORS.hdAccentBlue};
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 20px;
`;
