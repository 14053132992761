import { Form, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mantine/dates';
import { Group, NumberInput, Select } from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';

import { COLORS } from '../../constants';
import { FamilyRelation, RelatedPerson } from '../../types';
import { getFamilyRelationText } from '../../utils';
import { MobileFormContentWrapper } from './styles';
import { CancelButton, PrimaryButton, Spacer, Text, FormWrapper } from '..';

import dayjs from 'dayjs';

import 'dayjs/locale/sv';
import { Calendar } from 'react-feather';
import { useViewportSize } from '@mantine/hooks';
import { useState } from 'react';
dayjs.locale('sv');

interface ContactFormProps {
  onSubmit: (data: any) => void;
  contactOptions?: RelatedPerson[];
  yearMonth: string;
}

export const ContactForm = ({
  onSubmit,
  contactOptions,
  yearMonth,
}: ContactFormProps) => {
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const form = useForm<{
    contact: string;
    contactType: string;
    date: Date | null;
    minutes: number | null;
  }>({
    validateInputOnBlur: ['contact', 'contactType', 'minutes'],
    initialValues: {
      contact: '',
      contactType: '',
      date: null,
      minutes: null,
    },

    validate: {
      contact: isNotEmpty('Ange vem du haft kontakt med'),
      contactType: isNotEmpty('Ange typen av kontakt'),
      date: isNotEmpty('Ange när kontakten skedde'),
      minutes: isNotEmpty('Ange hur länge ni haft kontakt (i minuter)'),
    },
  });

  const [key, setKey] = useState(0);

  const resetForm = () => {
    form.reset();
    setKey((prevKey) => prevKey + 1); // Increment key to force re-render
  };

  const formattedContacts = contactOptions?.map((person) => ({
    value: person.relation,
    label: `${person.firstName} ${person.lastName}`,
  }));

  const baseOptions = [
    {
      value: 'Placedchild',
      label: 'Placerat barn',
    },
    {
      value: 'Trustee',
      label: 'God man',
    },
    {
      value: 'FamilyHome',
      label: 'Familjehemmet',
    },
  ];

  const defaultOptions = [
    {
      value: 'FamilyHome',
      label: getFamilyRelationText(FamilyRelation['FamilyHome']),
    },
    {
      value: 'FamilyHomeMother',
      label: getFamilyRelationText(FamilyRelation['FamilyHomeMother']),
    },
    {
      value: 'FamilyHomeFather',
      label: getFamilyRelationText(FamilyRelation['FamilyHomeFather']),
    },
    {
      value: 'FamilyHomeThey',
      label: getFamilyRelationText(FamilyRelation['FamilyHomeThey']),
    },
  ];

  const options = formattedContacts
    ? [...formattedContacts, ...baseOptions]
    : [...defaultOptions, ...baseOptions];

  if (isMobile) {
    return (
      <FormWrapper scrollable>
        <Form method="post">
          <Text size="23px">Registrera ny kontakt</Text>
          <Spacer minSpace="1.5rem" />
          <MobileFormContentWrapper>
            <>
              <Select
                label="Kontakt med"
                placeholder="Vem hade du kontakt med?"
                name="contact"
                {...form.getInputProps(`contact`)}
                value={form.getInputProps(`contact`).value}
                data={options}
              />

              <Spacer maxSpace="1rem" />

              <Select
                label="Typ av kontakt"
                placeholder="Vilken form av kontakt var det?"
                name="contactType"
                {...form.getInputProps(`contactType`)}
                value={form.getInputProps(`contactType`).value}
                data={[
                  {
                    value: 'Phone',
                    label: 'Telefonsamtal',
                  },
                  {
                    value: 'Visit',
                    label: 'Besök',
                  },
                  {
                    value: 'SocialService',
                    label: 'Besök från socialtjänst',
                  },
                ]}
              />

              <Spacer maxSpace="1rem" />

              <DatePicker
                name="date"
                {...form.getInputProps(`date`)}
                locale="sv"
                label="Datum"
                placeholder="Vilket datum skedde kontakten?"
                inputFormat="YYYY/MM/DD"
                labelFormat="YYYY/MM"
                initialMonth={dayjs(yearMonth, 'YYYYMM').toDate()}
                icon={<Calendar size={16} />}
              />

              <Spacer maxSpace="1rem" />

              <NumberInput
                key={key}
                name="minutes"
                {...form.getInputProps(`minutes`)}
                label="Tid"
                placeholder="Ange tid i minuter."
                hideControls
              />

              <Spacer />

              <Group
                position="center"
                spacing="xs"
                sx={{ marginTop: '1.5rem' }}
              >
                <PrimaryButton
                  fullWidth
                  onClick={() => {
                    const formHasErrors = form.validate().hasErrors;

                    if (!formHasErrors) {
                      onSubmit({
                        ...form.values,
                        date: form.values.date
                          ? dayjs(form.values.date).format('YYYY-MM-DD')
                          : '',
                      });

                      resetForm();
                    }
                  }}
                  background={COLORS.hdAccentGreen}
                  hover={COLORS.hdAccentGreenDark}
                >
                  Spara
                </PrimaryButton>
                <CancelButton
                  fullWidth
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                />
                <Spacer maxSpace="1rem" />
              </Group>
            </>
          </MobileFormContentWrapper>
        </Form>
      </FormWrapper>
    );
  }

  return (
    <FormWrapper widthCss="100%" paddingCss="2.5rem 0 0" justifyContent="start">
      <Form method="post">
        <Text size="23px">Registrera ny kontakt</Text>
        <Spacer minSpace="1.5rem" />
        <>
          <Select
            label="Kontakt med"
            placeholder="Vem hade du kontakt med?"
            name="contact"
            {...form.getInputProps(`contact`)}
            value={form.getInputProps(`contact`).value}
            data={options}
          />

          <Spacer maxSpace="1rem" />

          <Select
            label="Typ av kontakt"
            placeholder="Vilken form av kontakt var det?"
            name="contactType"
            {...form.getInputProps(`contactType`)}
            value={form.getInputProps(`contactType`).value}
            data={[
              {
                value: 'Phone',
                label: 'Telefonsamtal',
              },
              {
                value: 'Visit',
                label: 'Besök',
              },
              {
                value: 'SocialService',
                label: 'Besök från socialtjänst',
              },
            ]}
          />

          <Spacer maxSpace="1rem" />

          <DatePicker
            name="date"
            {...form.getInputProps(`date`)}
            locale="sv"
            label="Datum"
            placeholder="Vilket datum skedde kontakten?"
            inputFormat="YYYY/MM/DD"
            labelFormat="YYYY/MM"
            initialMonth={dayjs(yearMonth, 'YYYYMM').toDate()}
            icon={<Calendar size={16} />}
          />

          <Spacer maxSpace="1rem" />

          <NumberInput
            key={key}
            name="minutes"
            {...form.getInputProps(`minutes`)}
            label="Tid"
            placeholder="Ange tid i minuter."
            hideControls
          />

          <Spacer />

          <Group position="center" spacing="xs" sx={{ marginTop: '1.5rem' }}>
            <PrimaryButton
              fullWidth
              onClick={() => {
                const formHasErrors = form.validate().hasErrors;

                if (!formHasErrors) {
                  onSubmit({
                    ...form.values,
                    date: form.values.date
                      ? dayjs(form.values.date).format('YYYY-MM-DD')
                      : '',
                  });

                  resetForm();
                }
              }}
              background={COLORS.hdAccentGreen}
              hover={COLORS.hdAccentGreenDark}
            >
              Spara
            </PrimaryButton>
            <CancelButton
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            />
          </Group>
        </>
      </Form>
    </FormWrapper>
  );
};
