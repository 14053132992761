import { TableLink, TableMailLink } from '../../components';
import { TableRow, FamilyHome } from '../../types';

export const formatFamilyHomeRows = (
  data: FamilyHome[],
  onRowClick: (id: string) => void
): TableRow[] =>
  data.map((familyHome) => {
    const primaryContact = familyHome.relatedPersons.find(
      (person) => person.primaryContact === true
    );

    return {
      id: familyHome.id,
      onClick: () => onRowClick(familyHome.id),
      cells: [
        {
          name: 'familyHome',
          label: 'Familjehem',
          isSortable: true,
          value: familyHome.name,
          content: `${familyHome.name}, ${familyHome.city}`,
        },
        {
          name: 'contact',
          label: 'Primär kontaktperson',
          isSortable: true,
          value: `${primaryContact?.firstName}`, //sort on first name since last name can be null
          content: `${primaryContact?.firstName} ${primaryContact?.lastName}`,
        },
        {
          name: 'email',
          label: 'E-postadress',
          isSortable: true,
          value: primaryContact?.email,
          content: primaryContact?.email,
        },
        {
          name: 'phone',
          label: 'Telefonnummer',
          isSortable: true,
          value: familyHome.phoneNumber,
          content: familyHome.phoneNumber,
        },
      ],
    };
  });
