import styled from '@emotion/styled';
import { PasswordInput, TextInput } from '@mantine/core';
import { Form } from 'react-router-dom';

import { COLORS } from '../../constants';

export const StyledLoginPageWrapper = styled.div`
  min-width: 100%;
  overflow: auto;
  background-color: ${COLORS.textPrimary};

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding: 5rem 10rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 5rem 15rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 5rem 25rem;
  }
`;

export const StyledLoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-height: 500px;

  width: 100%;
  height: 100%;
  background-color: ${COLORS.hdBackgroundGreenLight};
  padding: 1rem;

  @media screen and (min-width: 768px) {
    padding: 0 3rem;
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 6rem;
`;

export const LoginTextInput = styled(TextInput)`
  margin-bottom: 2rem;

  label {
    font-weight: 700;
  }
`;

export const LoginPasswordInput = styled(PasswordInput)`
  margin-bottom: 2rem;

  label {
    font-weight: 700;
  }
`;

export const StyledDesktopBackground = styled.div`
  background-color: ${COLORS.textPrimary};
`;

export const StyledLoginError = styled.div`
  background-color: ${COLORS.hdBackgroundRed};
  color: ${COLORS.hdAccentRed};
  border: solid 2px ${COLORS.hdAccentRed};
  padding: 0.5rem;
  margin-bottom: 1rem;
  transition: width, 1s;
`;
