import styled from '@emotion/styled';
import { COLORS } from '../../../../constants';

export const StyledOverlay = styled.div`
  background: ${COLORS.hdBackgroundGreenDark};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 3rem;
  z-index: 99;
  width: 100%;
  height: 20.5rem;
  color: ${COLORS.textPrimary};
`;
