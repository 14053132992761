import { redirect } from 'react-router-dom';
import {
  createTenant,
  deleteTenant,
  disableTenant,
  invoiceMonth,
  updateTenant,
} from '../../api';
import { AuthToken, CreateTenantFormData, Tenant } from '../../types';
import { errorHandler } from '../../utils';

export const lockTenantAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await disableTenant(true, params.tenantId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/tenants`);
  };

export const unlockTenantAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await disableTenant(false, params.tenantId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/tenants`);
  };

export const createTenantAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as CreateTenantFormData;

    const res = await createTenant({ ...data, disabled: false }, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }
    return res;
  };

export const updateTenantAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as CreateTenantFormData;

    const res = await updateTenant({ ...data }, params.tenantId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    let tenant: Tenant;

    try {
      tenant = await res.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return redirect(`/tenants/${tenant.id}`);
  };

export const deleteTenantAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await deleteTenant(params.tenantId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/tenants`);
  };

export const invoiceTenantAction =
  ({ token }: AuthToken) =>
  async ({ params, request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;
    const res = await invoiceMonth(token, params.tenantId, data);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/tenants/${params.tenantId}`);
  };
