import { RouteObject } from 'react-router-dom';
import { Sentences, SentenceCreate, ErrorPage } from '../../pages';
import {
  createDescriptionAction,
  deleteDescriptionAction,
  getDescriptionSearchAction,
  updateDescriptionAction,
  getDescriptionByIdAction,
  getDescriptionsAction,
} from './actions';
import { descriptionEditLoader, descriptionLoader } from './loaders';
import { Descriptions } from '../../pages/Descriptions';

export const descriptionRoutes = (
  token: string | null = null
): RouteObject[] => [
  {
    path: 'descriptions',
    element: <Descriptions />,
    errorElement: <ErrorPage />,
    loader: descriptionLoader({ token }),
    action: getDescriptionsAction({ token }),
  },
  {
    path: 'descriptions/create/save/:formQuestionId',
    element: <Descriptions />,
    errorElement: <ErrorPage />,
    action: createDescriptionAction({ token }),
  },
  {
    path: 'descriptions/:descriptionId/update',
    element: <Descriptions />,
    errorElement: <ErrorPage />,
    action: updateDescriptionAction({ token }),
  },
];
