import { useViewportSize } from '@mantine/hooks';
import { Spacer } from '../../Spacer';
import { DatePicker } from '../DatePicker';
import { GreenRed } from '../GreenRed';

interface DateGreenRedProps {
  dateValue?: string | null;
  greenRedValue?: string;
  onChangeDate: (value: Date) => void;
  onChangeGreenRed: (value: string) => void;
}

export const DateGreenRed = ({
  dateValue,
  greenRedValue,
  onChangeDate,
  onChangeGreenRed,
}: DateGreenRedProps) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;
  return (
    <div
      style={
        isMobile
          ? {
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignSelf: 'center',
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignSelf: 'center',
            }
      }
    >
      <DatePicker value={dateValue} onChange={onChangeDate} />
      <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
      <GreenRed value={greenRedValue} onChange={onChangeGreenRed} />
    </div>
  );
};
