import styled from '@emotion/styled';

import { COLORS } from '../../constants';

export const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 100%;
  background-color: ${COLORS.hdBackgroundGreenLight};
  padding: 3rem 8rem 3rem;
  overflow: auto;

  @media (max-width: 1440px) {
    padding: 3rem 6rem 3rem;
  }

  @media (max-width: 1244px) {
    padding: 3rem 3rem 2rem;
  }

  @media (max-width: 960px) {
    padding: 2rem 2rem 1rem;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;
