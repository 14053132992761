import {
  getFamilyHomes,
  getPlacementById,
  getPlacementResponsibility,
  getPlacements,
  getPlacementConsultanttimes,
} from '../../api';
import { AuthToken } from '../../types';
import { json } from 'react-router-dom';
import { getReportsByPlacementId } from '../../api/getReportsByPlacementId';
import { errorHandler } from '../../utils';
import { getNotesSearchByPlacement } from '../../api/notes/getNotesSearchByPlacement';

export const placementsLoader =
  ({ token }: AuthToken) =>
  async () => {
    const res = await getPlacements(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };

export const placementDetailLoader =
  ({ token, isAdmin }: { token: string | null; isAdmin: boolean }) =>
  async ({ params }: any) => {
    const placementRes = await getPlacementById(params.placementId, token);
    const reportsRes = await getReportsByPlacementId(params.placementId, token);
    const familyHomesRes = await getFamilyHomes(token);
    const formattedConsultantTimesRes = await getPlacementConsultanttimes(
      params.placementId,
      token
    );
    let consultantsRes;

    if (!placementRes.ok) {
      throw await errorHandler(placementRes);
    }

    //temporary comment until no reports returns something else besides 500
    // if (!reportsRes.ok) {
    //   throw await errorHandler(reportsRes);
    // }

    if (!familyHomesRes.ok) {
      throw await errorHandler(familyHomesRes);
    }

    if (isAdmin) {
      consultantsRes = await getPlacementResponsibility(
        params.placementId,
        token
      );

      if (!consultantsRes.ok) {
        throw await errorHandler(consultantsRes);
      }
    }

    const placementNotesRes = await getNotesSearchByPlacement(
      params.placementId,
      token
    );
    if (!placementNotesRes.ok) {
      throw await errorHandler(placementNotesRes);
    }

    let placementNotes;

    try {
      placementNotes = await placementNotesRes.json();
    } catch (error) {
      throw new Error('placementDetailLoader, Failed parsing json');
    }

    const placement = await placementRes.json();
    const reports = await reportsRes.json();
    const familyHomes = await familyHomesRes.json();
    const consultants = await consultantsRes?.json();
    const formattedConsultantTimes = await formattedConsultantTimesRes.json();

    return json(
      {
        placement,
        reports,
        consultants,
        familyHomes,
        formattedConsultantTimes,
        placementNotes,
      },
      { status: 200 }
    );
  };

export const placementCreateLoader =
  ({ token }: AuthToken) =>
  async () => {
    const familyHomesRes = await getFamilyHomes(token);

    if (!familyHomesRes.ok) {
      throw await errorHandler(familyHomesRes);
    }

    return familyHomesRes;
  };

export const placementUpdateLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const placementRes = await getPlacementById(params.placementId, token);

    if (!placementRes.ok) {
      throw await errorHandler(placementRes);
    }

    return placementRes;
  };
