import styled from '@emotion/styled';
import DOMPurify from 'dompurify';

const TooltipContainer = styled.div<CustomTooltipProps>`
  position: relative;
  display: ${(props) => (props.display ? props.display : 'inline-block')};
  align-items: center;
  justify-content: center;

  &:hover span,
  &.clicked span {
    visibility: visible;
    opacity: 1;
  }
`;

interface CustomTooltipProps {
  hoverText?: string;
  children?: React.ReactNode;
  cssBottom?: string;
  cssLeft?: string;
  width?: string;
  maxHeight?: string;
  display?: string;
  clicked?: boolean;
}

const TooltipText = styled.span<CustomTooltipProps>`
  visibility: hidden;
  width: ${(props) => (props.width ? props.width : '500px')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '150px')};
  overflow-y: auto;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: ${(props) => (props.cssBottom ? props.cssBottom : '1150%')};
  left: ${(props) => (props.cssLeft ? props.cssLeft : '100%')};
  transform: translateX(-50%);
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
  opacity: 0;
  font-size: 16px;
  text-align: start;
  white-space: pre-wrap;
`;

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  hoverText,
  children,
  cssBottom,
  cssLeft,
  width,
  maxHeight,
  display,
  clicked,
}) => {
  const createMarkup = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  return (
    <TooltipContainer display={display} className={clicked ? 'clicked' : ''}>
      {children}
      {hoverText && (
        <TooltipText
          cssBottom={cssBottom}
          cssLeft={cssLeft}
          width={width}
          maxHeight={maxHeight}
        >
          <div dangerouslySetInnerHTML={createMarkup(hoverText)} />
        </TooltipText>
      )}
    </TooltipContainer>
  );
};
