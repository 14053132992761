import {
  createSentence,
  deleteSentence,
  getGeneratedSentence,
  getSentencesSearch,
  updateSentence,
} from '../../api';
import { AuthToken } from '../../types';
import { redirect } from 'react-router-dom';
import { errorHandler } from '../../utils';

export const createSentencesAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const { ...data } = Object.fromEntries(formData) as any;
    const parsedOwnReasons = JSON.parse(data.ownReasons);
    const parsedExperiencedReasons = JSON.parse(data.experiencedReasons);
    const newData: any = {
      ...data,
      ownReasons: parsedOwnReasons,
      experiencedReasons: parsedExperiencedReasons,
    };

    const res = await createSentence(params.formQuestionId, newData, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect('/sentences/create');
  };

export const updateSentenceAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const { ...data } = Object.fromEntries(formData) as any;
    const parsedOwnReasons = JSON.parse(data.ownReasons);
    const parsedExperiencedReasons = JSON.parse(data.experiencedReasons);
    const newData: any = {
      ...data,
      ownReasons: parsedOwnReasons,
      experiencedReasons: parsedExperiencedReasons,
    };

    const res = await updateSentence(params.sentenceId, newData, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect('/sentences');
  };

export const deleteSentenceAction =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await deleteSentence(params.sentenceId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/sentences`);
  };

export const getGeneratedSentenceAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const res = await getGeneratedSentence(data.sentence, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };

export const getSentencesAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as any;

    const res = await getSentencesSearch(data.formQuestionId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
