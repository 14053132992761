import { Box, Button, Checkbox, Loader, Overlay, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useSubmit } from 'react-router-dom';
import {
  DateGreenRed,
  DatePicker,
  NumberGreenRed,
  GreenRed,
  OneToFive,
  EstimateGreenRed,
  NumberSelect,
  DynamicTextSelect,
  DropDownGreenRed,
} from '../../../../components';
import { QuestionType, ReportQuestion } from '../../../../types';
import { createFormData } from '../../../../utils';
import dayjs from 'dayjs';
import { Group } from '@mantine/core';

import 'dayjs/locale/sv';
import { useAutosaveContext } from '../../../../contexts/AutosaveContext';
import { COLORS } from '../../../../constants';
dayjs.locale('sv');

interface FormAnswerProps {
  reportId: string;
  type: string;
  question: ReportQuestion;
  yearMonth: string;
}

export const FormAnswer = ({
  reportId,
  type,
  question,
  yearMonth,
}: FormAnswerProps) => {
  const { width } = useViewportSize();
  const isMobile = width < 500;
  const [currentQuestion, setCurrentQuestion] = useState(question);
  const [selected, setSelected] = useState(false);
  const { addPendingChange } = useAutosaveContext();
  const [selectedNumber, setSelectedNumber] = useState(question.numberValue);

  //check if there is anything in datevalues before checking relevancy
  const isThereAnythingInDateValues = () => {
    if (question?.dateValues === null) {
      return true;
    }
    if (question?.dateValues !== null && question?.dateValues?.length === 0) {
      return true;
    }
    return false;
  };

  const [questionNotRelevant, setQuestionNotRelevant] = useState(
    question?.dateValue?.includes('1970-01-01') &&
      isThereAnythingInDateValues() &&
      question?.estimateValue === -1
      ? true
      : false
  );
  const pickedYearMonth = dayjs(yearMonth, 'YYYYMM').toDate();
  //Get all saved dates from datevalues and transform them back to dates.
  const chosenDates = question?.dateValues?.map((dateString) =>
    dayjs(dateString).toDate()
  );
  //Add the old value into the new dateValues
  if (question.dateValue && question.dateValues == null) {
    const res: Date = dayjs(question.dateValue).toDate();
    if (!question?.dateValue?.includes('1970-01-01')) {
      chosenDates?.push(res);
    }
  }
  const [dates, setDates] = useState<Date[]>(chosenDates ? chosenDates : []);

  const setDateQuestionAnswer = () => {
    setCurrentQuestion((prevState) => ({
      ...prevState,
      dateValues: dates,
    }));
  };

  const handleAddDateChange = (date: Date) => {
    // Add a date to the answer
    setDates([...dates, date]);
  };

  const handleRemoveDateChange = (index: number) => {
    // remove a date from the answer
    setDates((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
    setSelected(true);
  };

  useEffect(() => {
    setDateQuestionAnswer();
  }, [dates]);

  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  // Function to handle the change in the input field
  const handleInputChange = (value: number) => {
    setSelectedNumber(value);
    // Clear the existing timer if it's set
    if (timerId) {
      clearTimeout(timerId);
    }

    // Start a new timer to update the value state after a delay
    const newTimerId = setTimeout(() => {
      // Set number after 1 sec after stopped writing
      setCurrentQuestion((prevState) => ({
        ...prevState,
        numberValue: value,
      }));
      setSelected(true);
    }, 1000);

    // Set the new timer ID
    setTimerId(newTimerId);
  };

  const saveChange = async () => {
    if (selected) {
      const formData = createFormData({
        ...currentQuestion,
        reportId: reportId,
      });
      try {
        await addPendingChange(formData);
      } catch (error) {
        console.error('Error saving change:', error);
      }
    }

    setSelected(false);
  };

  useEffect(() => {
    saveChange();
  }, [currentQuestion]);

  const isSummarySection = question.name === 'Sammanfattning';
  return (
    <div style={{ position: 'relative' }}>
      {selected && (
        <>
          <Overlay
            blur={1}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 'auto',
              right: 'auto',
              left: 'auto',
              bottom: 'auto',
              zIndex: 1001,
            }}
          />
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1002,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader variant="oval" color={COLORS.brandPink} size="md" />
          </Box>
        </>
      )}

      {(() => {
        switch (type) {
          case QuestionType.GreenRed:
            return (
              <GreenRed
                value={question.optionValue}
                onChange={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    optionValue: value,
                  }));
                  setSelected(true);
                }}
                isSummarySection={isSummarySection}
              />
            );
          case QuestionType.Date:
            return (
              <>
                <div>
                  <DatePicker
                    disabled={questionNotRelevant}
                    onChange={(value) => {
                      handleAddDateChange(value);
                      setSelected(true);
                    }}
                    defaultDate={pickedYearMonth}
                  />
                  {question.dateValues?.map((selectedDate, index) => (
                    <div key={index}>
                      <br />
                      <Group
                        position="apart"
                        align="center"
                        spacing={'xl'}
                        style={{
                          border: '1px solid #ccc',
                          padding: '10px',
                          borderRadius: '5px',
                        }}
                      >
                        <Text
                          c={questionNotRelevant ? 'gray' : 'black'}
                          style={{
                            marginRight: 'auto',
                            marginBottom: 0,
                          }}
                        >
                          {dayjs(selectedDate).format('YYYY-MM-DD')}
                        </Text>
                        {selectedDate && (
                          <Button
                            variant="default"
                            style={{
                              padding: 0,
                              background: 'none',
                              border: 'none',
                              textAlign: 'right',
                            }}
                            disabled={questionNotRelevant}
                            onClick={() => handleRemoveDateChange(index)}
                          >
                            Ta bort
                          </Button>
                        )}
                      </Group>
                    </div>
                  ))}
                </div>

                <Checkbox
                  sx={isMobile ? { width: '100%' } : { alignSelf: 'center' }}
                  mt="lg"
                  label="Ej relevant"
                  checked={questionNotRelevant}
                  onChange={() => {
                    setCurrentQuestion((prevState) => ({
                      ...prevState,
                      dateValues: questionNotRelevant ? dates : [],
                    }));
                    setQuestionNotRelevant((prevState) => !prevState);
                    setSelected(true);
                  }}
                />
              </>
            );
          case QuestionType.EstimateChild:
            return (
              <>
                <OneToFive
                  value={question.estimateValue}
                  disabled={questionNotRelevant}
                  onChange={(value) => {
                    setCurrentQuestion((prevState) => ({
                      ...prevState,
                      estimateValue: value,
                    }));
                    setSelected(true);
                  }}
                />
                <Checkbox
                  sx={isMobile ? { width: '100%' } : { alignSelf: 'center' }}
                  mt="lg"
                  label="Ej relevant"
                  checked={questionNotRelevant}
                  onChange={() => {
                    setCurrentQuestion((prevState) => ({
                      ...prevState,
                      estimateValue: questionNotRelevant === false ? -1 : 0,
                    }));
                    setQuestionNotRelevant((prevState) => !prevState);
                    setSelected(true);
                  }}
                />
              </>
            );
          case QuestionType.Number:
            return (
              <NumberSelect
                numberValue={selectedNumber}
                optionValue={question?.optionValue}
                numberInputPlaceHolder={'Ange antal'}
                numberLabel={'Antal'}
                numberInputOnChange={(value) => {
                  if (value <= 1000) {
                    handleInputChange(value);
                  } else {
                    value = 1000;
                    handleInputChange(1000);
                  }
                }}
              />
            );
          case QuestionType.DateAndGreenRed:
            return (
              <DateGreenRed
                dateValue={question.dateValue ? question.dateValue[0] : null}
                greenRedValue={question?.optionValue}
                onChangeDate={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    dateValues: [value],
                  }));
                  setSelected(true);
                }}
                onChangeGreenRed={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    optionValue: value,
                  }));
                  setSelected(true);
                }}
              />
            );
          case QuestionType.EstimateAndGreenRed:
            return (
              <EstimateGreenRed
                estimateValue={question?.estimateValue}
                optionValue={question?.optionValue}
                onChangeNumber={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    estimateValue: value,
                  }));
                  setSelected(true);
                }}
                onChangeGreenRed={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    optionValue: value,
                  }));
                  setSelected(true);
                }}
              />
            );
          case QuestionType.NumberAndGreenRed:
            return (
              <NumberGreenRed
                numberValue={selectedNumber}
                optionValue={question?.optionValue}
                numberInputPlaceHolder={'Ange antal'}
                numberLabel={'Antal'}
                disabled={question?.optionValue === 'NotApplicable'}
                numberInputOnChange={(value) => {
                  if (value <= 1000) {
                    handleInputChange(value);
                  } else {
                    value = 1000;
                    handleInputChange(1000);
                  }
                }}
                greenRedOnChange={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    optionValue: value,
                  }));
                  setSelected(true);
                }}
              />
            );
          case QuestionType.DropDown:
            return (
              <DynamicTextSelect
                value={question?.dropDownValue?.toString()}
                options={question?.dropDownValues}
                onChange={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    dropDownValue: value,
                  }));
                  setSelected(true);
                }}
              />
            );

          case QuestionType.DropDownAndGreenRed:
            return (
              <DropDownGreenRed
                selectValue={question?.dropDownValue?.toString()}
                selectOptions={question?.dropDownValues}
                optionValue={question?.optionValue}
                dropdownOnChange={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    dropDownValue: value,
                  }));
                  setSelected(true);
                }}
                greenRedOnChange={(value) => {
                  setCurrentQuestion((prevState) => ({
                    ...prevState,
                    optionValue: value,
                  }));
                  setSelected(true);
                }}
              />
            );

          default:
            return (
              <div>
                Komponent saknas för denna typ. Kontakta administratören.
              </div>
            );
        }
      })()}
    </div>
  );
};
