import { Box, Button, Dialog, Tabs } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ArrowLeft, ArrowUp } from 'react-feather';
import { Navigate, useParams, useSubmit } from 'react-router-dom';
import { useLoaderData, useNavigate } from 'react-router-dom';

import {
  MobileTopBarWrapper,
  Spacer,
  Text,
  MobileBackgroundWrapper,
  PlacedButton,
  PageWrapper,
  TopBar,
  IconLink,
  Title,
  WarningModal,
} from '../../components';
import { COLORS } from '../../constants';
import { Placement, MonthlyReport, ReportStatus } from '../../types';
import { createFormData } from '../../utils';
import {
  DesktopBackground,
  ReportFormWrapper,
  SectionGrid,
  TitleWrapper,
} from './styles';
import {
  FormAnswer,
  FormQuestion,
  OverlayButton,
  ProgressOverlay,
} from './subs';
import dayjs from 'dayjs';
import { useAutosaveContext } from '../../contexts/AutosaveContext';
interface ReportProps {
  placement: Placement;
}

export const Report: React.FC<ReportProps> = ({ placement }) => {
  const submit = useSubmit();
  const params = useParams();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const [openedProgression, setOpenedProgression] = useState(false);

  const { currentReport, resetCurrentReport } = useAutosaveContext();

  const [generateConfirmIsOpen, setGenerateConfirmIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(currentReport.formData[0]?.id);

  const backToTop = () => {
    const element = document.getElementById('topBar');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTabChange = (tabValue: string) => {
    setActiveTab(tabValue);
    backToTop();
  };

  const openGenerateModal = useCallback(
    () => setGenerateConfirmIsOpen(true),
    []
  );
  const closeGenerateModal = useCallback(
    () => setGenerateConfirmIsOpen(false),
    []
  );
  const [resetConfirmIsOpen, setResetConfirmIsOpen] = useState(false);

  const openResetModal = useCallback(() => setResetConfirmIsOpen(true), []);
  const closeResetModal = useCallback(() => setResetConfirmIsOpen(false), []);

  if (
    currentReport?.status === ReportStatus.Signed ||
    (currentReport?.status as unknown as number) === 4 // Deprecated ReportStatus.Invoiced = 4
  ) {
    return <Navigate to={`/placements/${currentReport?.id}`} />;
  }

  if (currentReport?.status === ReportStatus.Generated) {
    return (
      <Navigate
        to={`/placements/${placement?.id}/currentReport/${currentReport?.id}/signing`}
      />
    );
  }

  const isMobile = width === 0 || width < 500;

  const continueToGenerateText = (
    <div>
      <p>
        Är du säker på att du vill gå vidare och förhandsgranska rapporten? I
        nästa steg får du förslag på en färdig rapport där du har möjlighet att
        göra egna korrigeringar (utifrån de val du kryssat för i formuläret).
      </p>
      <p>
        Om du går vidare härifrån kan du INTE gå tillbaka och ändra i formuläret
        igen. Titta därför igenom formuläret en extra gång för att säkerställa
        att du kryssat i samtliga av de rutor som du önskar ha med i denna
        rapport.
      </p>
      <p>
        HumanDoc AB tillhandahåller enbart ett rapport- och handledningsstöd.
        Detta innebär att HumanDoc AB förutsätter att du läser igen hela
        förslaget på rapporten och gör korrigeringar för att informationen ska
        vara överensstämmande med de faktiska omständigheterna. Det är viktigt
        att du gör detta utifrån ett etiskt och moraliskt perspektiv.
      </p>
      <p>
        HumanDoc AB tar inte ansvar över det rapportinnehåll som du därefter
        signerar och väljer att lämna ifrån dig till ansvarig socialtjänst.
      </p>
    </div>
  );

  const generateContinueButtonText = 'Ja, till förhandsgranskning';

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper direction="row">
          <Box id="topBar" sx={{ flexDirection: 'column' }}>
            <Text size="18px">{currentReport.name}</Text>
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            <Text size="16px" weight="400">
              {placement?.birthDay &&
                dayjs(new Date(placement?.birthDay)).format('YYYY-MM-DD')}
            </Text>
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            <Text size="16px" weight="400">
              {`${placement?.familyHome?.name ?? '-'}, ${
                placement?.familyHome?.city ?? '-'
              }`}
            </Text>
          </Box>
          <Spacer minSpace="0.5rem" />
          <PlacedButton onClick={() => navigate(-1)}>
            Mina placerade
          </PlacedButton>
        </MobileTopBarWrapper>
        <ReportFormWrapper>
          <Tabs
            defaultValue={activeTab}
            onTabChange={handleTabChange}
            keepMounted={false}
            variant="pills"
            style={{
              width: '100%',
            }}
          >
            <Button
              type="submit"
              style={{ alignSelf: 'flex-end' }}
              variant="default"
              onClick={openResetModal}
            >
              Återställ rapport
            </Button>
            <Spacer minSpace="2rem" />
            <Tabs.List
              style={{
                height: 'fit-content',
                width: '100%',
              }}
            >
              {currentReport.formData.map((category) => (
                <Tabs.Tab
                  key={category.id}
                  value={category.id}
                  style={{
                    backgroundColor:
                      category.id === activeTab
                        ? COLORS.hdAccentBlue
                        : undefined,
                  }}
                >
                  {category.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {currentReport.formData.map((category, index) => (
              <Tabs.Panel id={category.id} value={category.id}>
                <Fragment key={category.id}>
                  <TitleWrapper
                    id={category.id}
                    style={{ flexDirection: 'column' }}
                  >
                    <Title as="h1" type="secondary" weight="500">
                      {category.name}
                    </Title>
                  </TitleWrapper>
                  {category.sections.map((section) => (
                    <Fragment key={section.id}>
                      {section.questions.map((question, index, array) => (
                        <FormQuestion
                          key={question.id}
                          section={`${section.name} ${index + 1}/${
                            array.length
                          }`}
                          question={question.name}
                          description={question.description?.text}
                        >
                          <FormAnswer
                            reportId={currentReport.id}
                            type={question.type}
                            question={question}
                            yearMonth={currentReport.yearMonth}
                          />
                        </FormQuestion>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              </Tabs.Panel>
            ))}
          </Tabs>

          <Spacer minSpace="2rem" maxSpace="2rem" />

          <Button
            style={{
              width: '100%',
              height: 'fit-content',
              backgroundColor: COLORS.hdBackgroundGreenDark,
              color: 'white',
              padding: '0.5rem',
            }}
            variant="default"
            onClick={backToTop}
          >
            <ArrowUp />
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            Tillbaka upp
          </Button>

          <Spacer minSpace="10rem" maxSpace="10rem" />
        </ReportFormWrapper>
        <OverlayButton
          onClick={() => setOpenedProgression((opened) => !opened)}
          overlayOpened={openedProgression}
        />
        <Dialog
          opened={openedProgression}
          withCloseButton
          onClose={() => setOpenedProgression(false)}
          size="lg"
          radius="md"
          position={{ left: 0, right: 0 }}
          zIndex={99}
          withBorder={false}
        >
          <ProgressOverlay
            onGenerateClick={openGenerateModal}
            categories={currentReport.formData}
            placementId={placement.id}
          />
        </Dialog>
        <WarningModal
          isOpen={generateConfirmIsOpen}
          onCancel={closeGenerateModal}
          onContinue={() => {
            submit(createFormData({ reportId: currentReport.id }), {
              method: 'post',
              action: `/placements/${params.placementId}/generate`,
            });
          }}
          title="Är du säker på att du vill fortsätta?"
          continueText={generateContinueButtonText}
          content={continueToGenerateText}
        />
        <WarningModal
          isOpen={resetConfirmIsOpen}
          onCancel={closeResetModal}
          onContinue={async () => {
            setIsLoading(true);
            await resetCurrentReport();
            closeResetModal();
            setIsLoading(false);
          }}
          title="Är du säker på att du vill fortsätta?"
          continueText="Ja, jag vill återställa formuläret"
          content={`Är du säker på att du vill återställa formuläret? Detta går inte att ångra.`}
          isLoading={isLoading}
        />
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        id="topBar"
        left={
          <>
            <IconLink
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Tillbaka
              </Text>
            </IconLink>

            <Title as="h1" type="secondary" weight="500">
              {placement.familyHome
                ? `${currentReport.name} - ${placement.familyHome.name}, ${placement.familyHome.city}`
                : `${currentReport.name}`}
            </Title>
          </>
        }
        right={
          <Button
            type="submit"
            style={{ alignSelf: 'flex-end' }}
            variant="default"
            onClick={openResetModal}
          >
            Återställ rapport
          </Button>
        }
      />
      <DesktopBackground>
        <ReportFormWrapper>
          <Tabs
            defaultValue={activeTab}
            onTabChange={handleTabChange}
            keepMounted={false}
            orientation="vertical"
            style={{
              width: '100%',
            }}
            variant="pills"
          >
            <Tabs.List
              style={{
                position: 'sticky',
                top: '50px',
                height: 'fit-content',
              }}
            >
              {currentReport.formData.map((category) => (
                <Tabs.Tab
                  key={category.id}
                  value={category.id}
                  style={{
                    backgroundColor:
                      category.id === activeTab
                        ? COLORS.hdAccentBlue
                        : undefined,
                  }}
                >
                  {category.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {currentReport.formData.map((category, index) => (
              <Tabs.Panel
                id={category.id}
                value={category.id}
                style={{ paddingLeft: '2rem' }}
              >
                <Fragment key={category.id}>
                  <TitleWrapper id={category.id}>
                    <Text lineHeight="35px">{category.name}</Text>
                    <Spacer minSpace="1rem" />
                  </TitleWrapper>
                  {category.sections.map((section) => (
                    <SectionGrid
                      isSummarySection={
                        section.questions[0].name === 'Sammanfattning'
                      }
                      key={section.id}
                      screenWidth={width}
                    >
                      {section.questions.map((question, index, array) => (
                        <FormQuestion
                          key={question.id}
                          section={`${section.name} ${index + 1}/${
                            array.length
                          }`}
                          question={question.name}
                          description={question.description?.text}
                        >
                          <FormAnswer
                            reportId={currentReport.id}
                            type={question.type}
                            question={question}
                            yearMonth={currentReport.yearMonth}
                          />
                        </FormQuestion>
                      ))}
                    </SectionGrid>
                  ))}
                </Fragment>
              </Tabs.Panel>
            ))}
          </Tabs>
          <Button
            style={{
              alignSelf: 'flex-end',
              width: 'fit-content',
              height: 'fit-content',
              backgroundColor: COLORS.hdBackgroundGreenDark,
              color: 'white',
              padding: '0.5rem 2rem',
            }}
            variant="default"
            onClick={backToTop}
          >
            <ArrowUp />
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            Tillbaka upp
          </Button>
          {/* bottom div so progress doesnt cover last question */}
          <div
            style={{
              height: '11.5rem',
              background: COLORS.hdBackgroundGreenDark,
              marginLeft: '-1rem',
              marginRight: '-1rem',
            }}
          ></div>
        </ReportFormWrapper>
      </DesktopBackground>
      <OverlayButton
        onClick={() => setOpenedProgression((opened) => !opened)}
        overlayOpened={openedProgression}
      />
      <Dialog
        opened={openedProgression}
        withCloseButton
        onClose={() => setOpenedProgression(false)}
        size="md"
        radius="md"
        position={isMobile ? { left: 0, right: 0 } : { right: 0 }}
        zIndex={99}
        withBorder={false}
      >
        <ProgressOverlay
          onGenerateClick={openGenerateModal}
          categories={currentReport.formData}
          placementId={placement.id}
        />
      </Dialog>
      <WarningModal
        isOpen={generateConfirmIsOpen}
        onCancel={closeGenerateModal}
        onContinue={() => {
          submit(createFormData({ reportId: currentReport.id }), {
            method: 'post',
            action: `/placements/${params.placementId}/generate`,
          });
        }}
        title="Är du säker på att du vill fortsätta?"
        continueText={generateContinueButtonText}
        content={continueToGenerateText}
      />
      <WarningModal
        isOpen={resetConfirmIsOpen}
        onCancel={closeResetModal}
        onContinue={async () => {
          setIsLoading(true);
          await resetCurrentReport();
          closeResetModal();
          setIsLoading(false);
        }}
        title="Är du säker på att du vill fortsätta?"
        continueText="Ja, jag vill återställa formuläret"
        content={`Är du säker på att du vill återställa formuläret? Detta går inte att ångra.`}
        isLoading={isLoading}
      />
    </PageWrapper>
  );
};
