export const saveAnswer = async (
  token: string | null,
  reportId: string,
  data: any
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Reports/${reportId}/autosave`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while saving answer', error);
      return error;
    });
