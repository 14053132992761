import { useLoaderData, useSubmit } from 'react-router-dom';

import {
  IconLink,
  PageWrapper,
  PlacementForm,
  Text,
  TopBar,
} from '../../components';
import { FamilyHome } from '../../types';
import { createFormData } from '../../utils';
import { useViewportSize } from '@mantine/hooks';
import { ArrowLeft } from 'react-feather';
import { COLORS } from '../../constants';

export const PlacementCreate = () => {
  const data = useLoaderData() as FamilyHome[];
  const submit = useSubmit();
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const onSubmit = (data: any) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'post',
    });
  };

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to={`/placements`}
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Placeringar
              </Text>
            </IconLink>
          </>
        }
        right={<></>}
      />
      <PlacementForm
        onSubmit={onSubmit}
        familyHomes={data}
        isMobile={isMobile}
      />
    </PageWrapper>
  );
};
