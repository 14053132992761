import { json } from 'react-router-dom';
import { AuthToken } from '../../types';
import { getPlacementsByConsultantId } from '..';
import { getRoles, getRoleByUserId } from '..';
import { errorHandler } from '../../utils';

export const getConsultantById = async (
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Consultants/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
    },
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while fetching consultant with id: ${id}`,
        error
      );
      return error;
    });

export const consultantDetailLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const resConsultant = await getConsultantById(params.consultantId, token);
    const resPlacements = await getPlacementsByConsultantId(
      params.consultantId,
      token
    );
    const resRoles = await getRoles(token);
    const resUserRole = await getRoleByUserId(params.consultantId, token);

    if (!resConsultant.ok) {
      throw await errorHandler(resConsultant);
    }

    if (!resPlacements.ok) {
      throw await errorHandler(resPlacements);
    }

    if (!resRoles.ok) {
      throw await errorHandler(resRoles);
    }

    if (!resUserRole.ok) {
      throw await errorHandler(resUserRole);
    }

    const consultant = await resConsultant.json();
    const placements = await resPlacements.json();
    const roles = await resRoles.json();
    const userRole = await resUserRole.json();

    const newRes = json(
      { consultant, placements, roles, userRole },
      { status: 200 }
    );

    return newRes;
  };
