import { useSubmit } from 'react-router-dom';
import { Trash2 } from 'react-feather';
import { StyledDeleteButtonWrapper } from './styles';
import { WarningModal } from '../../../../components';
import { useCallback, useState } from 'react';

interface DeleteSentenceButtonProps {
  sentenceId: string;
  sentenceText: string;
}

export const DeleteSentenceButton = ({
  sentenceId,
  sentenceText,
}: DeleteSentenceButtonProps) => {
  const submit = useSubmit();
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  const openWarningModal = useCallback(() => setWarningModalIsOpen(true), []);
  const closeWarningModal = useCallback(() => setWarningModalIsOpen(false), []);
  const deleteSentenceCallback = useCallback(
    () => deleteSentence(sentenceId),
    []
  );

  const deleteSentence = (sentenceId: string) => {
    setWarningModalIsOpen(false);
    submit(
      { sentenceId: sentenceId },
      {
        method: 'post',
        action: `/sentences/${sentenceId}/delete`,
      }
    );
  };

  return (
    <StyledDeleteButtonWrapper>
      <Trash2
        onClick={() => {
          openWarningModal();
        }}
        size="20"
        color="grey"
      />

      <WarningModal
        isOpen={warningModalIsOpen}
        onCancel={closeWarningModal}
        onContinue={deleteSentenceCallback}
        title="Är du säker på att du vill ta bort den här meningen?"
        continueText="Radera mening"
        content={`"${sentenceText.slice(0, 120)}..."`}
      />
    </StyledDeleteButtonWrapper>
  );
};
