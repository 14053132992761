import { ModalTitle } from '@atlaskit/modal-dialog';
import { BaseModal } from '.';
import { WarningButton } from '..';

interface AKWarningModalProps {
  isOpen: boolean;
  onContinue: () => void;
  onClose?: () => void;
  content: string | React.ReactNode;
  title?: string;
  continueText?: string;
}

export const ErrorModal = ({
  isOpen,
  onContinue,
  onClose,
  title,
  content,
  continueText,
}: AKWarningModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <ModalTitle appearance="warning">{title ?? 'Är du säker?'}</ModalTitle>
      }
      content={
        <>
          <p>{content}</p>
        </>
      }
      buttons={
        <>
          <WarningButton color="yellow" onClick={onContinue}>
            {continueText ?? 'Stäng'}
          </WarningButton>
        </>
      }
    />
  );
};
