import styled from '@emotion/styled';
import { Group } from '@mantine/core';

export const StyledFormGroup = styled(Group)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;

  .mantine-InputWrapper-root {
    width: 48%;
  }
`;
