import { Form } from 'react-router-dom';
import { Box, Group, Select, TextInput } from '@mantine/core';
import { useForm, isNotEmpty, FORM_INDEX } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../../constants';
import { DateOfBirth } from '../../components';
import { CancelButton, PrimaryButton, Spacer, Text, FormWrapper } from '..';
import {
  FamilyHome,
  Placement,
  PlacementExperiencedReasons,
  PlacementOwnReasons,
} from '../../types';
import { placementReasonsTranslation } from '../../utils';
import dayjs from 'dayjs';

interface PlacementFormProps {
  onSubmit: (data: any) => void;
  placement?: Placement;
  familyHomes?: FamilyHome[];
  isMobile?: boolean;
}

interface PlacementFormData {
  name: string;
  sex: string;
  birthDay: string | Date;
  // responsibleConsultant: any; //todo not implemented in backend
  reasons: { value: string }[];
  familyHomeId?: string;
}

export const PlacementForm = ({
  onSubmit,
  placement,
  familyHomes,
  isMobile,
}: PlacementFormProps) => {
  const navigate = useNavigate();

  const reasons: any[] = [];
  placement?.experiencedReasons?.forEach((reason) =>
    reasons.push({
      value: reason,
    })
  );
  placement?.ownReasons?.forEach((reason) =>
    reasons.push({
      value: reason,
    })
  );

  const form = useForm<PlacementFormData>({
    validateInputOnBlur: [
      'name',
      'birthday',
      'sex',
      `reasons.${FORM_INDEX}.value`,
    ],
    initialValues: {
      name: placement?.name ?? '',
      sex: placement?.sex ?? '',
      birthDay: placement?.birthDay ?? '',
      reasons: reasons.length > 0 ? reasons : [{ value: '' }],
    },

    validate: {
      name: isNotEmpty('Ange initialer eller annan benämning'),
      birthDay: (value) =>
        dayjs(value).isValid() && dayjs(value) < dayjs()
          ? null
          : 'Ange ett giltigt födelsedatum',
      sex: isNotEmpty('Ange ett kön'),
      reasons: {
        value: isNotEmpty('Ange en orsak'),
      },
    },
  });

  const ownReasonValues = Object.values(PlacementOwnReasons);
  const experiencedReasonsValues = Object.values(PlacementExperiencedReasons);

  const own = ownReasonValues.map((reason) => ({
    key: `own-${reason}`,
    value: reason,
    label:
      placementReasonsTranslation[
        reason as keyof typeof placementReasonsTranslation
      ],
    group: 'Egna skäl',
  }));

  const experienced = experiencedReasonsValues.map((reason) => ({
    key: `experienced-${reason}`,
    value: reason,
    label:
      placementReasonsTranslation[
        reason as keyof typeof placementReasonsTranslation
      ],
    group: 'Upplevda skäl',
  }));

  return (
    <FormWrapper scrollable>
      <Form method="post" style={{ width: isMobile ? '100%' : '27rem' }}>
        <Text>Kontaktuppgifter</Text>
        <Spacer minSpace="1.5rem" />
        <TextInput
          label="Placerads initialer eller benämning"
          placeholder="Placerads initialer eller benämning"
          name="name"
          {...form.getInputProps('name')}
        />
        <Select
          mt="xs"
          name="sex"
          label="Placerads kön"
          placeholder="Placerads kön"
          {...form.getInputProps('sex')}
          data={[
            { value: 'They', label: 'Hen' },
            { value: 'She', label: 'Hon' },
            { value: 'He', label: 'Han' },
          ]}
        />
        <DateOfBirth form={form} />

        {!placement && familyHomes && familyHomes.length > 0 ? (
          <Select
            mt="xs"
            name="familyHome"
            label="Välj familjehem"
            placeholder="Välj familjehem"
            {...form.getInputProps('familyHomeId')}
            value={form.getInputProps('familyHomeId').value}
            data={familyHomes?.map((familyHome) => ({
              value: familyHome.id,
              label: `${familyHome.name}, ${familyHome.city}`,
            }))}
            clearable
          />
        ) : null}

        {form.values.reasons.map((reason, index) => (
          <Box
            mt="xs"
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Select
              mt="xs"
              name="value"
              label={`Placeringsorsak ${index + 1}`}
              placeholder="Välj placeringsorsak"
              {...form.getInputProps(`reasons.${index}.value`)}
              value={form.getInputProps(`reasons.${index}.value`).value}
              data={[...own, ...experienced]}
              sx={{ width: '100%' }}
            />
            {form.values.reasons.length > 1 && (
              <CancelButton
                mt="xs"
                onClick={() => form.removeListItem('reasons', index)}
              >
                Ta bort
              </CancelButton>
            )}
          </Box>
        ))}
        <PrimaryButton
          fullWidth
          mt="md"
          onClick={() => form.insertListItem('reasons', { value: '' })}
        >
          Lägg till orsak
        </PrimaryButton>
        <Group position="right" spacing="xs" sx={{ marginTop: '1.5rem' }}>
          <CancelButton
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          />
          <PrimaryButton
            onClick={() => {
              const formHasErrors = form.validate().hasErrors;
              if (formHasErrors) return;

              const placement: any = {
                name: form.values.name,
                sex: form.values.sex,
                ownReasons: form.values.reasons
                  .map((reason) => reason.value)
                  .filter((reason) =>
                    ownReasonValues.find((value) => value === reason)
                  ),
                experiencedReasons: form.values.reasons
                  .map((reason) => reason.value)
                  .filter((reason) =>
                    experiencedReasonsValues.find((value) => value === reason)
                  ),
                birthDay: form.values.birthDay,
                familyHomeId: form.values.familyHomeId ?? undefined,
              };
              !formHasErrors && onSubmit(placement);
            }}
            background={COLORS.hdAccentGreen}
            hover={COLORS.hdAccentGreenDark}
          >
            Spara
          </PrimaryButton>
        </Group>
        <Spacer minSpace="1.5rem" />
      </Form>
    </FormWrapper>
  );
};
