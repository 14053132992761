import { Group } from '@mantine/core';
import { COLORS } from '../../../../../../constants';
import styled from '@emotion/styled';

export interface StyledButtonGroupProps {
  overlayOpened: boolean;
  drawerWidth?: string;
}

export const StyledButtonGroup = styled(Group)<StyledButtonGroupProps>`
  background: ${COLORS.hdBackgroundGreenDark};
  position: fixed;
  right: ${(props) =>
    props.overlayOpened
      ? props.drawerWidth
        ? props.drawerWidth
        : '20rem'
      : '0'};
  bottom: 0;
  z-index: 400;
  border-radius: 20px;
  transition: right 0.3s ease;
`;
