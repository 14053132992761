import { StyledH1, StyledH2, StyledH3, StyledH4 } from './styles';

interface TitleProps {
  as: 'h1' | 'h2' | 'h3' | 'h4';
  type?: 'primary' | 'faded' | 'secondary';
  weight?: string;
  children: React.ReactNode;
}

export const Title = ({
  as,
  type = 'primary',
  weight,
  children,
}: TitleProps) => {
  switch (as) {
    case 'h1':
      return (
        <StyledH1 type={type} weight={weight}>
          {children}
        </StyledH1>
      );

    case 'h2':
      return (
        <StyledH2 type={type} weight={weight}>
          {children}
        </StyledH2>
      );

    case 'h3':
    default:
      return (
        <StyledH3 type={type} weight={weight}>
          {children}
        </StyledH3>
      );

    case 'h4':
      return (
        <StyledH4 type={type} weight={weight}>
          {children}
        </StyledH4>
      );
  }
};
