import { CheckButton, Title } from '../../../../components';
import { StyledSection } from '../../styles';

interface ReasonsSectionProps {
  ownReasons: any[];
  handleOwnReasons: any;
  experiencedReasons: any[];
  handleExperiencedReasons: any;
}

export const ReasonsSection = ({
  ownReasons,
  handleOwnReasons,
  experiencedReasons,
  handleExperiencedReasons,
}: ReasonsSectionProps) => {
  return (
    <StyledSection>
      <Title as="h4" type="secondary" weight="600">
        Placeringsorsak
      </Title>

      <div style={{ marginTop: '1rem' }}>
        <Title as="h4" type="faded" weight="600">
          Egen
        </Title>
        <>
          {ownReasons.map((item, i) => {
            return (
              <CheckButton
                key={i}
                selected={item.selected}
                value={item.value}
                onClick={handleOwnReasons}
              >
                {item.name}
              </CheckButton>
            );
          })}
        </>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Title as="h4" type="faded" weight="600">
          Upplevd
        </Title>
        <>
          {experiencedReasons.map((item, i) => {
            return (
              <CheckButton
                key={i}
                selected={item.selected}
                value={item.value}
                onClick={handleExperiencedReasons}
              >
                {item.name}
              </CheckButton>
            );
          })}
        </>
      </div>
    </StyledSection>
  );
};
