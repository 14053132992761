import styled from '@emotion/styled';
import { COLORS } from '../../../../constants';

export const LockedWrapper = styled.div`
  button:hover {
    background-color: ${COLORS.hdAccentGreen};
  }
`;

export const UnLockedWrapper = styled.div`
  button {
    background-color: ${COLORS.hdAccentOrange};
  }
`;
