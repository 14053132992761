import styled from '@emotion/styled';
import { Gift } from 'react-feather';
import { COLORS } from '../../constants';

export const StyledGiftIcon = styled(Gift)`
  background: #ff9dde;
  border-radius: 50px;
  padding: 8px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 36px;
  color: ${COLORS.hdBackgroundWhite};
  margin-right: 0.5rem;
`;

export const BirthdayIcon = () => <StyledGiftIcon />;
