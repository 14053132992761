import { redirect } from 'react-router';
import { AuthToken } from '../types';
import { errorHandler } from '../utils';

export const logout = async (token: string | null): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Account/Logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: '*/*',
    },
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        'something went wrong while logging out from account',
        error
      );
      return error;
    });

export const logoutAction =
  ({ token }: AuthToken) =>
  async () => {
    const res = await logout(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect('/');
  };
