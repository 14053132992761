import { TableRow, Tenant } from '../../types';
import { LockButton } from './subs';

export const formatTenantRows = (
  data: Tenant[],
  onRowClick: (id: string) => void
): TableRow[] =>
  data.map((tenant) => ({
    id: tenant.id,
    onClick: () => onRowClick(tenant.id),
    cells: [
      {
        name: 'name',
        label: 'Kundnamn',
        isSortable: true,
        value: tenant.name,
        content: tenant.name,
      },
      {
        name: 'email',
        label: 'E-postadress',
        isSortable: true,
        value: tenant.contactEmail,
        content: tenant.contactEmail,
      },
      {
        name: 'phone',
        label: 'Telefonnummer',
        isSortable: true,
        value: tenant.contactPhone,
        content: tenant.contactPhone,
      },
      {
        name: 'numberOfConsultants',
        label: 'Antal konsulenter',
        isSortable: true,
        value: tenant.statistics.numberOfConsultants,
        content: tenant.statistics.numberOfConsultants,
      },
      {
        name: 'numberOfFamilyHomes',
        label: 'Påbörjade rapporter',
        isSortable: true,
        value: tenant.statistics.numberOfFamilyHomes,
        content: tenant.statistics.numberOfFamilyHomes,
      },
      {
        name: 'lockButton',
        label: 'Lås konto',
        isSortable: false,
        content: <LockButton tenant={tenant} />,
      },
    ],
  }));
