import styled from '@emotion/styled';
import { Group } from '@mantine/core';
import { COLORS } from '../../../../constants';

export const StyledButtonGroup = styled(Group)`
  background: ${COLORS.hdBackgroundGreenDark};
  position: fixed;
  right: 0;
  bottom: 5.5rem;
  z-index: 100;
  border-radius: 20px;
`;
