import { UseFormReturnType } from '@mantine/form';
import { StyledSelect } from '../../../Sentences/styles';
import { StyledSection } from '../../styles';

interface CategorySectionProps {
  mainCategories: any[];
  subCategories: any[];
  statusList: any[];
  form: UseFormReturnType<any>;
}

export const CategorySection = ({
  mainCategories,
  subCategories,
  statusList,
  form,
}: CategorySectionProps) => {
  return (
    <StyledSection style={{ display: 'flex' }}>
      <StyledSelect
        label="Kategori"
        placeholder="Välj kategori"
        data={mainCategories}
        sx={{ marginLeft: '0 !important' }}
        name="mainCategory"
        {...form.getInputProps('mainCategory')}
      />
      <StyledSelect
        label="Underkategori"
        placeholder="Välj underkategori"
        data={subCategories}
        disabled={form.values.mainCategory.length > 0 ? false : true}
        name="subCategory"
        {...form.getInputProps('subCategory')}
      />
      <StyledSelect
        label="Status"
        placeholder="Välj status"
        data={statusList}
        name="status"
        {...form.getInputProps('status')}
      />
    </StyledSection>
  );
};
