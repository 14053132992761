import { InfoBox } from '../../../../components';
import { ConsultantStats } from '../../../../types';
import { QuickInfoWrapper } from './styles';

interface QuickInfoProps {
  stats: ConsultantStats;
}

export const QuickInfo = ({ stats }: QuickInfoProps) => {
  return (
    <QuickInfoWrapper>
      <InfoBox infoText="Antal placerade" value={stats.numberOfPlacements} />
      <InfoBox infoText="Antal familjehem" value={stats.numberOfFamilyHomes} />
    </QuickInfoWrapper>
  );
};
