import { useAuth } from '../../../../../../hooks';
import { getVisibleNavLinks } from '../../../../../../utils/authHelpers';
import { StyledListItem, StyledNav, StyledNavLink } from './styles';

interface NavigationLinksProps {
  onNavigate: () => void;
}

export const NavigationLinks = ({
  onNavigate,
}: NavigationLinksProps): JSX.Element => {
  const { state } = useAuth();
  const userRole = state?.user?.role;
  const visibleLinks = getVisibleNavLinks(userRole);

  return (
    <StyledNav>
      <ul>
        {visibleLinks.map(({ to, title }, index) => (
          <StyledListItem key={`${title}-${index}`}>
            <StyledNavLink to={to} onClick={onNavigate}>
              {title}
            </StyledNavLink>
          </StyledListItem>
        ))}
      </ul>
    </StyledNav>
  );
};
