import { TableLink } from '../../components';
import { TableRow, Sentence } from '../../types';
import { placementReasonsTranslation } from '../../utils';
import { DeleteSentenceButton } from './subs';

const makeReasons = (sentence: Sentence) => {
  let reasonString = '';
  sentence.ownReasons?.forEach((reason, index) => {
    index = index + 1;
    const word =
      placementReasonsTranslation[
        reason as keyof typeof placementReasonsTranslation
      ];
    reasonString = reasonString + word + ', ';
  });
  sentence.experiencedReasons?.forEach((reason, index) => {
    index = index + 1;
    const word =
      placementReasonsTranslation[
        reason as keyof typeof placementReasonsTranslation
      ];
    reasonString = reasonString + word + ', ';
  });

  const ellipsis = reasonString.length > 40 ? '...' : '';

  return (
    reasonString.substring(0, reasonString.length - 2).slice(0, 40) + ellipsis
  );
};

export const formatSentencesRows = (data: Sentence[]): TableRow[] =>
  data.map((sentence) => ({
    id: sentence.id,
    cells: [
      {
        name: 'name',
        label: 'Namn',
        isSortable: true,
        value: sentence.text.slice(0, 65),
        content: (
          <TableLink id={`${sentence.id}/edit`} hoverText={sentence.text}>
            {sentence.text.slice(0, 65)}...
          </TableLink>
        ),
      },
      {
        name: 'status',
        label: 'Status',
        isSortable: true,
        value: `${
          sentence.questionResponse === 'Green'
            ? 'Välfungerande'
            : sentence.questionResponse === 'Red'
            ? 'Utvecklingsområde'
            : 'Inte relevant'
        }`,
        content: `${
          sentence.questionResponse === 'Green'
            ? 'Välfungerande'
            : sentence.questionResponse === 'Red'
            ? 'Utvecklingsområde'
            : 'Inte relevant'
        }`,
      },
      {
        name: 'age',
        label: 'Ålder',
        isSortable: true,
        value: `${sentence.minAge}-${sentence.maxAge}`,
        content: `${sentence.minAge}-${sentence.maxAge}`,
      },
      {
        name: 'reason',
        label: 'Orsak',
        isSortable: true,
        value: sentence.ownReasons.length > 0 ? makeReasons(sentence) : '-',
        content: sentence.ownReasons.length > 0 ? makeReasons(sentence) : '-',
      },
      {
        name: 'delete',
        label: 'Ta bort',
        isSortable: false,
        content: (
          <DeleteSentenceButton
            sentenceId={sentence.id}
            sentenceText={sentence.text}
          />
        ),
      },
    ],
  }));
