export const placementReasonsTranslation = {
  RulesNormViolations: 'Regel och normbrott',
  Crime: 'Kriminalitet',
  OwnViolence: 'Våld/Hot',
  SexualHarassment: 'Sexuellt ofredande',
  AnimalCruelty: 'Djurplågeri',
  Abuse: 'Alkohol - eller droger',
  UnaccompaniedRefugeeChild: 'Ensamkommande barn',
  Honor: 'Heder',
  CareFailure: 'Omsorgssvikt',
  ExperiencedViolence: 'Våld',
  ViolenceAgainstPartner: 'Våld mot partner/annan',
  SexualAssault: 'Sexuella övergrepp',
  HonorRelatedViolenceOppression: 'Hedersrelaterat våld och förtryck',
  EnvironmentWithAlcohol: 'Miljö med alkohol',
  EnvironmentWithDrugs: 'Miljö med droger',
  EnvironmentWithProstitution: 'Miljö med försäljning av sexuella tjänster',
  EnvironmentWithCrime: 'Miljö med kriminalitet',
  DeceasedParents: 'Avlidna föräldrar',
  ChildMarriage: 'Barnäktenskap',
  Trafficking: 'Människohandel och exploatering',
};
