import { useLoaderData, useNavigate } from 'react-router-dom';

import {
  TopBar,
  PageWrapper,
  Title,
  Table,
  PrimaryButton,
  MobileBackgroundWrapper,
  MobileTopBarWrapper,
  Spacer,
  MobileContentWrapper,
} from '../../components';
import { FamilyHome } from '../../types/FamilyHome';
import { formatFamilyHomeRows } from './data';
import { useViewportSize } from '@mantine/hooks';

export const FamilyHomes = () => {
  const navigate = useNavigate();
  const data = useLoaderData() as FamilyHome[];
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const rows = formatFamilyHomeRows(data, (id: string) => navigate(id));

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper>
          <Title as="h2" type="secondary" weight="500">
            Familjehem
          </Title>
          <Spacer minSpace="1rem" />
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              navigate('create');
            }}
          >
            Lägg till nytt familjehem
          </PrimaryButton>
        </MobileTopBarWrapper>
        <Table rows={rows} buttonMaxWidth={'3.25rem'} />
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <Title as="h1" type="secondary" weight="500">
            Familjehem
          </Title>
        }
        right={
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              navigate('create');
            }}
          >
            Lägg till nytt familjehem
          </PrimaryButton>
        }
      />

      <Table rows={rows} buttonMaxWidth={'3.25rem'} />
    </PageWrapper>
  );
};
