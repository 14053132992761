export const updateTenant = async (
  data: any,
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Tenants/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while updating tenant with id: ${id}`,
        error
      );
      return error;
    });
