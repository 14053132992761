import { json } from 'react-router-dom';
import { getForms, getSentenceById } from '../../api';
import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const sentenceLoader =
  ({ token }: AuthToken) =>
  async () => {
    const res = await getForms(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    const forms = await res.json();

    const newRes = json({ forms }, { status: 200 });

    return newRes;
  };

export const sentenceEditLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const resForms = await getForms(token);
    const resSentence = await getSentenceById(params.sentenceId, token);

    if (!resForms.ok) {
      throw await errorHandler(resForms);
    }

    if (!resSentence.ok) {
      throw await errorHandler(resSentence);
    }

    const forms = await resForms.json();
    const sentence = await resSentence.json();

    const newRes = json({ forms, sentence }, { status: 200 });

    return newRes;
  };
