import { redirect } from 'react-router-dom';
import { AuthToken, FamilyHome } from '../../types';
import { errorHandler } from '../../utils';

export const createFamilyHome = async (
  data: any,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/FamilyHomes`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while creating family home', error);
      return error;
    });

export const createFamilyHomeAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const { id, ...data } = Object.fromEntries(formData) as any;
    const parsedRps = JSON.parse(data.relatedPersons);
    const familyHome: FamilyHome = {
      ...data,
      relatedPersons: parsedRps,
      multiplePlacement: data.multiplePlacement === 'true' ? true : false, //formdata doesnt allow booleans...
    };

    const res = await createFamilyHome(familyHome, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    let home: FamilyHome;

    try {
      home = await res.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return redirect(`/family-homes/${home.id}`);
  };
