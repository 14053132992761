import { Navigate, useActionData, useNavigation } from 'react-router-dom';
import { Button, Box, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';

import { Spacer } from '../../components';
import { useAuth } from '../../hooks';
import { useEffect } from 'react';
import {
  LoginTextInput,
  LoginPasswordInput,
  StyledLoginForm,
  StyledLoginPageWrapper,
  StyledLoginError,
  StyledInputWrapper,
} from './styles';
import { validateEmail } from '../../utils';
import logo from '../../hd-logo-black.svg';

interface LoginAction {
  token?: string;
  type?: string;
  title?: string;
  status?: number;
  traceId?: string;
}

export const Login = () => {
  const data = useActionData() as LoginAction;
  const { state: authState, login } = useAuth();
  const { state } = useNavigation();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },

    validate: {
      username: (value) =>
        validateEmail(value) ? 'Ogiltig e-postadress' : null,
      password: (value) =>
        value?.length < 1 ? 'Var god ange ett lösenord' : null,
    },
  });

  useEffect(() => {
    if (data?.token && !authState.token) {
      login(data.token);
    }
  });

  if (authState.token) return <Navigate to="/dashboard" />;

  return (
    <StyledLoginPageWrapper>
      <StyledLoginForm method="post">
        <Box ta="center" mt="4rem">
          <img
            src={logo}
            alt={'HumanDoc logo'}
            style={{ width: '20rem' }}
          ></img>
        </Box>

        <Spacer minSpace="7rem" maxSpace="25rem" />

        {data?.status && (
          <StyledLoginError>
            Du har angivit fel användarnamn eller lösenord. Försök igen eller
            kontakta info@humandoc.se
          </StyledLoginError>
        )}
        <StyledInputWrapper>
          <LoginTextInput
            label="E-postadress"
            autoComplete="username"
            placeholder="E-postadress"
            name="username"
            {...form.getInputProps('username')}
          />

          <LoginPasswordInput
            autoComplete="current-password"
            label="Lösenord"
            placeholder="Lösenord"
            name="password"
            {...form.getInputProps('password')}
          />

          <Button mt="md" type="submit" fullWidth loading={state !== 'idle'}>
            Logga in
          </Button>
        </StyledInputWrapper>
      </StyledLoginForm>
    </StyledLoginPageWrapper>
  );
};
