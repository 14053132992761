import { StyledText } from './styles';

import { Color } from '../../types';

export interface TextProps {
  color?: Color | string;
  weight?: string;
  size?: string;
  lineHeight?: string;
  children: React.ReactNode;
}

export const Text = ({
  color,
  weight,
  size,
  lineHeight,
  children,
}: TextProps) => {
  return (
    <StyledText
      color={color}
      weight={weight}
      size={size}
      lineHeight={lineHeight}
    >
      {children}
    </StyledText>
  );
};
