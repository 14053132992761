export const getReportsByPlacementId = async (
  placementId: string,
  token: string | null
): Promise<Response> =>
  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/Reports/search?placementId=${placementId}&latest=false`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      },
    }
  )
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while fetching placements with id ${placementId}`,
        error
      );
      return error;
    });
