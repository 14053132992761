import { FamilyHome } from './FamilyHome';
import {
  FormattedConsultantTime,
  ReportStatus,
  SumConsultantTime,
} from './MonthlyReport';

export interface Placement {
  id: string;
  name: string; //only initials
  birthDay: Date;
  birthDayNotification: boolean;
  experiencedReasons: string[]; //array of reason keywords i.e. "EnvironmentWithAlcohol"
  ownReasons: string[]; //array of reason keywords i.e. "Crime"
  familyHome: FamilyHome | null;
  sex: string; //gender
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  reports: PlacementReport[] | null; //TODO refactor to separate type, some properties does not line up
  responsibleConsultant: string;
}

export interface PlacementReport {
  id: string;
  name: string;
  yearMonth: string;
  sumConsultantTimes: Array<SumConsultantTime>;
  formattedConsultantTimes: Array<FormattedConsultantTime>;
  signedBy: string;
  signedAt: string;
  status: ReportStatus;
  progress: number;
  placementId: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  isInvoiced: boolean;
  alternativeName: string;
}

export enum PlacementOwnReasons {
  RulesNormViolations = 'RulesNormViolations',
  Crime = 'Crime',
  OwnViolence = 'OwnViolence',
  SexualHarassment = 'SexualHarassment',
  AnimalCruelty = 'AnimalCruelty',
  Abuse = 'Abuse',
  UnaccompaniedRefugeeChild = 'UnaccompaniedRefugeeChild',
  Honor = 'Honor',
}

export enum PlacementExperiencedReasons {
  CareFailure = 'CareFailure',
  ExperiencedViolence = 'ExperiencedViolence',
  ViolenceAgainstPartner = 'ViolenceAgainstPartner',
  SexualAssault = 'SexualAssault',
  HonorRelatedViolenceOppression = 'HonorRelatedViolenceOppression',
  EnvironmentWithAlcohol = 'EnvironmentWithAlcohol',
  EnvironmentWithDrugs = 'EnvironmentWithDrugs',
  EnvironmentWithProstitution = 'EnvironmentWithProstitution',
  EnvironmentWithCrime = 'EnvironmentWithCrime',
  DeceasedParents = 'DeceasedParents',
  ChildMarriage = 'ChildMarriage',
  Trafficking = 'Trafficking',
}
