import { COLORS } from '../../../../constants';
import { Text } from '../../../../components';
import styled from '@emotion/styled';

interface ContentHeaderProps {
  text: string;
  size?: string;
}

export const StyledContentHeader = styled.div`
  align-self: flex-start;
`;

export const ContentHeader = ({ text, size }: ContentHeaderProps) => {
  return (
    <StyledContentHeader>
      <Text color={'#000000'} size={size ? size : '12px'}>
        {text}
      </Text>
    </StyledContentHeader>
  );
};
