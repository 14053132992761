import { useViewportSize } from '@mantine/hooks';
import { Check, X, TrendingUp } from 'react-feather';

import { Text } from '../../../components';
import { COLORS } from '../../../constants';
import { StyledEvaluationWrapper } from '../styles';
import { EvaluationBox } from './subs';

interface GreenRedProps {
  value?: string;
  onChange: (val: string) => void;
  isSummarySection?: boolean;
}

export const GreenRed = ({
  value,
  onChange,
  isSummarySection = false,
}: GreenRedProps) => {
  const { width } = useViewportSize();
  const isDesktop = width > 500;

  const handleChangeSelected = (val: string) => {
    onChange && onChange(val);
  };

  return (
    <StyledEvaluationWrapper isDesktop={isDesktop}>
      <EvaluationBox
        selected={value === 'Green'}
        color={COLORS.hdAccentGreen}
        onClick={() => handleChangeSelected('Green')}
        isSummarySection={isSummarySection}
        screenWidth={width}
      >
        <>
          <Check
            color={
              value === 'Green'
                ? COLORS.hdBackgroundWhite
                : COLORS.hdAccentGreen
            }
            size={isSummarySection ? '40px' : undefined}
          />
          <Text
            weight="400"
            size={isSummarySection && width >= 1000 ? '19px' : '13px'}
            color={
              value === 'Green' ? COLORS.textPrimary : COLORS.evaluationGreen
            }
            lineHeight="13px"
          >
            Välfungerande
          </Text>
        </>
      </EvaluationBox>
      {/* NOTE: the color of this was changed to blue to improve UX
      after the type was implemented in backend */}
      <EvaluationBox
        selected={value === 'Red'}
        color={COLORS.evaluationBlue}
        onClick={() => handleChangeSelected('Red')}
        isSummarySection={isSummarySection}
        screenWidth={width}
      >
        <>
          <TrendingUp
            color={
              value === 'Red' ? COLORS.hdBackgroundWhite : COLORS.evaluationBlue
            }
            size={isSummarySection ? '40px' : undefined}
          />
          <Text
            weight="400"
            size={isSummarySection && width >= 1000 ? '19px' : '13px'}
            color={value === 'Red' ? COLORS.textPrimary : COLORS.hdAccentBlue}
            lineHeight="13px"
          >
            Utvecklings område
          </Text>
        </>
      </EvaluationBox>
      <EvaluationBox
        selected={value === 'NotApplicable'}
        color={COLORS.evaluationGrey}
        onClick={() => handleChangeSelected('NotApplicable')}
        isSummarySection={isSummarySection}
        screenWidth={width}
      >
        <>
          <X
            color={
              value === 'NotApplicable'
                ? COLORS.hdBackgroundWhite
                : COLORS.evaluationGrey
            }
            size={isSummarySection ? '40px' : undefined}
          />
          <Text
            weight="400"
            size={isSummarySection && width >= 1000 ? '19px' : '13px'}
            color={
              value === 'NotApplicable'
                ? COLORS.textPrimary
                : COLORS.evaluationGrey
            }
            lineHeight="13px"
          >
            Inte relevant
          </Text>
        </>
      </EvaluationBox>
    </StyledEvaluationWrapper>
  );
};
