import React from 'react';

import AKModal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog';

interface BaseModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  content: React.ReactNode;
  buttons: React.ReactNode;
}

export const BaseModal = ({
  isOpen,
  onClose,
  title,
  content,
  buttons,
}: BaseModalProps) => {
  return (
    <ModalTransition>
      {isOpen && (
        <AKModal
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
        >
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{content}</ModalBody>
          <ModalFooter>{buttons}</ModalFooter>
        </AKModal>
      )}
    </ModalTransition>
  );
};

export * from './Modal';
export * from './DeleteModal';
export * from './WarningModal';
export * from './FormModal';
export * from './ErrorModal';
