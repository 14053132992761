export enum COLORS {
  //refactor: give better names that signal color
  textPrimary = '#ffffff',
  textSecondary = '#000000',
  textDisabled = '#6b778c',
  textGrey = '#767676',

  hdBackgroundWhite = '#ffffff',
  hdBackgroundGreenDark = '#124343', //#103c3c
  hdBackgroundGreenDarker = '#103c3c',
  hdBackgroundGreenLight = '#e9f2f2',
  hdBackgroundGrey = '#f5f6f9',
  hdBackgroundGreyDark = '#dee2e6',
  hdBackgroundGreyLight = '#f4f4f4',
  hdBackgroundRed = '#ffeded',

  hdAccentGreen = '#2a9e44',
  hdAccentGreenDark = '#418181',
  hdAccentGreenDarker = '#006644',
  hdAccentGreenLight = '#87c5c5',
  hdAccentBlue = '#0052cc',
  hdAccentBlueDark = '#00317A',
  hdAccentBlueLight = '#5f80b1',
  hdAccentRed = '#c5482d',
  hdAccentOrange = '#f5a829',
  hdAccentTableBorderGrey = '#dee2e6',

  evaluationGreen = '#60a66f',
  evaluationBlue = '#7394ea',
  evaluationGrey = '#8f8f8f',
  evaluationBlack = '#313131',

  reportSignHeader = '#8f8888',
  brandPink = '#FF0062',
  brandPinkDarker = '#E6005C',

  disabledMultiselectBackgroundGrey = '#f1f3f5',
  disabledMultiselectGrey = '#909296',
  disabledButtonBackgroundGrey = '#F7F8F9',
}

export const BASELINKS = [
  { to: 'tenants', title: 'Kunder' },
  { to: 'sentences', title: 'Meningar' },
  { to: 'consultants', title: 'Konsulenter' },
  { to: 'family-homes', title: 'Familjehem' },
  { to: 'placements', title: 'Placeringar' },
  { to: 'reports', title: 'Rapporter' },
  { to: 'descriptions', title: 'Förklaringar' },
];
