import { json } from 'react-router-dom';
import { getForms } from '../../api';
import { getDescriptionById } from '../../api/descriptions';
import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const descriptionLoader =
  ({ token }: AuthToken) =>
  async () => {
    const res = await getForms(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    const forms = await res.json();

    const newRes = json({ forms }, { status: 200 });

    return newRes;
  };

export const descriptionEditLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const resForms = await getForms(token);
    const resDescription = await getDescriptionById(
      params.descriptionId,
      token
    );

    if (!resForms.ok) {
      throw await errorHandler(resForms);
    }

    if (!resDescription.ok) {
      throw await errorHandler(resDescription);
    }

    const forms = await resForms.json();
    const description = await resDescription.json();

    const newRes = json({ forms, description }, { status: 200 });

    return newRes;
  };
