import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { Color } from '../../types';

export interface SecondaryButtonProps extends ButtonProps {
  onClick: (e: any) => void;
  backgroundColor?: Color | string;
}

const StyledSB = styled(Button)<SecondaryButtonProps>`
  background-color: ${(props) =>
    props.backgroundColor || COLORS.hdAccentTableBorderGrey};
  font-size: initial;
`;

const StyledSecondaryButton = createPolymorphicComponent<
  'button',
  SecondaryButtonProps
>(StyledSB);

export const SecondaryButton = ({
  children,
  ...rest
}: SecondaryButtonProps) => (
  <StyledSecondaryButton variant="light" color="dark" {...rest}>
    {children}
  </StyledSecondaryButton>
);
