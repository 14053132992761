import styled from '@emotion/styled';
import { Button, MultiSelect, MultiSelectProps, Select } from '@mantine/core';
import { Textarea } from '@mantine/core';
import { COLORS } from '../../../../../../constants';
import { Info } from 'react-feather';

export const StyledSelect = styled(Select)`
  margin-top: 5px;

  input {
    background-color: ${COLORS.hdBackgroundGreyDark};
    color: ${COLORS.textSecondary};
    border: none;

    &:hover {
      cursor: pointer;
    }
    &::placeholder {
      color: ${COLORS.textSecondary};
    }
  }
`;

interface StyledMultiSelectProps extends MultiSelectProps {
  disabled?: boolean;
}

export const StyledMultiSelect = styled(MultiSelect)<StyledMultiSelectProps>`
  margin-top: 5px;

  .mantine-MultiSelect-input {
    background-color: ${COLORS.hdBackgroundGreyDark};
    color: ${COLORS.textSecondary};
    border: none;

    &:hover {
      cursor: pointer;
    }
    &::placeholder {
      color: ${COLORS.textSecondary};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    .mantine-MultiSelect-input {
      background-color: ${COLORS.disabledMultiselectBackgroundGrey};
      color: ${COLORS.disabledMultiselectGrey};
      border: none;
    }

    .mantine-Button-root {
      background-color: #F7F8F9;
    }
  `}
`;

export const StyledInfoIconWrapper = styled.div`
  display: flex;
  &:hover svg {
    circle {
      fill: ${COLORS.hdAccentBlue};
      stroke: ${COLORS.hdAccentBlue};
    }

    line {
      stroke: white;
    }
  }
`;

export const NotepadForegroundWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  padding-bottom: 0.5rem;
  background-color: ${COLORS.hdBackgroundWhite};
  border-radius: 10px;
  overflow: auto;

  hr {
    background-color: ${COLORS.hdBackgroundGreyDark};
    width: 100%;
    height: 3px;
    border-radius: 50px;
    border: none;
  }
`;

export const NotepadBackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: flex-end;
  /* padding-bottom: 2.25rem; */
`;

export const StyledSectionWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const StyledSection = styled.div`
  background-color: ${COLORS.hdBackgroundGreyLight};
  margin: 0.75rem 0.25rem;
  padding: 1rem;
  flex: 1 1 100%;
  border-radius: 3px;
`;

export const StyledTransparentSection = styled.div`
  margin: 1.5rem 0.25rem 0.75rem 0.25rem;
  flex: 1 1 100%;
  border-radius: 3px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const StyledTextarea = styled(Textarea)`
  textarea {
    background-color: #fafbfc;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 1rem 0.75rem;
    border: 2px solid #dfe1e6;
    border-radius: 3px;
    min-height: 200px;
  }
`;
