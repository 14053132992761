import { RelatedPerson } from '../types';

export const getRelatedPersonsByRelationTypes = (
  persons: RelatedPerson[],
  relations: string[]
) => {
  const matchingPersons: RelatedPerson[] = [];
  relations.forEach((relation) => {
    const filterRes = persons.filter((person) => person.relation === relation);
    matchingPersons.push(...filterRes);
  });
  return matchingPersons;
};
