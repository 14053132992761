import { updateAccount, updatePassword } from '../../api';
import { AuthToken, CreateProfileFormData } from '../../types';
import { redirect } from 'react-router-dom';
import { errorHandler } from '../../utils';

export const updateAccountAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as CreateProfileFormData;

    const res = await updateAccount(data, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return redirect(`/profile`);
  };

export const updatePasswordAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as CreateProfileFormData;

    const res = await updatePassword(data, token);

    return res;
  };
