import { useSubmit } from 'react-router-dom';

import {
  PageWrapper,
  FamilyHomeForm,
  TopBar,
  IconLink,
  Text,
} from '../../components';
import { createFormData } from '../../utils';
import { useViewportSize } from '@mantine/hooks';
import { COLORS } from '../../constants';
import { ArrowLeft } from 'react-feather';

export const FamilyHomeCreate = () => {
  const submit = useSubmit();
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const onSubmit = (data: any) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'post',
    });
  };

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to="/family-homes"
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Familjehem
              </Text>
            </IconLink>
          </>
        }
        right={<></>}
      />
      <FamilyHomeForm onSubmit={onSubmit} isMobile={isMobile} />
    </PageWrapper>
  );
};
