import dayjs from 'dayjs';
import { Note } from '../types';
import { CustomTooltip } from '../components/CustomTooltip';
import { NoteReportRepeat } from '../pages/Root/subs/Notes/subs/Notepad';

export const getPlacementNoteTableRowsAdmin = (notesList: Note[]) => {
  const noteRows =
    notesList && notesList.length > 0
      ? notesList.map((note, index) => ({
          id: index.toString(),
          cells: [
            {
              name: 'date',
              label: 'Datum',
              isSortable: true,
              value: note.createdAt,
              content: (
                <div
                  style={{
                    minWidth: '100px',
                  }}
                >
                  {dayjs(note.createdAt).format('YYYY-MM-DD')}
                </div>
              ),
            },
            {
              name: 'note',
              label: 'Anteckning',
              isSortable: false,
              value: note.text,
              content: (
                <CustomTooltip
                  cssBottom="-20px"
                  cssLeft="150px"
                  maxHeight="100px"
                  hoverText={note.text}
                >
                  <div
                    style={{
                      maxWidth: '250px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {note.text}
                  </div>
                </CustomTooltip>
              ),
            },
            {
              name: 'noteReportRepeat',
              label: 'Skall med i rapporter?',
              isSortable: true,
              value:
                note.noteReportRepeat === NoteReportRepeat.All
                  ? 'Alla nuvarande och framtida'
                  : note.noteReportRepeat === NoteReportRepeat.Never
                  ? 'Nej'
                  : 'Specifika rapporter',
              content:
                note.noteReportRepeat === NoteReportRepeat.All
                  ? 'Alla nuvarande och framtida'
                  : note.noteReportRepeat === NoteReportRepeat.Never
                  ? 'Nej'
                  : 'Specifika rapporter',
            },
            {
              name: 'reportCategory',
              label: 'Kategori',
              isSortable: true,
              value: note.formStep?.name || '-',
              content: note.formStep?.name || '-',
            },
          ],
        }))
      : [];

  return noteRows;
};
