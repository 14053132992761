import { Fragment, useState } from 'react';
import { ArrowLeft, Info } from 'react-feather';
import { Box, Group, Input, Textarea } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useLoaderData, useNavigate, useSubmit } from 'react-router-dom';

import {
  MobileTopBarWrapper,
  Spacer,
  Text,
  MobileBackgroundWrapper,
  PlacedButton,
  PageWrapper,
  TopBar,
  IconLink,
  Title,
  PrimaryButton,
  CancelButton,
  WarningModal,
} from '../../components';
import { COLORS } from '../../constants';
import { MonthlyReport, Note, Placement } from '../../types';
import {
  FinishedReportWrapperDesktop,
  FinishedReportWrapperMobile,
} from './styles';
import { ContentHeader, OverlaySaveButton } from './subs';
import { createFormData } from '../../utils';
import dayjs from 'dayjs';
import { StyledInfoIconWrapper } from '../Root/subs/Notes/subs/Notepad/styles';
import { CustomTooltip } from '../../components/CustomTooltip';

const NOTE_TOOLTIP_TEXT =
  'Dessa anteckningar kommer att läggas till i slutet av denna kategorin. <br><br><strong>Om du vill redigera dina anteckningar:</strong> <br>Gör det genom listan i anteckningsblocket eller genom listan under "Anteckningar"-fliken för en placerad!';

const SIGN_WARNING_MODAL_CONTENT = (
  <div>
    <p>
      Är du säker på att du vill signera rapporten? Om du går vidare kan du inte
      gå tillbaka och fortsätta ändra texten i den förhandsgranskade rapporten.
    </p>
    <p>
      Vi ber dig därför att gå igenom texten i rapporten ytterligare en gång för
      att säkerställa att du gjort alla önskade korrigeringar. Någonting som är
      lätt att glömma är att kontrollera signaturen längst ner i dokumentet.
      Dubbelkolla att signaturen stämmer, ändra annars.
    </p>
    <p>
      HumanDoc AB tillhandahåller enbart ett rapport- och handledningsstöd.
      Detta innebär att HumanDoc AB förutsätter att du läser igen hela rapporten
      och gör korrigeringar för att informationen ska vara överensstämmande. Det
      är viktigt att du gör detta utifrån ett etiskt och moraliskt perspektiv.
    </p>
    <p>
      HumanDoc AB tar inte ansvar över det rapportinnehåll som du signerar och
      väljer att lämna ifrån dig till ansvarig socialtjänst.
    </p>
  </div>
);

const TITLE_TOOLTIP_TEXT =
  'Rubriken som kommer att användas i rapporten. <br><br>Om denna ruta lämnas tom används standardrubriken för rapporten istället.';

export const Signing = () => {
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const submit = useSubmit();
  const { placement, content, reportId, reportNotes, report } =
    useLoaderData() as {
      placement: Placement;
      content: { type: string; text: string }[];
      reportId: string;
      reportNotes: Note[];
      report: MonthlyReport;
    };
  const [editableContent, setEditableContent] = useState(content);
  const [signConfirmIsOpen, setSignConfirmIsOpen] = useState(false);
  const isMobile = width === 0 || width < 500;
  const [titleInput, setTitleInput] = useState(report.alternativeName ?? '');

  const handleTextAreaChange = (textValue: string, elementIndex: number) => {
    setEditableContent((prevState) => {
      const newState = [...prevState];
      newState[elementIndex].text = textValue;
      return newState;
    });
  };

  const createFormDataWithAltName = (content: any): FormData => {
    const data = { alternativeName: titleInput, content: content };
    return createFormData(data);
  };

  const save = () => {
    const formData = createFormDataWithAltName(editableContent);
    submit(formData, { method: 'put' });
  };

  const getRelevantNotesStringForCategorySection = (section: {
    type: string;
    text: string;
  }) => {
    const relevantNotesForCategory = reportNotes
      .filter((note) => note.formStep?.name === section.text)
      .map((note) => note.text);

    if (!relevantNotesForCategory || relevantNotesForCategory.length < 1)
      return null;

    const relevantNotesString = relevantNotesForCategory.join('\r\n');
    return relevantNotesString;
  };

  const addNotesToEditableContent = () => {
    const editableContentWithNotes = [...editableContent];
    for (const [index, section] of editableContentWithNotes.entries()) {
      if (section.type === 'Body') continue;
      else if (section.type !== 'Body') {
        const relevantNotesString =
          getRelevantNotesStringForCategorySection(section);

        if (!relevantNotesString) continue;

        editableContentWithNotes[index + 1].text +=
          relevantNotesString + '\r\n\r\n';
      }
    }
    return editableContentWithNotes;
  };

  if (isMobile) {
    return (
      <MobileBackgroundWrapper>
        <MobileTopBarWrapper direction="row">
          <Box sx={{ flexDirection: 'column' }}>
            <Text size="18px">{report.name}</Text>
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            <Text size="16px" weight="400">
              {placement?.birthDay &&
                dayjs(new Date(placement?.birthDay)).format('YYYY-MM-DD')}
            </Text>
            <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
            <Text size="16px" weight="400">
              {`${placement?.familyHome?.name}, ${placement?.familyHome?.city}`}
            </Text>
          </Box>
          <Spacer minSpace="0.5rem" />
          <PlacedButton onClick={() => navigate(`/placements/${placement.id}`)}>
            Tillbaka
          </PlacedButton>
        </MobileTopBarWrapper>
        <FinishedReportWrapperMobile>
          <Spacer minSpace="3rem" maxSpace="3rem" />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'start',
            }}
          >
            <CustomTooltip
              hoverText={TITLE_TOOLTIP_TEXT}
              width="300px"
              maxHeight="150px"
              cssBottom="30px"
              cssLeft="150px"
            >
              <StyledInfoIconWrapper>
                <Info
                  color={COLORS.hdAccentBlue}
                  style={{ marginRight: '0.3rem' }}
                  size={17}
                />
                <ContentHeader text={'Rapportens rubrik'} size="18px" />
              </StyledInfoIconWrapper>
            </CustomTooltip>
          </div>
          <Spacer minSpace="0.25rem" maxSpace="0.25rem" />
          <Input
            placeholder={report.name}
            defaultValue={titleInput ? titleInput : report.name}
            style={{ width: '100%' }}
            size="xl"
            onChange={(e: any) => {
              e.target.value != report.name
                ? setTitleInput(e.target.value)
                : setTitleInput('');
            }}
          />
          {content.map((element, index) => {
            let relevantNotesString: null | string = null;

            if (element.type === 'Body' && index >= 1) {
              relevantNotesString = getRelevantNotesStringForCategorySection(
                content[index - 1]
              );
            }
            return (
              <Fragment key={index}>
                {element.type !== 'Body' && (
                  <>
                    <Spacer minSpace="3rem" maxSpace="3rem" />
                    <ContentHeader text={element.text} size="18px" />
                    <Spacer minSpace="0.25rem" maxSpace="0.25rem" />
                  </>
                )}
                {element.type === 'Body' && (
                  <Textarea
                    defaultValue={element.text}
                    style={{ width: '100%' }}
                    autosize
                    onChange={(e: any) =>
                      handleTextAreaChange(e.target.value, index)
                    }
                  />
                )}
                {element.type === 'Body' && relevantNotesString && (
                  <>
                    <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'start',
                      }}
                    >
                      <CustomTooltip
                        hoverText={NOTE_TOOLTIP_TEXT}
                        width="300px"
                        maxHeight="250px"
                        cssBottom="30px"
                        cssLeft="150px"
                      >
                        <StyledInfoIconWrapper>
                          <Info
                            color={COLORS.hdAccentBlue}
                            style={{ marginRight: '0.3rem' }}
                            size={17}
                          />
                          <ContentHeader
                            text={`Anteckningar för ${content[index - 1].text}`}
                          />
                        </StyledInfoIconWrapper>
                      </CustomTooltip>
                    </div>
                    <Spacer minSpace="0.25rem" maxSpace="0.25rem" />

                    <Textarea
                      defaultValue={relevantNotesString}
                      style={{ width: '100%' }}
                      autosize
                      disabled
                    />
                  </>
                )}
              </Fragment>
            );
          })}

          <Spacer minSpace="1.5rem" />

          <Group position="center" style={{ width: '100%' }}>
            <PrimaryButton
              fullWidth
              background={COLORS.hdAccentGreen}
              onClick={() => setSignConfirmIsOpen(true)}
            >
              Signera rapport
            </PrimaryButton>
            <CancelButton fullWidth onClick={save}>
              Spara
            </CancelButton>
          </Group>
          <Spacer minSpace="10rem" maxSpace="10rem" />
          <WarningModal
            isOpen={signConfirmIsOpen}
            onCancel={() => setSignConfirmIsOpen(false)}
            onContinue={() => {
              const editableContentWithNotes = addNotesToEditableContent();
              const formData = createFormDataWithAltName(
                editableContentWithNotes
              );
              submit(formData, {
                method: 'put',
                action: `/placements/${placement.id}/report/${reportId}/sign`,
              });
            }}
            title="Är du säker på att du vill fortsätta?"
            continueText="Ja, jag vill signera rapporten"
            content={SIGN_WARNING_MODAL_CONTENT}
          />
          <OverlaySaveButton onClick={save} text="Spara" />
        </FinishedReportWrapperMobile>
      </MobileBackgroundWrapper>
    );
  }

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to={`/placements/${placement.id}`}
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Tillbaka
              </Text>
            </IconLink>

            <Title as="h1" type="secondary" weight="500">
              {placement.familyHome
                ? `${
                    report.alternativeName
                      ? report.alternativeName
                      : report.name
                  } - ${placement.familyHome.name}, ${
                    placement.familyHome.city
                  }`
                : `${report.name}`}
            </Title>
          </>
        }
        right={
          <>
            <PrimaryButton
              background={COLORS.hdAccentGreen}
              onClick={() => setSignConfirmIsOpen(true)}
            >
              Signera rapport
            </PrimaryButton>
            <CancelButton onClick={save}>Spara</CancelButton>
          </>
        }
      />
      <FinishedReportWrapperDesktop>
        <Spacer minSpace="3rem" maxSpace="3rem" />
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'start',
          }}
        >
          <CustomTooltip
            hoverText={TITLE_TOOLTIP_TEXT}
            width="300px"
            maxHeight="150px"
            cssBottom="30px"
            cssLeft="150px"
          >
            <StyledInfoIconWrapper>
              <Info
                color={COLORS.hdAccentBlue}
                style={{ marginRight: '0.3rem' }}
                size={17}
              />
              <ContentHeader text={'Rapportens rubrik'} size="18px" />
            </StyledInfoIconWrapper>
          </CustomTooltip>
        </div>
        <Spacer minSpace="0.25rem" maxSpace="0.25rem" />
        <Input
          placeholder={report.name}
          defaultValue={titleInput ? titleInput : report.name}
          style={{ width: '100%' }}
          size="xl"
          onChange={(e: any) => {
            e.target.value != report.name
              ? setTitleInput(e.target.value)
              : setTitleInput('');
          }}
        />

        {content.map((element, index) => {
          let relevantNotesString: null | string = null;

          if (element.type === 'Body' && index >= 1) {
            relevantNotesString = getRelevantNotesStringForCategorySection(
              content[index - 1]
            );
          }
          return (
            <Fragment key={index}>
              {element.type !== 'Body' && (
                <>
                  <Spacer minSpace="3rem" maxSpace="3rem" />
                  <ContentHeader text={element.text} size="18px" />
                  <Spacer minSpace="0.25rem" maxSpace="0.25rem" />
                </>
              )}
              {element.type === 'Body' && (
                <Textarea
                  defaultValue={element.text}
                  style={{ width: '100%' }}
                  autosize
                  onChange={(e: any) =>
                    handleTextAreaChange(e.target.value, index)
                  }
                />
              )}
              {element.type === 'Body' && relevantNotesString && (
                <>
                  <Spacer minSpace="0.5rem" maxSpace="0.5rem" />
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'start',
                    }}
                  >
                    <CustomTooltip
                      hoverText={NOTE_TOOLTIP_TEXT}
                      width="300px"
                      maxHeight="250px"
                      cssBottom="30px"
                      cssLeft="150px"
                    >
                      <StyledInfoIconWrapper>
                        <Info
                          color={COLORS.hdAccentBlue}
                          style={{ marginRight: '0.3rem' }}
                          size={17}
                        />
                        <ContentHeader
                          text={`Anteckningar för ${content[index - 1].text}`}
                        />
                      </StyledInfoIconWrapper>
                    </CustomTooltip>
                  </div>
                  <Spacer minSpace="0.25rem" maxSpace="0.25rem" />

                  <Textarea
                    defaultValue={relevantNotesString}
                    style={{ width: '100%' }}
                    autosize
                    disabled
                  />
                </>
              )}
            </Fragment>
          );
        })}

        <Spacer minSpace="1.5rem" />
        <WarningModal
          isOpen={signConfirmIsOpen}
          onCancel={() => setSignConfirmIsOpen(false)}
          onContinue={() => {
            const editableContentWithNotes = addNotesToEditableContent();
            const formData = createFormDataWithAltName(
              editableContentWithNotes
            );
            submit(formData, {
              method: 'put',
              action: `/placements/${placement.id}/report/${reportId}/sign`,
            });
          }}
          title="Är du säker på att du vill fortsätta?"
          continueText="Ja, jag vill signera rapporten"
          content={SIGN_WARNING_MODAL_CONTENT}
        />
        <OverlaySaveButton onClick={save} text="Spara" />
      </FinishedReportWrapperDesktop>
    </PageWrapper>
  );
};
