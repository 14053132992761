import { ArrowDown, Trash2 } from 'react-feather';
import { getPDFReport } from '../../api';
import { COLORS } from '../../constants';
import { MonthlyReport, ReportStatus, TableRow } from '../../types';

export const formatReportRows = (
  data: MonthlyReport[],
  token: string | null,
  deleteClick: (id: string) => void
): TableRow[] =>
  data.map((report) => ({
    id: report.id,
    cells: [
      {
        name: 'name',
        label: 'Namn',
        isSortable: true,
        value: report.alternativeName ? report.alternativeName : report.name,
        content: report.alternativeName ? report.alternativeName : report.name,
      },
      {
        name: 'month',
        label: 'Månad',
        isSortable: true,
        value: report.yearMonth,
        content: `${report.yearMonth.slice(0, 4)}-${report.yearMonth.slice(
          4,
          6
        )}`,
      },
      {
        name: 'createdBy',
        label: 'Skapad av',
        isSortable: true,
        value: report.createdBy,
        content: report.createdBy,
      },
      {
        name: 'download',
        label: 'Ladda ner',
        isSortable: false,
        content:
          report.status === ReportStatus.Signed ||
          (report.status as unknown as number) === 4 ? ( // Deprecated ReportStatus.Invoiced = 4
            <ArrowDown
              size={18}
              color={COLORS.textSecondary}
              strokeWidth={'1px'}
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                const pdf = await getPDFReport(report.id, token);
                const linkSource = `data:application/pdf;base64,${pdf}`;
                const downloadLink = document.createElement('a');
                const fileName = 'report.pdf';
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
              }}
            />
          ) : (
            <div />
          ),
      },
      {
        name: 'delete',
        label: 'Ta bort',
        isSortable: false,
        content:
          report?.status === ReportStatus.Generated ||
          report?.status === ReportStatus.Signed ||
          (report?.status as unknown as number) === 4 || // Deprecated ReportStatus.Invoiced = 4
          report?.isInvoiced ? (
            ''
          ) : (
            <Trash2
              size={'20px'}
              onClick={() => {
                deleteClick(report.id);
              }}
              style={{ cursor: 'pointer' }}
            />
          ),
      },
    ],
  }));

// export const formatReportRowsForGlobalAdmin = (
//   data: MonthlyReport[],
//   tenants: Tenant[]
// ): TableRow[] =>
//   data.map((report) => ({
//     id: report.id,
//     cells: [
//       {
//         name: 'name',
//         label: 'Namn',
//         isSortable: true,
//         value: report.name,
//         content: report.name,
//       },
//       {
//         name: 'month',
//         label: 'Månad',
//         isSortable: true,
//         value: report.yearMonth,
//         content: `${report.yearMonth.slice(0, 4)}-${report.yearMonth.slice(
//           4,
//           6
//         )}`,
//       },
//       {
//         name: 'createdBy',
//         label: 'Skapad av',
//         isSortable: true,
//         value: report.createdBy,
//         content: report.createdBy,
//       },
//       {
//         name: 'createdBy',
//         label: 'Skapad av',
//         isSortable: true,
//         value: report.createdBy,
//         content: report.createdBy,
//       },
//       // {
//       //   name: 'tenant',
//       //   label: 'Kund',
//       //   isSortable: true,
//       //   value: report.,
//       //   content: report.createdBy,
//       // },
//     ],
//   }));
