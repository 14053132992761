import { useLoaderData, useSubmit } from 'react-router-dom';

import {
  PageWrapper,
  FamilyHomeForm,
  IconLink,
  Text,
  TopBar,
  Title,
} from '../../components';
import { Consultant, FamilyHome } from '../../types';
import { createFormData } from '../../utils';
import { useViewportSize } from '@mantine/hooks';
import { ArrowLeft } from 'react-feather';
import { COLORS } from '../../constants';

export const FamilyHomeEdit = () => {
  const { familyHome } = useLoaderData() as {
    familyHome: FamilyHome;
    consultants: Consultant[];
  };
  const submit = useSubmit();
  const { width } = useViewportSize();
  const isMobile = width < 500;

  const onSubmit = (data: any) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'post',
    });
  };

  return (
    <PageWrapper>
      <TopBar
        left={
          <>
            <IconLink
              to={`/family-homes/${familyHome.id}`}
              iconBefore={
                <ArrowLeft
                  size={18}
                  color={COLORS.textSecondary}
                  strokeWidth={'1.5px'}
                />
              }
            >
              <Text weight="500" size="13px">
                Tillbaka
              </Text>
            </IconLink>
          </>
        }
        right={<></>}
      />
      <FamilyHomeForm
        onSubmit={onSubmit}
        familyHome={familyHome}
        isMobile={isMobile}
      />
    </PageWrapper>
  );
};
