import { AuthToken, CreateConsultantFormData } from '../../types';
import { errorHandler } from '../../utils';

export const createConsultant = async (
  data: any,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Consultants`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while creating consultant', error);
      return error;
    });

export const createConsultantAction =
  ({ token }: AuthToken) =>
  async ({ request }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as CreateConsultantFormData;

    const res = await createConsultant(data, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
