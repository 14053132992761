import { CheckButton, Title } from '../../../../components';
import { StyledSection } from '../../styles';

interface SexSectionProps {
  sex: any[];
  handleSex: any;
}

export const SexSection = ({ sex, handleSex }: SexSectionProps) => {
  return (
    <StyledSection>
      <Title as="h4" type="secondary" weight="600">
        Kön
      </Title>
      <>
        {sex.map((item) => {
          return (
            <CheckButton
              key={item.name}
              selected={item.selected}
              value={item.name}
              textalign={'left'}
              onClick={handleSex}
            >
              {item.name}
            </CheckButton>
          );
        })}
      </>
    </StyledSection>
  );
};
