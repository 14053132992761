import { Table as MantineTable } from '@mantine/core';

import { COLORS } from '../../constants';

import { StyledTableWrapper } from './styles';
import { useEffect, useMemo, useState } from 'react';
import { TableRow } from '../../types';
import { ChevronDown, ChevronUp } from 'react-feather';

interface TableProps {
  rows: TableRow[];
  buttonMaxWidth?: string;
  paddingCss?: string;
  sortingOrder?: SortOrder;
  sortingField?: string;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  Default = 'default',
}

const getColumnsFromRowData = (rows: TableRow[]) => {
  if (!rows || rows === undefined || !Array.isArray(rows) || rows.length < 1)
    return [];

  return [...rows].shift()?.cells.map((cell) => cell);
};

const Row = ({ id, cells, onClick }: TableRow) => {
  return (
    <tr key={`row-${id}`} style={!onClick ? { cursor: 'default' } : {}}>
      {cells.map((cell) => (
        <td
          key={`${cell.name}-${id}`}
          onClick={
            typeof cell.content === 'string' || typeof cell.content === 'number'
              ? onClick
              : () => null
          }
        >
          {cell.content}
        </td>
      ))}
    </tr>
  );
};

const getSortChevron = (sortOrder: SortOrder) => {
  switch (sortOrder) {
    case SortOrder.DESC:
      return <ChevronDown size={20} style={{ margin: '-5px 0' }} />;
    case SortOrder.ASC:
      return <ChevronUp size={20} style={{ margin: '-5px 0' }} />;
    default:
      return (
        <ChevronDown
          size={20}
          style={{ margin: '-5px 0', visibility: 'hidden' }}
        />
      );
  }
};

export const Table = ({
  rows,
  buttonMaxWidth,
  paddingCss,
  sortingOrder = SortOrder.Default,
  sortingField = '',
}: TableProps) => {
  const [sortField, setSortField] = useState(sortingField);
  const [sortOrder, setSortOrder] = useState(sortingOrder);
  const [sortedRows, setSortedRows] = useState(rows);

  const handleSortClick = (field: string) => {
    const newSortOrder =
      sortField === field && sortOrder !== SortOrder.Default
        ? sortOrder === SortOrder.DESC
          ? SortOrder.ASC
          : SortOrder.Default
        : SortOrder.DESC;

    setSortField(field);
    setSortOrder(newSortOrder);
  };

  useEffect(() => {
    if (sortOrder === SortOrder.Default) {
      setSortedRows(rows);
      return;
    }

    const rowsCopy = [...rows];

    const newSortedRows = rowsCopy.sort((a, b) => {
      const cellA = a.cells.find((cell) => cell.name === sortField)?.value;
      const cellB = b.cells.find((cell) => cell.name === sortField)?.value;

      if (cellA === undefined && cellB === undefined) return 0;
      if (cellA === undefined) return 1;
      if (cellB === undefined) return -1;

      const compare =
        cellA?.toString().localeCompare(cellB?.toString()) *
        (sortOrder === SortOrder.DESC ? 1 : -1);
      return compare;
    });

    setSortedRows(newSortedRows);
  }, [sortField, sortOrder, rows]);

  // The reason for inline styling here is that mantine
  // overrides all class styling on the native th element
  const columns = useMemo(() => getColumnsFromRowData(rows), [rows]);

  const headings = columns?.map((column: any, index: any) => (
    <th
      style={{
        fontWeight: 500,
        fontSize: '12px',
        color: COLORS.textDisabled,
        cursor: 'pointer',
      }}
      key={`${index}-${column.name}`}
      onClick={() => column.isSortable && handleSortClick(column.name)}
    >
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          WebkitUserSelect: 'none',
          userSelect: 'none',
        }}
      >
        {column.label}{' '}
        {column.isSortable &&
          getSortChevron(
            column.name === sortField ? sortOrder : SortOrder.Default
          )}
      </div>
    </th>
  ));

  return (
    <StyledTableWrapper buttonMaxWidth={buttonMaxWidth} paddingCss={paddingCss}>
      {sortedRows && sortedRows.length > 0 ? (
        <MantineTable highlightOnHover fontSize={'md'}>
          <thead>
            <tr>{headings}</tr>
          </thead>
          <tbody>
            {sortedRows.map((row) => (
              <Row
                key={`row-${row.id}`}
                id={row.id}
                cells={row.cells}
                onClick={row.onClick}
              />
            ))}
          </tbody>
        </MantineTable>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <i>Här finns inget... än!</i>
        </div>
      )}
    </StyledTableWrapper>
  );
};
