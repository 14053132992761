import { useEffect, useState } from 'react';
import { Menu } from 'react-feather';
import { Outlet, useNavigation } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

import { SideNav } from './subs';
import { COLORS } from '../../constants';
import { PrivateRoutes } from '../../utils';
import {
  MenuWrapper,
  StyledOutletWrapper,
  StyledDrawer,
  StyledOverlay,
} from './styles';
import { Notes } from './subs/Notes';
import { useAuth } from '../../hooks';
import { UserRole } from '../../types';
import { useNoteContext } from '../../contexts/NoteContext';

export const Root = () => {
  const { state } = useNavigation();
  const { state: authState } = useAuth();
  const isMobile = useMediaQuery('(max-width: 820px)');
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const { notepadOpen } = useNoteContext();

  useEffect(() => {
    notepadOpen && closeDrawer();
  }, [notepadOpen]);

  return (
    <PrivateRoutes>
      {isMobile ? (
        <StyledDrawer
          opened={!notepadOpen ? open : false}
          onClose={closeDrawer}
          withCloseButton={true}
          trapFocus={true}
          size={'15rem'}
          styles={{
            body: { height: '100%' },
            header: {
              backgroundColor: COLORS.hdBackgroundGreenDark,
              marginBottom: 0,
            },
          }}
        >
          <SideNav onNavigate={closeDrawer} />
        </StyledDrawer>
      ) : (
        <SideNav onNavigate={closeDrawer} />
      )}
      <StyledOutletWrapper id="content" state={state}>
        {isMobile && (
          <MenuWrapper>
            <Menu size={20} strokeWidth={2.5} onClick={openDrawer} />
          </MenuWrapper>
        )}

        {state !== 'idle' && <StyledOverlay blur={1} />}

        {authState.user?.role === UserRole.Consultant && (
          <Notes isMobile={isMobile} token={authState.token} />
        )}

        <Outlet />
      </StyledOutletWrapper>
    </PrivateRoutes>
  );
};
