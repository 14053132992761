import styled from '@emotion/styled';

const StyledMobileTopBarWrapper = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  margin: 1.5rem;
`;

export const MobileTopBarWrapper = ({ children, direction }: any) => (
  <StyledMobileTopBarWrapper direction={direction}>
    {children}
  </StyledMobileTopBarWrapper>
);
