import { getTenantById, getTenants } from '../../api';
import { AuthToken } from '../../types';
import { errorHandler } from '../../utils';

export const tenantLoader =
  ({ token }: AuthToken) =>
  async () => {
    const res = await getTenants(token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };

export const tenantDetailLoader =
  ({ token }: AuthToken) =>
  async ({ params }: any) => {
    const res = await getTenantById(params.tenantId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    return res;
  };
