import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { StyledPlacedButton } from './styles';

interface PlacedButtonProps {
  onClick: () => void;
  children: EmotionJSX.Element | string;
}

export const PlacedButton = ({ onClick, children }: PlacedButtonProps) => (
  <StyledPlacedButton onClick={onClick}>{children}</StyledPlacedButton>
);
