import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { COLORS } from '../../constants';

interface IconLinkProps {
  to?: string;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: ${COLORS.textSecondary};
`;

const StyledLinkText = styled.div`
  margin: -1px 0.5rem 0;
  padding-bottom: 0.5rem;
`;

export const IconLink = ({
  to,
  iconBefore,
  iconAfter,
  children,
  onClick,
}: IconLinkProps) => {
  const navigate = useNavigate();

  return (
    <StyledLink
      to={to ?? '/'}
      onClick={(e) => {
        onClick && onClick(e);
        !to && navigate(-1);
      }}
    >
      <div>{iconBefore && iconBefore}</div>
      <StyledLinkText>{children}</StyledLinkText>
      <div>{iconAfter && iconAfter}</div>
    </StyledLink>
  );
};
