import { StyledSideBar } from './styles';
import { NavigationLinks, BrandBanner } from './subs';

interface SideNavProps {
  onNavigate: () => void;
}

export const SideNav = ({ onNavigate }: SideNavProps) => (
  <StyledSideBar>
    <BrandBanner onNavigate={onNavigate}>
      <NavigationLinks onNavigate={onNavigate} />
    </BrandBanner>
  </StyledSideBar>
);
