import { RouteObject } from 'react-router-dom';
import {
  Consultants,
  ConsultantDetail,
  ConsultantCreate,
  ConsultantEdit,
  ErrorPage,
} from '../pages';
import {
  consultantLoader,
  consultantDetailLoader,
  createConsultantAction,
  updateConsultantAction,
  deleteConsultantAction,
  updateRoleAction,
} from '../api';

export const consultantRoutes = (
  token: string | null = null
): RouteObject[] => [
  {
    path: 'consultants',
    element: <Consultants />,
    errorElement: <ErrorPage />,
    loader: consultantLoader({ token }),
  },
  {
    path: 'consultants/:consultantId',
    element: <ConsultantDetail />,
    errorElement: <ErrorPage />,
    loader: consultantDetailLoader({ token }),
  },
  {
    path: 'consultants/create',
    element: <ConsultantCreate />,
    errorElement: <ErrorPage />,
    action: createConsultantAction({ token }),
  },
  {
    path: 'consultants/:consultantId/edit',
    element: <ConsultantEdit />,
    errorElement: <ErrorPage />,
    loader: consultantDetailLoader({ token }),
    action: updateConsultantAction({ token }),
  },
  {
    path: 'consultants/:consultantId/delete',
    errorElement: <ErrorPage />,
    action: deleteConsultantAction({ token }),
  },
  {
    path: 'consultants/:consultantId/update-role/:roleId',
    errorElement: <ErrorPage />,
    action: updateRoleAction({ token }),
  },
];
