import {
  LoggedInAsText,
  StyledBrandBanner,
  StyledLogoutWrapper,
} from './styles';
import {
  Spacer,
  LogoutButton,
  ProfileButton,
} from '../../../../../../components';

import logo from '../../../../../../hd-logo-white.svg';
import { StyledNavLink } from '../NavigationLinks/styles';
import { useAuth } from '../../../../../../hooks';
import { Box, Loader } from '@mantine/core';
import { useNavigation } from 'react-router-dom';
import { COLORS } from '../../../../../../constants';

interface BrandBannerProps {
  children: React.ReactNode;
  onNavigate?: () => void;
}

export const BrandBanner = ({
  children,
  onNavigate,
}: BrandBannerProps): JSX.Element => {
  const { state: authState } = useAuth();
  const { state } = useNavigation();

  return (
    <StyledBrandBanner>
      <Box
        style={{
          position: 'relative',
          width: '100%',
          height: 'fit-content', // Adjust the height to fit the loader
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          padding: '25px',
          zIndex: 1000,
          visibility: state === 'idle' ? 'hidden' : 'visible',
        }}
      >
        <Loader variant="dots" color={COLORS.brandPink} size="xl" />
      </Box>
      <img src={logo} alt={'HumanDoc logo'} style={{ width: '11rem' }}></img>
      {children}
      <Spacer />
      <StyledLogoutWrapper>
        <LoggedInAsText>{authState?.user?.tenantName}</LoggedInAsText>
        <LoggedInAsText>{authState?.user?.email}</LoggedInAsText>
        <Spacer minSpace="0.5rem" />
        <StyledNavLink to={'/profile'} onClick={onNavigate}>
          <ProfileButton />
        </StyledNavLink>
        <LogoutButton />
      </StyledLogoutWrapper>
    </StyledBrandBanner>
  );
};
