export const initialPrimaryContact = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  primaryContact: true,
  relation: '',
  birthDay: '',
};

export const initialRelatedPerson = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  primaryContact: false,
  relation: '',
  birthDay: '',
};
