export const login = async (data: any): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Account/Login`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while logging in to account', error);
      return error;
    });

export const loginAction = async ({ request }: any) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);

  const res = await login({
    ...data,
    rememberMe: data.rememberMe ? true : false,
  });

  if (!res.ok) {
    //Should this even be here? maybe we dont want to let user know this happened for security reasons?
    //eslint-disable-next-line
    console.error('Error logging in');
  }

  let token;

  try {
    token = await res.json();
  } catch (error) {
    throw new Error('Failed parsing json');
  }

  return token;
};
