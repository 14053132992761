import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import { getReportById, resetReport, saveAnswer } from '../api';
import { MonthlyReport, ReportCategory } from '../types';
import { errorHandler } from '../utils';

interface AutosaveContextProps {
  addPendingChange: (change: FormData) => Promise<void>;
  isSaving: boolean;
  currentReport: MonthlyReport;
  setCurrentReport: React.Dispatch<React.SetStateAction<MonthlyReport>>;
  refreshReport: () => Promise<void>;
  resetCurrentReport: () => Promise<void>;
}

const AutosaveContext = createContext<AutosaveContextProps | undefined>(
  undefined
);

interface AutosaveProviderProps {
  children: ReactNode;
  token: string | null;
  report: MonthlyReport;
}

export const AutosaveProvider: React.FC<AutosaveProviderProps> = ({
  children,
  token,
  report,
}) => {
  const [pendingChanges, setPendingChanges] = useState<
    { formData: FormData; resolve: () => void; reject: (error: any) => void }[]
  >([]);
  const [currentReport, setCurrentReport] = useState<MonthlyReport>(report);
  const [isSaving, setIsSaving] = useState(false);
  const [allChangesAreComplete, setAllChangesAreComplete] = useState(true);

  useEffect(() => {
    if (pendingChanges.length > 0 && allChangesAreComplete) {
      setAllChangesAreComplete(false);
      startAutosaveLoop();
    }
  }, [pendingChanges, allChangesAreComplete]);

  const startAutosaveLoop = async () => {
    while (pendingChanges.length > 0) {
      if (!isSaving) {
        setIsSaving(true);
        const nextChange = pendingChanges.shift();
        if (nextChange) {
          await autosaveAnswer(nextChange);
        }
        setIsSaving(false);

        pendingChanges.length === 0 && setAllChangesAreComplete(true);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
  };

  const autosaveAnswer = async ({
    formData,
    resolve,
    reject,
  }: {
    formData: FormData;
    resolve: () => void;
    reject: (error: any) => void;
  }) => {
    const { reportId, dropDownValues, ...rest } = Object.fromEntries(
      formData
    ) as any;
    const answerData = {
      ...rest,
      description: JSON.parse(rest.description),
      estimateValue: Number.parseInt(rest.estimateValue),
      numberValue: Number.parseInt(rest.numberValue),
      sortOrder: Number.parseInt(rest.sortOrder),
      dateValue: rest.dateValue === 'null' ? null : rest.dateValue,
      dateValues: JSON.parse(rest.dateValues),
      group: rest.dateValue === 'null' ? null : rest.dateValue,
    };

    setIsSaving(true);
    try {
      const response = await saveAnswer(token, reportId, answerData);
      if (response.ok) {
        const updatedReport = patchReport(currentReport, await response.json());
        setCurrentReport(updatedReport);
        resolve();
      } else {
        console.error('Failed to autosave answer');
        reject(new Error('Failed to autosave answer'));
      }
    } catch (error) {
      console.error('Error autosaving answer:', error);
      reject(error);
    } finally {
      setIsSaving(false);
    }
  };

  const addPendingChange = (change: FormData): Promise<void> => {
    return new Promise((resolve, reject) => {
      setPendingChanges((prev) => [
        ...prev,
        { formData: change, resolve, reject },
      ]);
    });
  };

  const patchReport = (
    report: MonthlyReport,
    savedData: any
  ): MonthlyReport => {
    const updatedReport = { ...report };
    const categoryIndex = updatedReport.formData.findIndex(
      (category) => category.id === savedData.id
    );
    if (categoryIndex === -1) throw Error('Autosave: category not found');

    const sectionIndex = updatedReport.formData[
      categoryIndex
    ].sections.findIndex((section) => section.id === savedData.sections[0].id);

    if (sectionIndex === -1) throw Error('Autosave: section not found');

    const questionIndex = updatedReport.formData[categoryIndex].sections[
      sectionIndex
    ].questions.findIndex(
      (question) => question.id === savedData.sections[0].questions[0].id
    );

    if (sectionIndex === -1) throw Error('Autosave: question not found');

    const { description } =
      updatedReport.formData[categoryIndex].sections[sectionIndex].questions[
        questionIndex
      ];

    // result from autosaving does not return description
    savedData.sections[0].questions[0].description = description;

    updatedReport.formData[categoryIndex].sections[sectionIndex].questions[
      questionIndex
    ] = savedData.sections[0].questions[0];

    return updatedReport;
  };

  const refreshReport = async () => {
    try {
      const reportRes = await getReportById(token, currentReport.id);

      if (reportRes && !reportRes.ok) {
        throw await errorHandler(reportRes);
      }

      const refreshedReport = await reportRes.json();
      setCurrentReport(refreshedReport as unknown as MonthlyReport);
    } catch (error) {
      console.error('Failed to refresh report:', error);
    }
  };

  const resetCurrentReport = async () => {
    try {
      const res = await resetReport(token, currentReport.id);

      if (!res.ok) {
        throw await errorHandler(res);
      }

      const resettedFormData = await res.json();
      const resettedReport = currentReport;

      resettedReport.formData = resettedFormData as unknown as ReportCategory[];
      setCurrentReport(resettedReport);
    } catch (error) {
      console.error('Failed to reset report:', error);
    }
  };

  return (
    <AutosaveContext.Provider
      value={{
        addPendingChange,
        isSaving,
        currentReport,
        setCurrentReport,
        refreshReport,
        resetCurrentReport,
      }}
    >
      {children}
    </AutosaveContext.Provider>
  );
};

export const useAutosaveContext = (): AutosaveContextProps => {
  const context = useContext(AutosaveContext);
  if (!context) {
    throw new Error('useAutosave must be used within an AutosaveProvider');
  }
  return context;
};
