import React, { useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setMobile] = useState<boolean>();

  React.useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Return the width so we can use it in our components

  return isMobile;
};
