export const createPlacement = async (
  data: any,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Placements`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error('something went wrong while creating placement', error);
      return error;
    });
