import { DynamicTextSelect } from '../DynamicTextSelect';
import { GreenRed } from '../GreenRed';

interface DropDownGreenRedProps {
  selectValue?: string;
  optionValue?: string;
  selectOptions?: string[];
  dropdownOnChange: (value: string) => void;
  greenRedOnChange: (value: string) => void;
}

export const DropDownGreenRed = ({
  selectValue,
  optionValue,
  selectOptions,
  dropdownOnChange,
  greenRedOnChange,
}: DropDownGreenRedProps) => {
  return (
    <>
      <DynamicTextSelect
        disabled={optionValue === 'NotApplicable'}
        value={selectValue}
        options={selectOptions}
        onChange={dropdownOnChange}
      />
      <GreenRed onChange={greenRedOnChange} value={optionValue} />
    </>
  );
};
