import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { COLORS } from '../../../../../../constants';

export const StyledReportProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 0.5rem;
`;

export const StyledSectionRowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1rem;
  gap: 15px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const StyledRPButton = styled(Button)`
  background-color: ${COLORS.hdBackgroundGreenDark};
  border: 1px solid ${COLORS.textPrimary};
`;

export const ReportProgressButton = createPolymorphicComponent<
  'button',
  ButtonProps
>(StyledRPButton);
