import { ReportCategory } from '../../../../types';
import { StyledOverlay } from './styles';
import { ReportProgress } from './subs';

interface ProgressOverlayProps {
  onGenerateClick: () => void;
  categories: ReportCategory[];
  placementId: string;
}

export const ProgressOverlay = ({
  onGenerateClick,
  categories,
  placementId,
}: ProgressOverlayProps) => {
  return (
    <StyledOverlay>
      <ReportProgress
        categories={categories}
        onGenerateClick={onGenerateClick}
        placementId={placementId}
      />
    </StyledOverlay>
  );
};
