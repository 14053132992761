import { Group, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import dayjs from 'dayjs';
import { useState } from 'react';
import { RelatedPerson } from '../../types';

interface DateOfBirthProps {
  form: UseFormReturnType<any>;
  index?: number;
  formListName?: string;
  person?: RelatedPerson;
  disabled?: boolean;
}

export const DateOfBirth = ({
  form,
  index,
  formListName,
  person = undefined,
  disabled = false,
}: DateOfBirthProps) => {
  //Handling nested forms and list of form properties
  const formPath = `${formListName ? formListName + '.' : ''}${
    index !== undefined ? index + '.' : ''
  }birthDay`;

  let initialDate: Date | undefined;

  if (person) {
    initialDate = person.birthDay ? new Date(person.birthDay) : undefined;
  } else {
    initialDate = form.values.birthDay
      ? new Date(form.values.birthDay)
      : undefined;
  }

  const [year, setYear] = useState(initialDate ? initialDate.getFullYear() : 0);
  const [month, setMonth] = useState(initialDate ? initialDate.getMonth() : -1); // 0-11 indexed
  const [day, setDay] = useState(initialDate ? initialDate.getDate() : 0);

  const updateDate = (newYear: number, newMonth: number, newDay: number) => {
    let tempDate = new Date(Date.UTC(newYear, newMonth, newDay));

    if (tempDate.getUTCMonth() !== newMonth) {
      // Set to the last day of the intended month
      tempDate = new Date(Date.UTC(newYear, newMonth + 1, 0));
    }

    const newDateString = dayjs(tempDate).format('YYYY-MM-DD');

    if (newYear && newMonth + 1 && newDay) {
      form.setFieldValue(formPath, newDateString);
    }
  };

  return (
    <Group mt="xs">
      <NumberInput
        {...form.getInputProps(formPath)}
        onChange={(value) => {
          if (!value || value.toString().length !== 4) {
            // form.setFieldError(`parents.${index}.birthDay`, '');
            form.setFieldValue(formPath, '');
            setYear(0);
            return;
          }
          setYear(value);
          updateDate(value, month, day);
        }}
        value={year ? year : undefined}
        formatter={(value) => {
          const formattedValue = value
            ? !Number.isNaN(parseInt(value))
              ? value
              : ''
            : '';
          return formattedValue;
        }}
        sx={{ width: '5.375rem' }}
        label="År"
        placeholder="ÅÅÅÅ"
        name="year"
        hideControls
        max={new Date().getFullYear()}
        disabled={disabled}
      />

      <NumberInput
        {...form.getInputProps(formPath)}
        onChange={(value) => {
          if (!value) {
            setMonth(-1);
            form.setFieldValue(formPath, '');
            return;
            //maybe have to set manual error
          }
          if (value < 1 || value > 12) return;
          setMonth(value - 1);
          updateDate(year, value - 1, day);
        }}
        value={month + 1 ? month + 1 : undefined}
        formatter={(value) => {
          const formattedValue = value
            ? !Number.isNaN(parseInt(value))
              ? value
              : ''
            : '';
          return formattedValue;
        }}
        sx={{ width: '5.375rem' }}
        label="Månad"
        placeholder="MM"
        name="month"
        hideControls
        min={1}
        max={12}
        disabled={disabled}
      />

      <NumberInput
        {...form.getInputProps(formPath)}
        onChange={(value) => {
          if (!value) {
            form.setFieldValue(formPath, '');
            setDay(0);
            return;
            //maybe have to set manual error
          }
          if (value < 1 || value > 31) return;
          setDay(value);
          updateDate(year, month, value);
        }}
        value={day ? day : undefined}
        formatter={(value) => {
          const formattedValue = value
            ? !Number.isNaN(parseInt(value))
              ? value
              : ''
            : '';
          return formattedValue;
        }}
        sx={{ width: '5.375rem' }}
        label="Dag"
        placeholder="dd"
        name="day"
        hideControls
        min={1}
        max={31}
        disabled={disabled}
      />
    </Group>
  );
};
