import { Text } from '.././..';
import { NumberBox } from './subs';
import { COLORS } from '../../../constants';
import { StyledEvaluationWrapper } from '../styles';

interface OneToFiveProps {
  value?: number;
  onChange?: (val: number) => void;
  disabled?: boolean;
}

export const OneToFive = ({ value, onChange, disabled }: OneToFiveProps) => {
  const numbers = [1, 2, 3, 4, 5];

  const handeChangeSelected = (val: number) => {
    onChange && !disabled && onChange(val);
  };

  return (
    <StyledEvaluationWrapper>
      {numbers.map((number) => (
        <NumberBox
          disabled={disabled}
          key={number}
          selected={value === number}
          onClick={() => handeChangeSelected(number)}
        >
          <Text
            size="18px"
            color={
              value === number
                ? COLORS.hdBackgroundWhite
                : COLORS.evaluationBlack
            }
          >
            {number}
          </Text>
        </NumberBox>
      ))}
    </StyledEvaluationWrapper>
  );
};
