export const getNotesSearchByPlacement = async (
  placementId: string,
  token: string | null
): Promise<Response> =>
  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/Notes/search/placement/${placementId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: 'application/json',
      },
    }
  )
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
        console.error(`something went wrong while fetching notes`, error);
      return error;
    });
