import { ChevronDown } from 'react-feather';
import { useLoaderData, useSubmit, Form } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import {
  TopBar,
  PageWrapper,
  Title,
  PrimaryButton,
  Text,
} from '../../components';
import { Category } from '../../types';
import {
  CreateDescriptionFormData,
  Description,
} from '../../types/Description';
import {
  CreateDescriptionWrapper,
  StyledButtonWrapper,
  StyledSelect,
  StyledTransparentSection,
} from './styles';
import { Dialog } from '@mantine/core';
import { COLORS } from '../../constants';
import { isNotEmpty, useForm } from '@mantine/form';
import { createFormData } from '../../utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const Descriptions = () => {
  const { forms } = useLoaderData() as {
    forms: Category[];
  };

  const submit = useSubmit();

  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState<
    string | null
  >(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<
    string | null
  >(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesDisabled, setSubCategoriesDisabled] = useState(true);
  const [formDisabled, setFormDisabled] = useState(true);

  const [showSaveMessage, setShowSaveMessage] = useState(false);

  const mainCategories = forms.map((category: Category) => {
    return { label: category.name, value: category.id };
  });

  const [description, setDescription] = useState<Description | null>(null);
  const previousActionWasCreate = useRef(false);

  const form = useForm({
    initialValues: {
      id: '',
      text: '',
      mainCategory: '',
      subCategory: '',
    },
    validate: {
      mainCategory: isNotEmpty('Välj kategori'),
      subCategory: isNotEmpty('Välj underkategori'),
    },
  });

  useEffect(() => {
    setSelectedSubCategoryId(null);
    if (selectedMainCategoryId) {
      setSubCategoriesDisabled(false);
    }
  }, [selectedMainCategoryId]);

  useEffect(() => {
    if (selectedMainCategoryId) {
      const mainCategory: any = forms.find((obj: Category) => {
        return obj.id === selectedMainCategoryId;
      });

      const subCategoryNames: any = [];

      mainCategory.sections.map((section: any) => {
        section.questions.forEach((question: any) =>
          subCategoryNames.push({
            label: question.name,
            value: question.id,
            description: question.description,
          })
        );
      });

      setSubCategories(subCategoryNames);
    }
  }, [selectedMainCategoryId, forms]);

  useEffect(() => {
    if (selectedSubCategoryId) {
      submit(
        { formQuestionId: selectedSubCategoryId },
        {
          method: 'post',
        }
      );

      const subCategory: any = subCategories?.find((obj: any) => {
        return obj.value === selectedSubCategoryId;
      });

      form.setFieldValue(
        'subCategory',
        selectedSubCategoryId ? selectedSubCategoryId : ''
      );
      form.setFieldValue(
        'mainCategory',
        selectedMainCategoryId ? selectedMainCategoryId : ''
      );

      if (subCategory && subCategory.description) {
        setDescription(subCategory.description);
        form.setFieldValue('id', subCategory.description.id);
        form.setFieldValue('text', subCategory.description.text);
      } else {
        setDescription(null);
        form.setFieldValue('id', '');
        form.setFieldValue('text', '');
      }

      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  }, [selectedSubCategoryId]);

  useEffect(() => {
    if (previousActionWasCreate) {
      const subCategory: any = subCategories?.find((obj: any) => {
        return obj.value === selectedSubCategoryId;
      });
      if (subCategory && subCategory.description) {
        setDescription(subCategory.description);
      } else {
        setDescription(null);
      }
    }
  });

  const saveDescription = () => {
    const formHasErrors = form.validate().hasErrors;

    if (formHasErrors) {
      return;
    }

    const modifiedContent = form.values.text
      .replace(/<p>/g, '<div>')
      .replace(/<\/p>/g, '</div>');

    const data: CreateDescriptionFormData = {
      text: modifiedContent,
      formQuestionId: form.values.subCategory,
    };

    const formData = createFormData(data);

    const formQuestionId = form.values.subCategory;

    if (description) {
      submit(formData, {
        method: 'post',
        action: `/descriptions/${description.id}/update`,
      });
      previousActionWasCreate.current = false;
    } else {
      submit(formData, {
        method: 'post',
        action: `/descriptions/create/save/${formQuestionId}`,
      });

      previousActionWasCreate.current = true;
    }

    setShowSaveMessage(true);

    setTimeout(() => {
      setShowSaveMessage(false);
    }, 5000);
  };

  const handleQuillChange = (content: string) => {
    form.setFieldValue('text', content);
  };

  return (
    <PageWrapper>
      <CreateDescriptionWrapper>
        <TopBar
          left={
            <Title as="h1" type="secondary" weight="500">
              Förklaringar
            </Title>
          }
          right={
            <div>
              <StyledSelect
                label="Kategori"
                placeholder="Välj huvudkategori"
                value={selectedMainCategoryId}
                data={mainCategories}
                onChange={setSelectedMainCategoryId}
                rightSectionWidth={40}
              />
              <StyledSelect
                label="Underkategori"
                placeholder="Välj underkategori"
                value={selectedSubCategoryId}
                data={subCategories}
                onChange={setSelectedSubCategoryId}
                disabled={subCategoriesDisabled}
                rightSectionWidth={40}
              />
            </div>
          }
        />
        <Form onSubmit={form.onSubmit(saveDescription)}>
          <div style={{ backgroundColor: COLORS.hdBackgroundWhite }}>
            <ReactQuill
              value={form.values.text}
              onChange={handleQuillChange}
              readOnly={formDisabled}
              theme="snow"
              css={'background-color: white;'}
            />
          </div>

          <StyledTransparentSection>
            <StyledButtonWrapper>
              <Dialog
                opened={showSaveMessage}
                size="sm"
                radius="sm"
                style={{ backgroundColor: COLORS.hdAccentGreen }}
              >
                <Text color={COLORS.textPrimary} size={'14px'}>
                  Förklaringen har sparats
                </Text>
              </Dialog>
              <PrimaryButton
                type="submit"
                background={COLORS.hdAccentGreen}
                hover={COLORS.hdAccentGreenDark}
                disabled={formDisabled}
              >
                Spara
              </PrimaryButton>
            </StyledButtonWrapper>
          </StyledTransparentSection>
        </Form>
      </CreateDescriptionWrapper>
    </PageWrapper>
  );
};
