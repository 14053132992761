import { redirect } from 'react-router-dom';
import { AuthToken, CreateConsultantFormData, Consultant } from '../../types';
import { errorHandler } from '../../utils';

export const updateConsultant = async (
  data: any,
  id: string,
  token: string | null
): Promise<Response> =>
  fetch(`${process.env.REACT_APP_API_BASE_URL}/Consultants/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((error) => {
      //eslint-disable-next-line
      console.error(
        `something went wrong while updating consultant with id: ${id}`,
        error
      );
      return error;
    });

export const updateConsultantAction =
  ({ token }: AuthToken) =>
  async ({ request, params }: any) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData) as CreateConsultantFormData;

    const res = await updateConsultant(data, params.consultantId, token);

    if (!res.ok) {
      throw await errorHandler(res);
    }

    let consultant: Consultant;

    try {
      consultant = await res.json();
    } catch (error) {
      throw new Error('Failed parsing json');
    }

    return redirect(`/consultants/${consultant.id}`);
  };
