import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { Color } from '../../types';

interface PrimaryButtonProps extends ButtonProps {
  background?: Color | string;
  hover?: Color | string;
}

const StyledPB = styled(Button)<PrimaryButtonProps>`
  background-color: ${(props) => {
    if (props.disabled)
      return `${COLORS.disabledButtonBackgroundGrey} !important`;
    return props.background ? props.background : COLORS.hdAccentBlue;
  }};
  font-size: initial;

  &:hover {
    background-color: ${(props) => props.hover};
  }
`;

export const PrimaryButton = createPolymorphicComponent<
  'button',
  PrimaryButtonProps
>(StyledPB);
