export const createFormData = (data: any) => {
  //create a new FormData object and append data
  //we do this to pass it correcly to the react router action
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value) || typeof value === 'object') {
      return formData.append(key, JSON.stringify(value));
    }

    return formData.append(key, value as string);
  });
  return formData;
};
