import { COLORS } from '../constants';

export * from './Tenant';
export * from './Consultant';
export * from './FamilyHome';
export * from './Placement';
export * from './Sentence';
export * from './Auth';
export * from './Table';
export * from './MonthlyReport';
export * from './Profile';
export * from './Note';

export type Color = keyof typeof COLORS;

export interface AuthToken {
  token: string | null;
}

export enum RedirectAction {
  Register = 'register',
  Report = 'report',
}
