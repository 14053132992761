import { RouteObject } from 'react-router-dom';
import {
  familyHomesLoader,
  familyHomeDetailLoader,
  createFamilyHomeAction,
  updateFamilyHomeAction,
  deleteFamilyHomeAction,
  familyHomePermissionAction,
} from '../api';
import {
  ErrorPage,
  FamilyHomes,
  FamilyHomeDetail,
  FamilyHomeCreate,
  FamilyHomeEdit,
} from '../pages';

export const familyHomeRoutes = (
  token: string | null = null,
  isAdmin = false
): RouteObject[] => [
  {
    path: 'family-homes',
    element: <FamilyHomes />,
    errorElement: <ErrorPage />,
    loader: familyHomesLoader({ token }),
  },
  {
    path: 'family-homes/:familyHomeId',
    element: <FamilyHomeDetail />,
    errorElement: <ErrorPage />,
    loader: familyHomeDetailLoader({ token, isAdmin }),
    action: familyHomePermissionAction({ token }),
  },
  {
    path: 'family-homes/create',
    element: <FamilyHomeCreate />,
    errorElement: <ErrorPage />,
    action: createFamilyHomeAction({ token }),
  },
  {
    path: 'family-homes/:familyHomeId/edit',
    element: <FamilyHomeEdit />,
    errorElement: <ErrorPage />,
    loader: familyHomeDetailLoader({ token, isAdmin }),
    action: updateFamilyHomeAction({ token }),
  },
  {
    path: 'family-homes/:familyHomeId/delete',
    errorElement: <ErrorPage />,
    action: deleteFamilyHomeAction({ token }),
  },
];
