import { Box } from '@mantine/core';
import { Fragment } from 'react';
import { BirthdayIcon, Text, StyledCard, Spacer } from '../../../../components';
import { FamilyRelation, Placement } from '../../../../types';
import { getFamilyRelationText } from '../../../../utils';
import dayjs from 'dayjs';

interface DetailBirthdayCardProps {
  placement: Placement;
}

export const DetailBirthdayCard = ({ placement }: DetailBirthdayCardProps) => (
  <StyledCard>
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <>
        <Text size="16px">Aktuella födelsedagar</Text>
        <Spacer minSpace="0.5rem" />
        <BirthdayIcon />
      </>
    </Box>
    {placement.familyHome?.relatedPersons.map((person) => {
      if (person.birthDayNotification) {
        return (
          <Fragment key={person.id}>
            <Text weight="400" size="16px">
              {getFamilyRelationText(
                FamilyRelation[person.relation as keyof typeof FamilyRelation]
              )}
            </Text>
            <Text weight="400" size="16px">
              {person?.birthDay &&
                dayjs(new Date(person.birthDay)).format('YYYY-MM-DD')}
            </Text>
          </Fragment>
        );
      }
    })}
  </StyledCard>
);
