import styled from '@emotion/styled';

interface SpacerProps {
  minSpace?: string;
  maxSpace?: string;
}

const StyledSpacer = styled.div<SpacerProps>`
  flex: 1 2;
  min-width: ${(props) => props.minSpace};
  min-height: ${(props) => props.minSpace};
  max-width: ${(props) => props.maxSpace};
  max-height: ${(props) => props.maxSpace};
`;

export const Spacer = ({ minSpace, maxSpace }: SpacerProps) => {
  return <StyledSpacer minSpace={minSpace} maxSpace={maxSpace} />;
};
