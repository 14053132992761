import styled from '@emotion/styled';
import { Gift } from 'react-feather';
import { COLORS } from '../../constants';

export const StyledCard = styled.div`
  background-color: ${COLORS.hdBackgroundWhite};
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
  box-shadow: 0px 6px 60px rgba(18, 67, 67, 0.06);
  border-radius: 8px;
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;
export const PlacementNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledGiftIcon = styled(Gift)`
  background: #ff9dde;
  border-radius: 50px;
  padding: 8px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 36px;
  color: ${COLORS.hdBackgroundWhite};
  margin-right: 0.5rem;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
