import { Link } from 'react-router-dom';
import { BirthdayIcon, getFormattedYearMonth } from '../../components';
import { COLORS } from '../../constants';
import { Placement, ReportStatus, TableRow } from '../../types';
import { Edit3 } from 'react-feather';

export const formatPlacementRowsForAdmin = (
  data: Placement[],
  onRowClick: (id: string) => void
): TableRow[] =>
  data.map((placement) => ({
    id: placement.id,
    onClick: () => onRowClick(placement.id),
    cells: [
      {
        name: 'name',
        label: 'Placerad',
        isSortable: true,
        value: placement.name,
        content: placement.name,
      },
      {
        name: 'familyHome',
        label: 'Familjehem',
        isSortable: true,
        value: placement?.familyHome?.name,
        content: placement?.familyHome?.name
          ? placement?.familyHome?.name
          : '-',
      },
      {
        name: 'consultant',
        label: 'Ansvarig konsulent',
        isSortable: true,
        value: placement?.responsibleConsultant,
        content: placement?.responsibleConsultant,
      },
    ],
  }));

export const formatPlacementRowsForConsultant = (
  data: Placement[],
  onRowClick: (id: string) => void
): TableRow[] =>
  data.map((placement) => {
    // Check and log for New or InProgress report
    const hasUnfinishedReports = placement?.reports?.find(
      (report) =>
        report.status === ReportStatus.New ||
        report.status === ReportStatus.InProgress ||
        report.status === ReportStatus.Generated
    );

    return {
      id: placement.id,
      onClick: () => onRowClick(placement.id),
      cells: [
        {
          name: 'name',
          label: 'Placerad',
          isSortable: true,
          value: placement.name,
          content: placement.name,
        },
        {
          name: 'familyHome',
          label: 'Familjehem',
          isSortable: true,
          value: placement?.familyHome?.name,
          content: placement?.familyHome?.name
            ? placement?.familyHome?.name
            : '-',
        },
        {
          name: 'unfinishedReports',
          label: 'Finns påbörjade rapporter',
          isSortable: false,
          content: hasUnfinishedReports ? (
            <Link
              to={`${placement.id}`}
              style={{
                textDecoration: 'none',
                color: COLORS.hdAccentBlue,
                display: 'flex',
              }}
            >
              <Edit3 />
            </Link>
          ) : (
            '-'
          ),
        },
        {
          name: 'birthDaySoon',
          label: '',
          isSortable: false,
          content: placement.birthDayNotification ? <BirthdayIcon /> : '',
        },
      ],
    };
  });

export const formatPlacementRowsForConsultantMobile = (
  data: Placement[],
  onRowClick: (id: string) => void
): TableRow[] =>
  data.map((placement) => ({
    id: placement.id,
    onClick: () => onRowClick(placement.id),
    cells: [
      {
        name: 'name',
        label: 'Placerad',
        isSortable: true,
        value: placement.name,
        content: placement.name,
      },
      {
        name: 'familyHome',
        label: 'Familjehem',
        isSortable: true,
        value: placement?.familyHome?.name,
        content: placement?.familyHome?.name
          ? placement?.familyHome?.name
          : '-',
      },
      {
        name: 'birthDaySoon',
        label: '',
        isSortable: false,
        content: placement.birthDayNotification ? <BirthdayIcon /> : '',
      },
    ],
  }));
