import { Lock, Unlock } from 'react-feather';
import { ActionIcon } from '@mantine/core';
import { useSubmit } from 'react-router-dom';

import { COLORS } from '../../../../constants';
import { Tenant } from '../../../../types';
import { createFormData } from '../../../../utils';
import { LockedWrapper, UnLockedWrapper } from './stylex';

interface LockButtonProps {
  tenant: Tenant;
}

export const LockButton = ({ tenant }: LockButtonProps) => {
  const submit = useSubmit();
  //All tenant data is sent here to satisfy the API validation.
  const formData = createFormData(tenant);

  const handleUnLockedClick = (e: any) => {
    e.preventDefault();
    submit(formData, {
      method: 'post',
      action: `/tenants/${tenant.id}/lock`,
    });
  };
  const handleLockedClick = (e: any) => {
    e.preventDefault();
    submit(formData, {
      method: 'post',
      action: `/tenants/${tenant.id}/unlock`,
    });
  };

  return tenant?.disabled ? (
    <LockedWrapper>
      <ActionIcon
        variant="filled"
        color="gray"
        onClick={handleLockedClick}
        sx={{ transform: 'none !important' }}
      >
        <Lock size={18} color={COLORS.textPrimary} strokeWidth={'1.5px'} />
      </ActionIcon>
    </LockedWrapper>
  ) : (
    <UnLockedWrapper>
      <ActionIcon
        variant="filled"
        onClick={handleUnLockedClick}
        sx={{ transform: 'none !important' }}
      >
        <Unlock size={18} color={COLORS.textPrimary} strokeWidth={'1.5px'} />
      </ActionIcon>
    </UnLockedWrapper>
  );
};
