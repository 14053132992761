import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';
import { COLORS } from '../../constants';

export const StyledPB = styled(Button)`
  background-color: white;
  color: black;
  align-self: self-end;
`;

export const StyledPlacedButton = createPolymorphicComponent<
  'button',
  ButtonProps
>(StyledPB);
