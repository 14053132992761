import { Text } from '../../../../components';
import { QuestionWrapper } from './styles';
import { QuestionInfoIcon } from '../../../../components/QuestionInfoIcon';

interface FormQuestionProps {
  section: string;
  question: string;
  children: any;
  description?: string;
}

export const FormQuestion = ({
  section,
  question,
  children,
  description,
}: FormQuestionProps) => {
  const isSummarySection = question === 'Sammanfattning';

  return (
    <QuestionWrapper>
      {!isSummarySection && (
        <>
          <Text size="12px" weight="400">
            {section}
          </Text>
          <QuestionInfoIcon hoverText={description}>
            <Text size="20px" lineHeight="22px">
              {question}
            </Text>
          </QuestionInfoIcon>
        </>
      )}
      {isSummarySection && <QuestionInfoIcon hoverText={description} />}
      {children}
    </QuestionWrapper>
  );
};
