export interface MonthlyReport {
  id: string;
  name: string;
  yearMonth: string;
  sumConsultantTimes: SumConsultantTime;
  signedBy: string;
  signedAt: string;
  status: ReportStatus;
  progress: number;
  placementId: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  formData: ReportCategory[];
  isInvoiced: boolean;
  alternativeName: string;
}

export interface ReportCategory {
  freeText?: string | null;
  id: string;
  name: string;
  sortorder: number;
  sections: ReportSection[];
}

export interface ReportSection {
  id: string;
  name: string;
  sortOrder: number;
  questions: ReportQuestion[];
}

export interface ReportQuestion {
  dateValue?: string | null;
  dateValues?: Date[] | null;
  estimateValue: number;
  group?: string | null; //???
  id: string;
  name: string;
  numberValue: number;
  optionValue: string;
  dropDownValue: string;
  dropDownValues: string[];
  sortOrder: number;
  type: QuestionType;
  description?: ReportQuestionDescription;
}

export interface ReportQuestionDescription {
  id: string;
  text?: string;
}

export enum QuestionType {
  GreenRed = 'GreenRed',
  Date = 'Date',
  DateAndGreenRed = 'DateAndGreenRed',
  EstimateChild = 'EstimateChild',
  EstimateAndGreenRed = 'EstimateAndGreenRed',
  Number = 'Number',
  NumberAndGreenRed = 'NumberAndGreenRed',
  DropDown = 'DropDown',
  DropDownAndGreenRed = 'DropDownAndGreenRed',
}

export enum ReportStatus {
  New = 'New',
  InProgress = 'InProgress',
  Generated = 'Generated',
  Signed = 'Signed',
  // Invoiced = 'Invoiced',
  // Canceled = 'Canceled',
}

export interface SumConsultantTime {
  hours: number;
  minutes: number;
  contactType: string;
}

export interface FormattedConsultantTime {
  hours: number;
  minutes: number;
  contactType: string;
  contact: string;
  date: string;
  consultant: string;
}
