import { Form, useNavigation, useSubmit } from 'react-router-dom';
import { Category, PlacementReport } from '../../../../../../types';
import { useEffect, useRef, useState } from 'react';
import { NoteFormData, Note } from '../../../../../../types/Note';
import { isNotEmpty, useForm } from '@mantine/form';
import { createFormData } from '../../../../../../utils';
import {
  NotepadForegroundWrapper,
  NotepadBackgroundWrapper,
  StyledButtonWrapper,
  StyledInfoIconWrapper,
  StyledMultiSelect,
  StyledSelect,
  StyledTextarea,
  StyledTransparentSection,
} from './styles';
import {
  CancelButton,
  PrimaryButton,
  SecondaryButton,
  SortOrder,
  Spacer,
  Table,
  Text,
  Title,
} from '../../../../../../components';
import { COLORS } from '../../../../../../constants';
import { useNoteContext } from '../../../../../../contexts/NoteContext';
import { Box, Col, Dialog, Grid, Loader, ScrollArea } from '@mantine/core';
import { getAltTitleOfReport } from '../../../../../../utils/miscHelpers';
import { Info, RotateCw } from 'react-feather';
import { CustomTooltip } from '../../../../../../components/CustomTooltip';
import dayjs from 'dayjs';

interface NotepadProps {
  token: string | null;
  isMobile: boolean;
}

export enum NoteReportRepeat {
  Never = 'Never',
  All = 'All',
  SpecificReports = 'SpecificReports',
}

export const Notepad = ({ token, isMobile }: NotepadProps) => {
  const {
    forms,
    notes,
    addNote,
    placements,
    updateNoteContextData,
    placementsAreLoading,
    getNoteTableRowsConsultant,
    selectedNote,
    setSelectedNote,
    editNote,
  } = useNoteContext();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null
  );

  const [selectedPlacementId, setSelectedPlacementId] = useState<string | null>(
    null
  );

  const [reportsList, setReportsList] = useState<
    { label: string; value: string }[] | null
  >(null);

  const [selectedReportsList, setSelectedReportsList] = useState<string[]>([]);

  const [formDisabled, setFormDisabled] = useState(true);

  const [reportRepeat, setReportRepeat] = useState<string>(
    NoteReportRepeat.Never
  );

  const [text, setText] = useState('');

  const mainCategoriesList = forms.map((category: Category) => {
    return { label: category.name, value: category.id };
  });

  const placementList = placements.map((placement) => {
    return { label: placement.name, value: placement.id };
  });

  useEffect(() => {
    if (placements.length > 0 && selectedPlacementId !== null) {
      const selectedPlacement = placements.find(
        (placement) => placement.id === selectedPlacementId
      );

      if (selectedPlacement?.reports) {
        setReportsList(
          selectedPlacement.reports.map((report) => {
            return { label: getAltTitleOfReport(report), value: report.id };
          }) ?? []
        );
      } else {
        setReportsList(null);
      }
    }
  }, [placements, selectedPlacementId]);

  useEffect(() => {
    if (
      !selectedPlacementId ||
      !selectedNote ||
      reportRepeat !== NoteReportRepeat.SpecificReports
    )
      setSelectedReportsList([]);

    if (reportRepeat === NoteReportRepeat.Never) setSelectedCategoryId(null);
  }, [selectedPlacementId, reportRepeat, selectedNote]);

  useEffect(() => {
    if (text === '') {
      setFormDisabled(true);
    } else if (
      reportRepeat === NoteReportRepeat.All &&
      selectedPlacementId &&
      selectedCategoryId
    ) {
      setFormDisabled(false);
    } else if (
      reportRepeat === NoteReportRepeat.SpecificReports &&
      selectedPlacementId &&
      selectedReportsList.length > 0 &&
      selectedCategoryId
    ) {
      setFormDisabled(false);
    } else if (reportRepeat === NoteReportRepeat.Never && selectedPlacementId) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  }, [
    selectedPlacementId,
    reportsList,
    selectedReportsList,
    reportRepeat,
    selectedCategoryId,
    text,
  ]);

  useEffect(() => {
    if (selectedNote) {
      setSelectedPlacementId(selectedNote.placementId);
      setSelectedReportsList(selectedNote.specificReportsIds ?? []);
      setSelectedCategoryId(selectedNote.formStepId);
      setReportRepeat(selectedNote.noteReportRepeat);
      setText(selectedNote.text ?? '');
    }
  }, [selectedNote]);

  const addNewNote = async (event: React.FormEvent) => {
    event.preventDefault();

    const newNote: NoteFormData = {
      text: text,
      placementId: selectedPlacementId ?? '',
      noteReportRepeat: reportRepeat as NoteReportRepeat,
      formStepId: selectedCategoryId,
      specificReportsIds: selectedReportsList,
    };

    const noteSuccessfullyAdded = await addNote(newNote);
    if (noteSuccessfullyAdded) resetForm();
  };

  const updateNote = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedNote) return;

    const updatedNote: NoteFormData = {
      text: text,
      placementId: selectedPlacementId ?? '',
      noteReportRepeat: reportRepeat as NoteReportRepeat,
      formStepId: selectedCategoryId,
      specificReportsIds: selectedReportsList,
    };

    const noteSuccessfullyUpdated = await editNote(
      selectedNote.id,
      updatedNote
    );
    if (noteSuccessfullyUpdated) resetForm();
  };

  const handleNoteReportRepeatChange = (value: string | null) => {
    if (value === null) {
      setReportRepeat(NoteReportRepeat.Never);
    } else {
      setReportRepeat(value as NoteReportRepeat);
    }
  };

  const resetForm = () => {
    setSelectedPlacementId(null);
    setReportRepeat(NoteReportRepeat.Never);
    setSelectedCategoryId(null);
    setText('');
    setSelectedReportsList([]);
    setReportsList(null);
    setSelectedNote(null);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  return (
    <NotepadBackgroundWrapper>
      <NotepadForegroundWrapper>
        <div style={{ zIndex: 1001 }}>
          <Title as="h2" type="secondary" weight="600">
            Anteckningar
          </Title>
        </div>
        <div
          style={{
            display: 'flex',
            minHeight: '400px',
            flexGrow: '1',
            marginTop: '0.4rem',
          }}
        >
          <Table
            sortingField="date"
            sortingOrder={SortOrder.ASC}
            paddingCss="0.5rem 0"
            rows={getNoteTableRowsConsultant(notes)}
          />
        </div>
        <hr></hr>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Title as="h2" type="secondary" weight="600">
            {selectedNote
              ? `${dayjs(selectedNote.createdAt).format('YYYY-MM-DD')}, ${
                  placements.find(
                    (placement) => placement.id === selectedNote.placementId
                  )?.name || '-'
                } (${selectedNote.text?.slice(0, 30)}...)`
              : 'Ny anteckning'}
          </Title>
          <Spacer></Spacer>
          <CancelButton onClick={resetForm}>
            {selectedNote ? 'Avbryt redigering' : 'Återställ'}
          </CancelButton>
        </div>
        <Grid sx={{ marginBottom: '1rem' }}>
          <Col span={isMobile ? 12 : 6}>
            <StyledSelect
              label="Skall med i rapporter?"
              value={reportRepeat}
              data={[
                // Tried putting these in a data.tsx but enums got bugged
                { label: 'Nej', value: NoteReportRepeat.Never },
                {
                  label: 'Alla nuvarande och framtida',
                  value: NoteReportRepeat.All,
                },
                {
                  label: 'Specifika rapporter',
                  value: NoteReportRepeat.SpecificReports,
                },
              ]}
              onChange={handleNoteReportRepeatChange}
            />
            <StyledMultiSelect
              label={
                <CustomTooltip
                  hoverText="Hittar inte rätt rapport? <br><br>Klicka på uppdaterings-pilen för att se till att listan är uppdaterad!"
                  width="200px"
                  maxHeight="200px"
                  cssBottom="30px"
                  cssLeft="100px"
                >
                  <StyledInfoIconWrapper>
                    <Info
                      color={COLORS.hdAccentBlue}
                      style={{ marginRight: '0.3rem' }}
                      size={17}
                    />
                    Specifika rapporter
                  </StyledInfoIconWrapper>
                </CustomTooltip>
              }
              value={selectedReportsList}
              data={reportsList ? reportsList : []}
              onChange={setSelectedReportsList}
              searchable
              nothingFound="Inga rapporter hittade!"
              clearable
              disabled={
                reportRepeat !== NoteReportRepeat.SpecificReports ||
                reportsList === null ||
                !selectedPlacementId
              }
              withAsterisk={reportRepeat === NoteReportRepeat.SpecificReports}
              error={
                reportRepeat === NoteReportRepeat.SpecificReports &&
                !selectedPlacementId
                  ? 'Välj placerad för att se specifika rapporter'
                  : ''
              }
              rightSection={
                !placementsAreLoading ? (
                  <SecondaryButton
                    onClick={() => updateNoteContextData(token)}
                    style={{ width: '36px', padding: '0' }}
                    disabled={
                      reportRepeat !== NoteReportRepeat.SpecificReports ||
                      reportsList === null ||
                      !selectedPlacementId
                    }
                  >
                    <RotateCw size={18} />
                  </SecondaryButton>
                ) : (
                  <Box
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: 'fit-content',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Loader variant="oval" color={COLORS.brandPink} size="sm" />
                  </Box>
                )
              }
            />
          </Col>
          <Col span={isMobile ? 12 : 6}>
            <StyledSelect
              label={
                <CustomTooltip
                  hoverText="Hittar inte rätt placerad? <br><br>Klicka på uppdaterings-pilen för att se till att listan är uppdaterad!"
                  width="200px"
                  maxHeight="200px"
                  cssBottom="30px"
                  cssLeft="100px"
                >
                  <StyledInfoIconWrapper>
                    <Info
                      color={COLORS.hdAccentBlue}
                      style={{ marginRight: '0.3rem' }}
                      size={17}
                    />
                    Placerad
                  </StyledInfoIconWrapper>
                </CustomTooltip>
              }
              placeholder="Välj placerad"
              value={selectedPlacementId}
              data={placementList}
              onChange={setSelectedPlacementId}
              searchable
              nothingFound="Inga placerade hittade!"
              disabled={placementsAreLoading}
              withAsterisk
              clearable
              rightSection={
                !placementsAreLoading ? (
                  <SecondaryButton
                    onClick={() => updateNoteContextData(token)}
                    style={{ width: '36px', padding: '0' }}
                  >
                    <RotateCw size={18} />
                  </SecondaryButton>
                ) : (
                  <Box
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: 'fit-content',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Loader variant="oval" color={COLORS.brandPink} size="sm" />
                  </Box>
                )
              }
            />

            <StyledSelect
              label="Kategori"
              placeholder="Välj kategori"
              value={selectedCategoryId}
              data={mainCategoriesList}
              onChange={setSelectedCategoryId}
              clearable
              withAsterisk={reportRepeat !== NoteReportRepeat.Never}
              disabled={reportRepeat === NoteReportRepeat.Never}
            />
          </Col>
        </Grid>
        <Form onSubmit={selectedNote ? updateNote : addNewNote}>
          <div style={{ backgroundColor: COLORS.hdBackgroundWhite }}>
            <StyledTextarea value={text} onChange={handleTextChange} />
          </div>

          <StyledTransparentSection>
            <StyledButtonWrapper>
              <PrimaryButton
                type="submit"
                background={COLORS.hdAccentGreen}
                hover={COLORS.hdAccentGreenDark}
                disabled={formDisabled}
              >
                {selectedNote ? 'Uppdatera anteckning' : 'Spara'}
              </PrimaryButton>
            </StyledButtonWrapper>
          </StyledTransparentSection>
        </Form>
      </NotepadForegroundWrapper>
    </NotepadBackgroundWrapper>
  );
};
