import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { StyledNumberBox } from './styles';

export interface NumberBoxProps {
  selected: boolean;
  children: EmotionJSX.Element | number;
  onClick: () => void;
  disabled?: boolean;
}

export const NumberBox = ({
  selected,
  children,
  onClick,
  disabled,
}: NumberBoxProps) => {
  return (
    <StyledNumberBox selected={selected} onClick={onClick} disabled={disabled}>
      {children}
    </StyledNumberBox>
  );
};
