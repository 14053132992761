import { Title, Text } from '../../../../components';
import { COLORS } from '../../../../constants';
import { StyledDetailBlock } from './styles';

export interface consultantDetailProps {
  email: string;
  phone: string;
}

const DetailBlock = ({ header, content }: any) => {
  return (
    <StyledDetailBlock>
      <Text weight="600px" size="12px" color={COLORS.textDisabled}>
        {header}
      </Text>
      <Text weight="500px" size="19px" color={COLORS.textDisabled}>
        {content}
      </Text>
    </StyledDetailBlock>
  );
};

export const ContactDetail = ({ email, phone }: consultantDetailProps) => {
  return (
    <>
      <Title as="h2" type="secondary" weight="500">
        Kontaktuppgifter
      </Title>
      <DetailBlock header={'E-postadress'} content={email} />
      <DetailBlock header={'Telefonnummer'} content={phone} />
    </>
  );
};
