import { Form } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { StyledLogoutButton } from './styles';

export const LogoutButton = () => {
  const { logout } = useAuth();

  return (
    <>
      <Form method="post" action="/logout">
        {/* the onclick solution here is temporary until we can invalidate token in backend */}
        <StyledLogoutButton type="submit" onClick={logout}>
          Logga ut
        </StyledLogoutButton>
      </Form>
    </>
  );
};
