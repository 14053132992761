import { Title, DetailBlock } from '../../../../components';

export interface TenantDetailProps {
  name: string;
  email: string;
  phone: string;
}

export const ContactDetail = ({ name, email, phone }: TenantDetailProps) => {
  return (
    <>
      <Title as="h2" type="secondary" weight="500">
        Kontaktuppgifter
      </Title>
      <DetailBlock header={'Kontaktperson'} content={name} />
      <DetailBlock header={'E-postadress'} content={email} />
      <DetailBlock header={'Telefonnummer'} content={phone} />
    </>
  );
};
