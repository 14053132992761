import { Group, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { Form, useNavigate } from 'react-router-dom';
import {
  PrimaryButton,
  Spacer,
  CancelButton,
  Title,
  FormWrapper,
} from '../../components';

import { COLORS } from '../../constants';
import { CreateProfileFormData } from '../../types';
import { validateEmail, validateNumber } from '../../utils';

interface profileFormProps {
  onSubmit: (data: CreateProfileFormData) => void;
  profile: CreateProfileFormData;
}

export const ProfileForm = ({ onSubmit, profile }: profileFormProps) => {
  const navigate = useNavigate();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      phoneNumber: profile.phoneNumber,
    },

    validate: {
      firstName: isNotEmpty('Ange ett förnamn'),
      lastName: isNotEmpty('Ange ett efternamn'),
      email: (value) => (validateEmail(value) ? 'Ange en e-postadress' : null),
      phoneNumber: (value) =>
        validateNumber(value) ? 'Ange ett telefonnummer' : null,
    },
  });

  return (
    <FormWrapper scrollable>
      <Form method="post">
        <Title as="h2" type="secondary" weight="600">
          Min Profil
        </Title>
        <Spacer minSpace="1.5rem" />

        <TextInput
          label="Förnamn"
          placeholder="Förnamn"
          name="firstName"
          {...form.getInputProps('firstName')}
        />

        <TextInput
          label="Efternamn"
          placeholder="Efternamn"
          name="lastName"
          {...form.getInputProps('lastName')}
        />

        <TextInput
          label="E-postadress"
          placeholder="E-postadress"
          name="email"
          {...form.getInputProps('email')}
        />
        <TextInput
          label="Telefonnummer"
          placeholder="Telefonnummer"
          name="phoneNumber"
          {...form.getInputProps('phoneNumber')}
        />

        <Group position="right" spacing="xs" sx={{ marginTop: '1.5rem' }}>
          <CancelButton
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          />
          <PrimaryButton
            onClick={() => {
              const formHasErrors = form.validate().hasErrors;
              !formHasErrors && onSubmit(form.values);
            }}
            background={COLORS.hdAccentGreen}
            hover={COLORS.hdAccentGreenDark}
          >
            Spara
          </PrimaryButton>
        </Group>
      </Form>
    </FormWrapper>
  );
};
