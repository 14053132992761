import { useActionData, useLoaderData, useSubmit } from 'react-router-dom';
import { isNotEmpty, useForm } from '@mantine/form';
import { useCallback, useEffect, useState } from 'react';
import { CreatePasswordFormData, CreateProfileFormData } from '../../types';
import {
  ChangePasswordForm,
  ErrorModal,
  FormModal,
  PageWrapper,
  ProfileForm,
  SecondaryButton,
  TopBar,
} from '../../components';
import { createFormData } from '../../utils';

export const ProfileEdit = () => {
  const profile = useLoaderData() as CreateProfileFormData;
  const passwordRes = useActionData() as any;

  const submit = useSubmit();

  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const openPasswordModal = useCallback(() => setPasswordModalIsOpen(true), []);

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const closeErrorModal = useCallback(() => setErrorModalIsOpen(false), []);

  const passwordForm: any = useForm({
    validateInputOnBlur: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      verifyNewPassword: '',
    },

    validate: {
      oldPassword: isNotEmpty('Ange ett lösenord'),
      newPassword: isNotEmpty('Ange ett nytt lösenord'),
      verifyNewPassword: (value) =>
        value === passwordForm.values.newPassword
          ? null
          : 'Lösenordet matchar inte det nya lösenordet',
    },
  });

  const closePasswordModal = () => {
    passwordForm.reset();
    setPasswordModalIsOpen(false);
  };

  const changePassword = () => {
    const formHasErrors = passwordForm.validate().hasErrors;

    if (!formHasErrors) {
      const data: CreatePasswordFormData = {
        oldPassword: passwordForm.values.oldPassword,
        newPassword: passwordForm.values.newPassword,
        verifyNewPassword: passwordForm.values.verifyNewPassword,
      };

      const formData = createFormData(data);

      submit(formData, {
        method: 'post',
      });

      closePasswordModal();
    }
  };

  useEffect(() => {
    if (passwordRes?.status === 500) {
      setErrorModalIsOpen(true);
    }
  }, [passwordRes]);

  const onSubmit = (data: CreateProfileFormData) => {
    const formData = createFormData(data);
    submit(formData, {
      method: 'put',
      action: `/profile/update-account`,
    });
  };

  return (
    <PageWrapper>
      <TopBar
        left={<></>}
        right={
          <SecondaryButton
            onClick={(e) => {
              openPasswordModal();
            }}
          >
            Byt lösenord
          </SecondaryButton>
        }
      />

      <ProfileForm onSubmit={onSubmit} profile={profile} />

      <FormModal
        isOpen={passwordModalIsOpen}
        onCancel={closePasswordModal}
        onContinue={changePassword}
        continueText="Spara"
        content={<ChangePasswordForm form={passwordForm} />}
      />

      <ErrorModal
        isOpen={errorModalIsOpen}
        onContinue={closeErrorModal}
        continueText="Stäng"
        title="Fel lösenord"
        content={<>Nuvarande lösenord matchar inte kontots lösenord</>}
      />
    </PageWrapper>
  );
};
