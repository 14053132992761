import { Group, NumberInput, Progress } from '@mantine/core';
import { useNavigate, useSubmit } from 'react-router-dom';

import { ProgressWrapper, StyledCard } from './styles';
import { COLORS } from '../../constants';
import { MonthlyReport, Placement, RedirectAction } from '../../types';
import { PrimaryButton, Spacer, Text } from '..';
import {
  reportIsEditable,
  reportIsGenerated,
  reportIsSigned,
} from '../../pages';
import dayjs from 'dayjs';
import { isNotEmpty, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { createFormData } from '../../utils';
import { doNothing, getAltTitleOfReport } from '../../utils/miscHelpers';

interface PlacementCardProps {
  placement: Placement;
  reports?: MonthlyReport[];
}

/**
 * @param year If no argument, gets current year.
 * @param month If no argument, gets current month.
 * @returns
 */
export const getFormattedYearMonth = (
  year: number = dayjs().year(),
  month: number = dayjs().month() + 1
) => {
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  return `${year}${formattedMonth}`;
};

export const PlacementCard = ({
  placement,
  reports = [],
}: PlacementCardProps) => {
  const navigate = useNavigate();
  const submit = useSubmit();

  const form = useForm<{
    year: string | null;
    month: string | null;
  }>({
    validateInputOnBlur: ['contact', 'contactType', 'minutes'],
    initialValues: {
      year: null,
      month: null,
    },

    validate: {
      year: isNotEmpty('Ange år'),
      month: isNotEmpty('Ange månad'),
    },
  });

  const currentYear = dayjs().year();
  const currentMonth = dayjs().month() + 1;

  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth); // 1-12 indexed
  const [yearMonth, setYearMonth] = useState(
    getFormattedYearMonth(year, month)
  );
  const [yearMonthAlreadyExists, setYearMonthAlreadyExists] = useState(true);
  const [monthIsInvalid, setMonthIsInvalid] = useState(true);
  const [yearIsInvalid, setYearIsInvalid] = useState(true);

  useEffect(() => {
    setYearMonth(getFormattedYearMonth(year, month));
    setMonthIsInvalid(
      month === 0 || (month > currentMonth && year === currentYear)
    );
    setYearIsInvalid(year === 0 || year > currentYear);
  }, [year, month, currentYear, currentMonth]);

  useEffect(() => {
    setYearMonthAlreadyExists(
      reports.find((report) => report.yearMonth === yearMonth) ? true : false
    );
  }, [yearMonth, reports]);

  const onCreateNewReport = (
    yearMonth: string,
    redirectAction: RedirectAction
  ) => {
    submit(
      createFormData({ yearMonth: yearMonth, redirectAction: redirectAction }),
      {
        method: 'post',
        action: `/placements/${placement?.id}/report/create`,
      }
    );
  };

  return (
    <>
      <StyledCard>
        <Text size="22px" weight="500">
          Skapa ny rapport
        </Text>

        <Group mt="xs">
          <NumberInput
            {...form.getInputProps('year')}
            onChange={(value) => {
              if (!value || value.toString().length !== 4) {
                form.setFieldValue('year', '');
                setYear(0);
                return;
              }
              setYear(value);
            }}
            value={year ? year : undefined}
            formatter={(value) => {
              const formattedValue = value
                ? !Number.isNaN(parseInt(value))
                  ? value
                  : ''
                : '';
              return formattedValue;
            }}
            sx={{ width: '5.375rem' }}
            label="År"
            placeholder="ÅÅÅÅ"
            name="year"
            hideControls
            max={currentYear}
          />

          <NumberInput
            {...form.getInputProps('month')}
            onChange={(value) => {
              if (!value || value > 12) {
                setMonth(0);
                form.setFieldValue('month', '');
                return;
              }
              if (value < 1 || value > 12) return;
              setMonth(value);
            }}
            value={month ? month : undefined}
            formatter={(value) => {
              const formattedValue = value
                ? !Number.isNaN(parseInt(value))
                  ? value
                  : ''
                : '';
              return formattedValue;
            }}
            sx={{ width: '5.375rem' }}
            label="Månad"
            placeholder="MM"
            name="month"
            hideControls
            min={1}
            max={12}
          />
        </Group>

        <Spacer maxSpace="0.5rem" />

        <PrimaryButton
          disabled={yearMonthAlreadyExists || monthIsInvalid || yearIsInvalid}
          onClick={() => {
            onCreateNewReport(yearMonth, RedirectAction.Register);
          }}
        >
          Registrera kontakt
        </PrimaryButton>
        <PrimaryButton
          disabled={yearMonthAlreadyExists || monthIsInvalid || yearIsInvalid}
          onClick={() => {
            onCreateNewReport(yearMonth, RedirectAction.Report);
          }}
        >
          Rapportera
        </PrimaryButton>

        <Spacer maxSpace="0.5rem" />
      </StyledCard>

      <Spacer minSpace="1rem" maxSpace="1rem" />

      <StyledCard>
        {placement && placement.reports != null && (
          <Text size="22px" weight="500">
            Pågående rapporter
          </Text>
        )}

        {placement && placement.reports && placement.reports?.length !== 0 ? (
          placement.reports.map((report) => {
            const capitalizedAltTitle = getAltTitleOfReport(report);

            return (
              <StyledCard>
                <Text size="16px" weight="500">
                  {capitalizedAltTitle}
                </Text>

                <Spacer maxSpace="0.5rem" />

                <PrimaryButton
                  disabled={
                    !reportIsEditable(report) || reportIsGenerated(report)
                  }
                  onClick={() => {
                    !reportIsGenerated(report) &&
                      navigate(
                        `/placements/${placement?.id}/report/${report.id}/register`
                      );
                  }}
                >
                  Registrera kontakt
                </PrimaryButton>
                <PrimaryButton
                  disabled={reportIsSigned(report)}
                  onClick={() => {
                    reportIsGenerated(report)
                      ? navigate(
                          `/placements/${placement?.id}/report/${report?.id}/signing`
                        )
                      : reportIsEditable(report)
                      ? navigate(
                          `/placements/${placement?.id}/report/${report?.id}/form`
                        )
                      : doNothing();
                  }}
                >
                  Fortsätt rapportera
                </PrimaryButton>

                <Spacer maxSpace="1rem" />
                {report?.progress !== 100 && (
                  <ProgressWrapper>
                    <Progress
                      value={report?.progress ?? 0}
                      sx={{ width: '90%' }}
                    />
                    <Text weight="400" size="14px">{`${
                      report?.progress ?? 0
                    }%`}</Text>
                  </ProgressWrapper>
                )}
              </StyledCard>
            );
          })
        ) : (
          <i style={{ padding: '1rem 0' }}>
            Det finns inga påbörjade rapporter.
          </i>
        )}
      </StyledCard>
      <Spacer minSpace="1rem" maxSpace="1rem" />
    </>
  );
};

export { StyledCard } from './styles';
